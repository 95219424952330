import { interval, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { isArray } from 'rxjs/internal-compatibility';
import { IAssessCaseInfo } from '@clients-nside-io/shared/models';

export enum AssessStages {
  evaluations = 'Evaluations',
  assessments = 'Assessments',
  plans = 'RMS-Plans',
  interventions = 'Interventions',
  monitoring = 'Monitoring',
  closed = 'Closed'
}

export interface IStageCasesCounts {
  Evaluations: number;
  Assessments: number;
  Plans: number;
  Interventions: number;
  Monitoring: number;
  Closed: number;

  reset(): void;
  update(data: IAssessCaseInfo[]): void;
}

export class StageCasesCounts implements IStageCasesCounts {
  private _evaluations: number;
  private _assessments: number;
  private _plans: number;
  private _interventions: number;
  private _monitoring: number;
  private _closed: number;

  get Evaluations(): number { return this._evaluations };
  get Assessments(): number { return this._assessments };
  get Plans(): number { return this._plans };
  get Interventions(): number { return this._interventions };
  get Monitoring(): number { return this._monitoring };
  get Closed(): number { return this._closed };

  /**
   * Resets the current counts.
   */
  reset(): void {
    this._evaluations = 0;
    this._assessments = 0;
    this._plans = 0;
    this._interventions = 0;
    this._monitoring = 0;
    this._closed = 0;
  }

  /**
   * Updates the current counts according to the provided data.
   * @param data
   */
  update(data: IAssessCaseInfo[], resetFirst?: boolean): void {
    if (resetFirst == true)
      this.reset();

    data.forEach(assessCase => {
      switch (assessCase.CurrentStage) {
        case AssessStages.evaluations:
          this._evaluations += 1;
          break;
        case AssessStages.assessments:
          this._assessments += 1;
          break;
        case AssessStages.plans:
          this._plans += 1;
          break;
        case AssessStages.interventions:
          this._interventions += 1;
          break;
        case AssessStages.monitoring:
          this._monitoring += 1;
          break;
        case AssessStages.closed:
          this._closed += 1;
          break;
      }
    });
  }
}

export class DataLoadingInfo {
  private _loaded: boolean;
  private readonly _timeToRun: number;
  private _timeToCheck: number;
  private _lastLoaded: Date;
  private _paused: boolean;

  /**
   * The current stage for this instance.
   */
  get currentStage(): AssessStages {
    return this.stage;
  }

  /**
   * Whether data has been previously loaded.
   */
  get isLoaded(): boolean {
    return this._loaded;
  }

  /**
   * When the data was last loaded/reloaded.
   */
  get lastLoaded(): Date | null {
    return this._lastLoaded;
  }

  /**
   * How long, in milliseconds, since the data was last loaded/reloaded.
   */
  get lastLoadedTimeElapsed(): number {
    return Date.now() -  this._lastLoaded.getTime();
  }

  /**
   * Whether data currently can and needs to be reloaded.
    */
  get needsReload(): boolean {
    const nowMS = Date.now();
    return this._paused == false
      && (this._loaded == false
        || nowMS - this._lastLoaded.getTime() >= this._timeToRun);
  }

  /**
   * An observable for automatically reloading via a subscription.
   */
  get reloadObs$(): Observable<boolean> {
    return interval(this._timeToCheck)
      .pipe(
        map(() => this.needsReload),
        filter(nr => nr == true)
      )
  }

  /**
   *
   * @param stage The current stage, if limiting to a particular stage.
   * @param alreadyLoaded Optional. Whether data has already been loaded.
   * @param runReloadTime Optional. The elapsed time, in milliseconds,
   * after which a reload is needed. [default: 90000, minimum: 15000]
   * @param checkReloadTime Optional. The interval to check/update whether a
   * reload is needed. [default: 5000, minimum: 1000, maximum: runReloadTime]
   * @param startPaused Optional. Whether to start paused. [default: false]
   */
  constructor(private stage?: AssessStages,
              private alreadyLoaded?: boolean,
              private runReloadTime?: number,
              private checkReloadTime?: number,
              private startPaused?: boolean) {
    this._loaded = alreadyLoaded == true;
    this._timeToRun = runReloadTime > 15000
      ? runReloadTime
      : 90000;
    this._timeToCheck = checkReloadTime >= 1000
      ? checkReloadTime <= this._timeToRun
        ? checkReloadTime
        : this._timeToRun
      : 5000;
    this._lastLoaded = this._loaded
      ? new Date()
      : new Date(0);
    this._paused = startPaused == true;
  }

  /**
   * Pauses reload checks and update timers.
   */
  pause(): void {
    this._paused = true;
  }

  /**
   * Resumes reload checks and update timers.
   */
  resume(): void {
    this._paused = false;
  }

  /**
   * Updates the isLoaded, lastLoaded, and related properties if the
   * provided status indicates a successful load.
   * @param status An indicator of success, e.g., a true boolean, a positive
   * number of records returned, the Date of the successful load/reload, a null
   * object (failure), or an array of the data items returned.
   */
  update(status?: boolean | number | Date | null | never[]): void {
    let result: boolean;
    let statusDt: Date = null;
    const lastUpdated = this._lastLoaded;
    if (status == null) {
      result = false;
    } else if (status == true || status == false) {
      result = status;
    } else if (status['toISOString'] != undefined) {
      statusDt = new Date(status.toString());
      result = statusDt > lastUpdated;
    } else if (Number(status) >= 0) {
      result = Number(status) > 0;
    } else if (isArray(status)) {
      result = (status as unknown as Array<never>)?.length > 0;
    }

    if (result) {
      this._loaded = true;
      this._lastLoaded = statusDt != null
        ? statusDt
        : new Date();
    }
  }

  /**
   * Resets the current instance to an unloaded state.
   * @param setPausedTo If provided, sets the paused state to match.
   */
  reset(setPausedTo?: boolean): void {
    const wasPaused = this._paused == true;
    this._paused = true;
    this._loaded = false;
    this._lastLoaded = new Date(0);
    this._paused = wasPaused
      ? setPausedTo != false
      : setPausedTo == true;
  }
}
