import { AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import { AssessService } from '../../../services/assess.service';
import { ActivatedRoute, Router} from '@angular/router';
import { NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {
  ActionHandlerService
} from '@clients-nside-io/shared/util';
import { ToastrService } from 'ngx-toastr';
import { DebugUtils } from '@clients-nside-io/shared/util';
import { SidebarHighlightService } from '../../../services/sidebar-highlight.service';
import { AssessForms } from '../../../models/Constants';
import { AssessStages } from '../../../models/AssessGeneral';
import { AssessStageBaseComponent } from '../../shared/AssessStageBase.component';
import { LocalStoreService } from '@clients-nside-io/shared/services';

@Component({
  selector: 'clients-nside-io-plans',
  templateUrl: '../../shared/AssessStageBase.component.html',
  // templateUrl: './plans.component.html',
  styleUrls: ['./plans.component.scss'],
})
export class PlansComponent extends AssessStageBaseComponent implements OnInit, OnDestroy, AfterViewInit {
  public myTitle = 'Plans';
  protected readonly myStageName: AssessStages = AssessStages.plans;
  protected readonly myFormDisplayNames: string[] = AssessForms.displayNames.plans;
  protected readonly myFormNames: string[] = AssessForms.names.plans;
  protected readonly myReviewFormNumber: number = 4;
  protected readonly myReviewForm: string = this.myFormNames[this.myReviewFormNumber - 1];

  constructor(
    protected assessService: AssessService,
    protected route: ActivatedRoute,
    protected router: Router,
    protected modalService: NgbModal,
    protected actionHandlerService: ActionHandlerService,
    protected toastr: ToastrService,
    protected debug: DebugUtils,
    protected sidebarHighlighter: SidebarHighlightService,
    protected localStore: LocalStoreService
  ) {
    super(assessService, route, router, modalService, actionHandlerService, toastr, debug, sidebarHighlighter, localStore);
  }

  // @ViewChild('threatAssessmentTeamSelection') threatAssessmentTeamSelectionModal: TemplateRef<any>;
  // @ViewChild('teamSelection') designatedTeamSelectionModal: TemplateRef<any>;
  // @ViewChild('teamSelectionConsider') teamSelectionConsiderModal: TemplateRef<any>;
  // @ViewChild(DatatableComponent) table: DatatableComponent;
  //
  // private activeSubscriptions: Subscription[] = [];
  // private afterViewInitObservables: Observable<any>[] = [];
  // private formDisplayNames = AssessForms.displayNames.plans;
  // private reviewFormNumber = 1;
  // private reviewForm = AssessForms.names.plans[this.reviewFormNumber - 1];
  // private currentCase: IAssessCaseInfo;
  // private currentUserId: string | number;
  // private reloader$ub: Subscription;
  // private assessUserList: IAssessUserInfo[] = [];
  // private reviewerId: number;
  // private assignedUserId: number;
  // private currentFormName: string;
  //
  // private get selectedUserIDs(): number[] {
  //   return this.assessUserList
  //       ?.filter(f => f.isSelected == true)
  //       ?.map(m => m.userID)
  //     ?? [];
  // }
  //
  // private get selectedTeamMatches(): boolean {
  //   return this.currentCase?.AssignedTeam?.Users
  //     ?.every(user => this.selectedUserIDs
  //       ?.findIndex(val => val.toString() == user.id?.toString()) >= 0 )
  //     !== true;
  // }
  //
  // isOpeningForm = false;
  // isChoosingCaseId = false;
  // buildingId = '';
  // plansData: any = null;
  // temp = [];
  // closeResult = '';
  //
  // plansDataSubject = new BehaviorSubject<any>(null);
  //
  // currentActionHistory: ICaseStageActionInfo[] = null;
  // isOpenForm = false;
  //
  // //for case reviewing
  // reviewerEmail = '';
  //
  // //assessment team data
  // assessmentTeamList: any[] = [];
  //
  // //attachments list
  // attachmentsCompletedForms = null;
  // attachmentsInProgressForms = null;
  // attachmentsFileLinks = null;
  // //----------------
  //
  // currentClickedCaseId: string;
  //
  // //current case number
  // stageJobsCount = {
  //   Evaluations: 0,
  //   Assessments: 0,
  //   Plans: 0,
  //   Interventions: 0,
  //   Monitoring: 0,
  //   Closed: 0,
  // };
  //
  // teamSelectionTitle = '';
  // isSelectingTeamMember = false;
  //
  // // FOR DISABLE THE BUTTON WHEN DOING HTTP REQUEST
  // isWaitingForHttpRequest = false;
  // // ----------------------------------------------
  //
  // constructor(
  //   private assessService: AssessService,
  //   private route: ActivatedRoute,
  //   private router: Router,
  //   private modalService: NgbModal,
  //   private actionHandlerService: ActionHandlerService,
  //   private toastr: ToastrService,
  //   private debug: DebugUtils,
  //   private sidebarHighlighter: SidebarHighlightService
  // ) {
  //   this.activeSubscriptions.push(this.actionHandlerService
  //     .formSubmissionSuccess$
  //     .subscribe((rsp) => {
  //       if (rsp == null) return;
  //
  //       this.toastr.success(rsp.description ?? 'Form submitted successfully.', 'Submission Status:');
  //       this.loadData();
  //       this.isOpeningForm = false;
  //     })
  //   );
  //
  //   this.activeSubscriptions.push(this.actionHandlerService
  //     .formSubmissionError$
  //     .subscribe((rsp) => {
  //       if (rsp == null) return;
  //
  //       this.toastr.error(rsp.description
  //         ?? rsp.error?.log
  //         ?? 'Form submission error, please try again.', 'Submission Status:');
  //     })
  //   );
  //
  //   this.activeSubscriptions.push(this.router.events
  //     .pipe(navigationEndEventFilter)
  //     .subscribe(event=> {
  //       const val = getFullDataForRoute(this.router);
  //       const data = this.assessService.getAssessDataForRoute(val);
  //
  //       // const _tryGet = function(name: string): string {
  //       //   return val.params?.[name]
  //       //     ?? val.queryParams?.[name]
  //       //     ?? val.data?.[name];
  //       // }
  //       //
  //       // const bldId = _tryGet('buildingId');
  //       // const routeAction = _tryGet('action');
  //       // const routeCaseId = _tryGet('assessCaseID')
  //       //   ?? _tryGet('assessCaseId')
  //       //   ?? _tryGet('assessCaseUID')
  //       //   ?? _tryGet('assessCaseUid')
  //       //   ?? _tryGet('assesscaseid')
  //       //   ?? _tryGet('assesscaseuid');
  //       // const routeSubmissionId = _tryGet('submissionId')
  //       //   ?? _tryGet('submissionID')
  //       //   ?? _tryGet('submissionid')
  //       //   ?? _tryGet('submission-id');
  //       // const routeFormName = _tryGet('formName')
  //       //   ?? _tryGet('form-name')
  //       //   ?? _tryGet('formname');
  //
  //       if (data?.buildingId) {
  //         this.buildingId = data.buildingId.toString();
  //         localStorage.setItem('buildingId', this.buildingId);
  //         localStorage.setItem('currentAssessBuildingId', this.buildingId);
  //       }
  //
  //       if (data?.isOpeningForm == true
  //         && data.formName?.length > 0
  //         && AssessForms.names.plans.findIndex(w => w == data.formName.toLowerCase()) >= 0) {
  //         this.isOpeningForm = true;
  //         this.currentFormName = data.formName.toLowerCase();
  //       }
  //
  //       if (val.url[0].path.toLowerCase().startsWith('action')
  //         && data?.action?.length > 0
  //         && data?.assessCaseID?.length > 0) {
  //
  //         switch (data.action.toLowerCase()) {
  //           case 'create':
  //             this.actionHandlerService.updateCurrentCaseId.next(data.assessCaseID);
  //             break;
  //           case 'review':
  //             // do something?
  //             break;
  //           case 'view':
  //             if (data.formName?.length > 0 && data.submissionId?.length > 0) {
  //               this.afterViewInitObservables.push(
  //                 defer(() =>
  //                   {
  //                     this.assessService
  //                       .viewOrDownloadReport(null, data.assessCaseID, data.formName, data.submissionId, false)
  //                   }
  //                 )
  //               );
  //             }
  //             else
  //             {
  //               this.toastr.warning('Unable to view requested form.');
  //               this.debug.warn('Unable to process request to view form! Route Data:', val);
  //             }
  //             break;
  //           case 'download':
  //             if (data.formName?.length > 0 && data.submissionId?.length > 0) {
  //               this.afterViewInitObservables.push(
  //                 defer(() =>
  //                   {
  //                     this.assessService
  //                       .viewOrDownloadReport(null, data.assessCaseID, data.formName, data.submissionId, true)
  //                   }
  //                 )
  //               );
  //             }
  //             else
  //             {
  //               this.toastr.warning('Unable to download requested form.');
  //               this.debug.warn('Unable to process request to download form! Route Data:', val);
  //             }
  //             break;
  //           case '':
  //             // do something
  //             break;
  //           default:
  //             // do nothing
  //             break;
  //         }
  //       }
  //     }));
  // }
  //
  // ngAfterViewInit(): void {
  //   this.afterViewInitObservables.forEach(obs => {
  //     this.activeSubscriptions.push(
  //       obs.subscribe()
  //     );
  //   });
  // }
  //
  // ngOnDestroy(): void {
  //   // this.reloader$ub?.unsubscribe();
  //   // this.routeData$ub?.unsubscribe();
  //   // this.formSubmitSuccess$ub?.unsubscribe();
  //   // this.formSubmitError$ub?.unsubscribe();
  //   this.activeSubscriptions.forEach(sub => {
  //     sub?.unsubscribe();
  //   });
  // }
  //
  // ngOnInit(): void {
  //   localStorage.setItem('currentUrl', this.router.url);
  //
  //   // update userId
  //   this.currentUserId = this.assessService.getActiveUserId()
  //     ?? localStorage.getItem('currentAssessUserId');
  //
  //   this.sidebarHighlighter.highlightSideBar(this.router);
  //
  //   // //get building id
  //   // this.buildingId = this.route.parent.snapshot.paramMap.get('buildingId');
  //   // // console.log(this.buildingId);
  //   // localStorage.setItem('buildingId', this.buildingId);
  //   // localStorage.setItem('currentAssessBuildingId', this.buildingId);
  //
  //   this.loadData();
  //   this.reloader$ub = interval(90000).subscribe(() => {
  //     this.loadData();
  //   });
  //   this.activeSubscriptions.push(this.reloader$ub);
  //
  //   // if (this.router.url.includes('action')) {
  //   //   // getting information from url
  //   //   const URLActionName = this.route.snapshot.paramMap.get('action');
  //   //   const URLFormName = this.route.snapshot.paramMap.get('formName');
  //   //   const URLAssessCaseID = this.route.snapshot.paramMap.get('assessCaseID');
  //   //
  //   //   this.debug.log('PlansComponent.ngOnInit - URLActionName:', URLActionName,
  //   //     'URLAssessCaseID:', URLAssessCaseID,
  //   //     'URLFormName:', URLFormName);
  //   //   // if the application is assigning the form to user
  //   //   if (URLActionName == 'assign') {
  //   //     this.actionHandlerService.updateCurrentCaseId.next(URLAssessCaseID);
  //   //     this.router.navigateByUrl(
  //   //       `stage/${this.buildingId}/plans/${URLFormName}/${URLAssessCaseID}`
  //   //     );
  //   //   // if the application is reviewing the form to user
  //   //   } else if (URLActionName == 'review') {
  //   //     this.debug.log('GOING TO REVIEW PLAN!');
  //   //   }
  //   // }
  // }
  //
  // chooseForm(formNumber: number, assessCase: IAssessCaseInfo) {
  //   const thisFormName = AssessForms.names.plans[formNumber - 1];
  //   const thisCase = assessCase ?? this.currentCase;
  //
  //   if (!thisCase) {
  //     this.toastr.error("An error occurred. Please try again.");
  //     return;
  //   }
  //
  //   this.isOpeningForm = true;
  //   this.currentCase = thisCase;
  //   this.currentClickedCaseId = thisCase.CaseId;
  //   localStorage.setItem('selectedAssessCaseId', thisCase.CaseId);
  //   this.router.navigate([
  //     `stage/${this.buildingId}/plans/${thisFormName}/${thisCase.CaseId}`,
  //   ]);
  // }
  //
  // loadData() {
  //   //load data from the
  //   this.activeSubscriptions.push(
  //     this.assessService
  //       .getAssessInfoByBuildingID(parseInt(this.buildingId))
  //       .subscribe({
  //         next: (res: any) => {
  //           this.plansData = this.getPlansData(res.data);
  //           console.log(this.plansData);
  //           this.temp = [...this.plansData];
  //
  //           //set up the assessment team data
  //           this.assessmentTeamList = [];
  //           res.assessUsers.forEach((user) => {
  //             if (user.permissions?.settingsPermissions?.
  //               assess_permissions?.canSubmitAssessments == true) {
  //               this.assessmentTeamList.push({
  //                 userId: user.userID,
  //                 userDisplayName: user.userDisplayName,
  //                 permissions: user.permissions,
  //                 isChecked: false,
  //               });
  //             }
  //           });
  //
  //           //update current case number--------------------------------------------
  //           const dataArray = res.data;
  //
  //           this.stageJobsCount.Evaluations = 0;
  //           this.stageJobsCount.Assessments = 0;
  //           this.stageJobsCount.Plans = 0;
  //           this.stageJobsCount.Interventions = 0;
  //           this.stageJobsCount.Monitoring = 0;
  //           this.stageJobsCount.Closed = 0;
  //
  //           for (let i = 0; i < dataArray.length; i++) {
  //             const data = dataArray[i].CurrentStage;
  //
  //             if (data == 'Evaluations') {
  //               this.stageJobsCount.Evaluations += 1;
  //             }
  //             if (data == 'Assessments') {
  //               this.stageJobsCount.Assessments += 1;
  //             }
  //             if (data == 'RMS-Plans') {
  //               this.stageJobsCount.Plans += 1;
  //             }
  //             if (data == 'Interventions') {
  //               this.stageJobsCount.Interventions += 1;
  //             }
  //             if (data == 'Monitoring') {
  //               this.stageJobsCount.Monitoring += 1;
  //             }
  //             if (data == 'Closed') {
  //               this.stageJobsCount.Closed += 1;
  //             }
  //           }
  //
  //           this.actionHandlerService.currentCaseNumber.next(this.stageJobsCount);
  //           //end update current case number--------------------------------------------
  //         },
  //         error: (err: any) => {
  //           console.log(err);
  //         },
  //       })
  //   );
  // }
  //
  // getPlansData(apiResponse: IAssessCaseInfo[]) {
  //   const dataArray = [];
  //
  //   apiResponse.forEach((data: any) => {
  //     dataArray.push(data);
  //   });
  //
  //   const plansArray = [];
  //
  //   dataArray.forEach((data: any) => {
  //     if (data.CurrentStage == 'RMS-Plans') {
  //       if (data.Stages['RMS-Plans']) {
  //         plansArray.push(data);
  //       }
  //     }
  //   });
  //
  //   return plansArray.reverse();
  // }
  //
  // formatDate(dateString: string) {
  //   const datepipe: DatePipe = new DatePipe('en-US');
  //   return datepipe.transform(dateString, 'dd-MMM-YYYY HH:mm:ss');
  // }
  //
  // updateFilter(event) {
  //   const val = event.target.value.toLowerCase();
  //   // filter our data
  //   const temp = this.temp.filter(function (d) {
  //     return (
  //       d.CaseId.toLowerCase().indexOf(val) !== -1 ||
  //       d.LastUpdatedByName.toLowerCase().indexOf(val) !== -1 ||
  //       d.IncidentSummary.toLowerCase().indexOf(val) !== -1 ||
  //       !val
  //     );
  //   });
  //   // update the rows
  //   this.plansData = temp;
  //   // Whenever the filter changes, always go back to the first page
  //   this.table.offset = 0;
  // }
  //
  // // interviewTeacherStaff() {
  // //   this.isChoosingCaseId = true;
  // // }
  //
  // open(content, assessCase?: IAssessCaseInfo) {
  //   // this.debug.log(content);
  //   if (assessCase)
  //     this.currentCase = assessCase;
  //
  //   this.modalService
  //     .open(content, {
  //       ariaLabelledBy: 'modal-basic-title',
  //       backdrop: 'static',
  //       keyboard: false,
  //       size: 'lg'
  //     })
  //     .result.then(
  //       (result) => {
  //         this.closeResult = `Closed with: ${result}`;
  //       },
  //       (reason) => {
  //         this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
  //       }
  //     );
  // }
  //
  // private getDismissReason(reason: any): string {
  //   if (reason === ModalDismissReasons.ESC) {
  //     return 'by pressing ESC';
  //   } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
  //     return 'by clicking on a backdrop';
  //   } else {
  //     return `with: ${reason}`;
  //   }
  // }
  //
  // rowClickHandler(event) {
  //   if (event.type == 'click') {
  //     this.actionHandlerService.updateCurrentStudentName.next(
  //       event.row.IncidentContact.ContactFirstName +
  //       ' ' +
  //       event.row.IncidentContact.ContactLastName
  //     );
  //     this.debug.log('current row is: ', event.row);
  //     this.actionHandlerService.updateCurrentCaseId.next(event.row.CaseId);
  //     this.currentCase = event.row;
  //     this.currentClickedCaseId = event.row.CaseId;
  //     this.currentActionHistory = this.assessService.caseCurrentStageActions(event.row);
  //
  //     // for showing attachments
  //     this.attachmentsCompletedForms =
  //       this.currentCase.Attachments?.completedForms;
  //     this.attachmentsInProgressForms =
  //       this.currentCase.Attachments?.inProgressForms;
  //     this.attachmentsFileLinks = this.currentCase.Attachments?.fileLinks;
  //   }
  // }
  //
  // getDataFromActivityArray(actArray: any[]) {
  //   if (!(actArray?.length > 0))
  //     return null;
  //
  //   const res =
  //     actArray[actArray.length - 1].Action +
  //     ' (' +
  //     actArray[actArray.length - 1].ActionName +
  //     ')';
  //   return res;
  // }
  //
  // closeForm() {
  //   this.isOpeningForm = false;
  //   this.actionHandlerService.updateCurrentCaseId.next('');
  //   this.actionHandlerService.updateCurrentStudentName.next('');
  //   this.router.navigateByUrl(`stage/${this.buildingId}/plans`);
  // }
  //
  // startRMSReview(p_case: IAssessCaseInfo, skipNavigation?: boolean): void {
  //   this.debug.log('Attempting to start review process for case:', p_case.CaseId);
  //   const _subId = this.assessService.caseGetLastSubmittedFormSubId(p_case, this.reviewForm);
  //
  //   if (!_subId) {
  //     this.toastr.error("Unable to locate submission for review! Please try again. If the error persists, contact nSide for support.");
  //     return;
  //   }
  //
  //   localStorage.setItem('formsFormName', this.reviewForm);
  //
  //   this.isOpeningForm = true;
  //
  //   if (!skipNavigation) {
  //     const _path = `stage/${this.buildingId}/plans/request/${p_case.CaseId}/review/${this.reviewForm}/${_subId}`;
  //     console.log('navigating to:', _path);
  //     this.modalService.dismissAll();
  //     this.router.navigate([_path]);
  //   }
  // }
  //
  // // // check if users submitted the rms documentations form or not
  // // isPlanDocumentationSubmitted(currentCase) {
  // //   let isSubmitted = false;
  // //
  // //   const lastAction = currentCase.LastAction;
  // //
  // //   for (let i = 0; i < lastAction.length; i++) {
  // //     if (lastAction[i].ActionName === 'RMS Plan Documentation Submitted') {
  // //       isSubmitted = true;
  // //       break;
  // //     }
  // //   }
  // //
  // //   return isSubmitted;
  // // }
  //
  // // submitAssessmentTeam() {
  // //   // prepare data for api request
  // //   const checkedUsers = this.assessmentTeamList.filter(
  // //     (user) => user.isChecked
  // //   );
  // //   this.debug.log(checkedUsers);
  // //   const userIDs = [];
  // //   for (let i = 0; i < checkedUsers.length; i++) {
  // //     userIDs.push(checkedUsers[i].userId);
  // //   }
  // //   this.debug.log(this.buildingId, this.currentCase.CaseUID, userIDs);
  // //
  // //   const buildingId = parseInt(this.buildingId);
  // //   const assessCaseUID = this.currentCase.CaseUID;
  // //
  // //   if (this.isSelectingTeamMember) {
  // //     this.assessService
  // //       .assignAssessmentTeam(buildingId, assessCaseUID, userIDs)
  // //       .subscribe(
  // //         (result: any) => {
  // //           if (result.statusCode == 200) {
  // //             this.toastr.success(
  // //               'The assessment team has been submitted!',
  // //               'Message:'
  // //             );
  // //           } else {
  // //             this.toastr.error(result.description, 'Error:');
  // //           }
  // //           this.debug.log(result);
  // //
  // //           //reload data
  // //           this.loadData();
  // //
  // //           //reset modal and dismiss the modal---------------
  // //           this.assessmentTeamList.forEach(
  // //             (person) => (person.isChecked = false)
  // //           );
  // //           this.modalService.dismissAll();
  // //         },
  // //         (error) => {
  // //           this.toastr.error(error.description, 'Error:');
  // //           this.debug.log(error);
  // //         }
  // //       );
  // //   } else {
  // //     // call api for assign task to another person using text/email-----------------------------
  // //     for (let i = 0; i < userIDs.length; i++) {
  // //       const currentUserId = userIDs[i];
  // //
  // //       this.assessService
  // //         .designateTeamAssessmentMember(
  // //           buildingId,
  // //           this.currentClickedCaseId,
  // //           currentUserId,
  // //           `Assign Threat Assessment Team Members`,
  // //           ''
  // //         )
  // //         .subscribe((res: any) => {
  // //           if (res.statusCode == 200) {
  // //             this.modalService.dismissAll();
  // //             this.toastr.success(
  // //               'The message has been sent to the target user!'
  // //             );
  // //           }
  // //         });
  // //     }
  // //   }
  // // }
  // //
  // // assignTeamSelectionTaskToUser() {
  // //   const buildingId = this.buildingId;
  // //   const currentCaseId = this.currentCase.CaseId;
  // //
  // //   // for getting submissionId---------------------------------------------------------
  // //   let submissionId = '';
  // //   const lastActions = this.currentCase.LastAction;
  // //
  // //   for (let i = 0; i < lastActions.length; i++) {
  // //     if (
  // //       lastActions[i].ActionName ==
  // //       'RMS Plan Documentation Submitted'
  // //     ) {
  // //       submissionId = lastActions[i].SubmissionId;
  // //       break;
  // //     }
  // //   }
  // //   //----------------------------------------------------------------------------------
  // //
  // //   const teamSelectionRoute = `http://localhost:4200/action/${buildingId}/assessments/review/team/${currentCaseId}/assess-threat-assessment-full/${submissionId}`;
  // //   alert(
  // //     'Send an email to the target user with this link: ' + teamSelectionRoute
  // //   );
  // // }
  // //
  // // isAbleToCreate(currentCase: IAssessCaseInfo, formNumber: number) {
  // //   if (!currentCase.AssignedTeam)
  // //     return false;
  // //
  // //   if (formNumber >= AssessForms.names.plans.length)
  // //     return false;
  // //
  // //   const thisFormName = AssessForms.names.plans[formNumber - 1];
  // //
  // //   switch (thisFormName) {
  // //     case 'assess-rms-checklist':
  // //       if (this.assessService.caseHasReviewedForm(currentCase, 'assess-rms-checklist'))
  // //         return false;
  // //       break;
  // //     case 'assess-roar-assignment':
  // //       if (!this.assessService.caseHasReviewedForm(currentCase, 'assess-rms-checklist'))
  // //         return false;
  // //       break;
  // //     case 'assess-rms-review-schedule':
  // //       if (!this.assessService.caseHasReviewedForm(currentCase, 'assess-rms-checklist'))
  // //         return false;
  // //       break;
  // //     case 'assess-rms-summary':
  // //       if (!this.assessService.caseHasReviewedForm(currentCase, 'assess-rms-checklist'))
  // //         return false;
  // //       break;
  // //     default:
  // //       return false;
  // //   }
  // //
  // //   return currentCase.AssignedTeam?.Users?.
  // //     findIndex(w => w.id?.toString() == this.currentUserId?.toString()) > -1;
  // // }
  // //
  // // // check if users submitted the case assessment form or not
  // // // and check if the current user is assigned to this case.
  // // isAbleToReview(currentCase: IAssessCaseInfo, formNumber: number) {
  // //   // if the case is not assigned to the team, then cannot review anything.
  // //   if (!currentCase.AssignedTeam)
  // //     return false;
  // //
  // //   if (formNumber >= AssessForms.names.plans.length
  // //     || !this.assessService.caseHasReviewRequestForForm(currentCase, AssessForms.names.plans[formNumber - 1]))
  // //     return false;
  // //
  // //   return currentCase.AssignedTeam?.Users?.
  // //   findIndex(w => w.id?.toString() == this.currentUserId?.toString()) > -1;
  // // }
  // //
  // // // // check if users submitted the case assessment form or not
  // // // // and check if the current user is assigned to this case.
  // // // isAbleToReview(currentCase) {
  // // //   // if the case is not assigned to the team, then cannot review anything.
  // // //   if (!currentCase.AssignedTeam) return false;
  // // //
  // // //   let isMadeForm = false;
  // // //   const lastAction = currentCase.LastAction;
  // // //   for (let i = 0; i < lastAction.length; i++) {
  // // //     if (
  // // //       lastAction[i].ActionName === 'RMS Plan Documentation Submitted'
  // // //       //need to check if the case is submitted by current user or not
  // // //     ) {
  // // //       isMadeForm = true;
  // // //     }
  // // //   }
  // // //
  // // //   let isAssignedToTheCase = false;
  // // //
  // // //   if (currentCase.AssignedTeam) {
  // // //
  // // //     const assignedUsers = currentCase.AssignedTeam.Users;
  // // //     for (let i = 0; i < assignedUsers.length; i++) {
  // // //       if (assignedUsers[i].id == this.currentUserId) {
  // // //         isAssignedToTheCase = true;
  // // //         break;
  // // //       }
  // // //     }
  // // //   }
  // // //
  // // //   return (isMadeForm && isAssignedToTheCase);
  // // // }
  // //
  // // // check if the assessment has been assigned or not
  // // isAbleToAssignAssessmentTeam(currentCase) {
  // //   if (this.hasCanManageCasesPermission()) return true;
  // //
  // //   if (!currentCase.AssignedTeam) return this.hasCanSubmitAssessmentsPermission();
  // //
  // //   const assignedUsersArray: any[] = currentCase.AssignedTeam?.Users;
  // //   for (let j = 0; j < assignedUsersArray.length; j++) {
  // //     const teamUserId = assignedUsersArray[j].id;
  // //     if (this.currentUserId == teamUserId) {
  // //       return true;
  // //     }
  // //   }
  // //   return true;
  // // }
  // //
  // // // check if the user has certain permission or not
  // // hasCanManageCasesPermission() {
  // //   const currentUserID = this.currentUserId;
  // //   for (let i = 0; i < this.assessmentTeamList.length; i++) {
  // //     if (this.assessmentTeamList[i].userId?.toString() == currentUserID) {
  // //       return this.assessmentTeamList[i].permissions?.settingsPermissions?.assess_permissions?.canManageCases == true;
  // //     }
  // //   }
  // //   return false;
  // // }
  // //
  // // // check if the user has certain permission or not
  // // hasCanSubmitAssessmentsPermission() {
  // //   const currentUserID = this.currentUserId;
  // //   for (let i = 0; i < this.assessmentTeamList.length; i++) {
  // //     if (this.assessmentTeamList[i].userId?.toString() == currentUserID) {
  // //       return this.assessmentTeamList[i].permissions?.settingsPermissions?.assess_permissions?.canSubmitAssessments == true;
  // //     }
  // //   }
  // //   return false;
  // // }
  // //
  // // openTeamSelectingModal(assessCase?: any) {
  // //   const theCase = assessCase || this.currentCase;
  // //
  // //   if (!theCase) return;
  // //
  // //   this.currentCase = theCase;
  // //
  // //   this.assessmentTeamList.forEach((member) => {
  // //     member.isChecked = false;
  // //   });
  // //
  // //   // show current selected assessmen team member
  // //   if (theCase.AssignedTeam) {
  // //     const currentAssignedUsers: any[] = theCase.AssignedTeam.Users;
  // //
  // //     const assignedUserIdArray = [];
  // //     currentAssignedUsers.forEach((user) => {
  // //       const currentAssignedUserId = user.id;
  // //       assignedUserIdArray.push(currentAssignedUserId);
  // //     });
  // //
  // //     this.assessmentTeamList.forEach((member) => {
  // //       this.debug.log(assignedUserIdArray);
  // //       this.debug.log(member);
  // //       if (assignedUserIdArray.includes(member.userId + '')) {
  // //         member.isChecked = true;
  // //       }
  // //     });
  // //   }
  // //   //--------------------------------------------
  // //
  // //   this.isSelectingTeamMember = true;
  // //   this.teamSelectionTitle = `Team Selection for the Case ${theCase.CaseId}`;
  // //   this.open(this.designatedTeamSelectionModal);
  // // }
  // // openAssignTaskModal() {
  // //   this.isSelectingTeamMember = false;
  // //   this.teamSelectionTitle = `Assign assessment task to other people for the Case ${this.currentClickedCaseId}`;
  // //   this.open(this.designatedTeamSelectionModal);
  // // }
  // //
  // // backToDecision() {
  // //   this.modalService.dismissAll();
  // //   this.open(this.teamSelectionConsiderModal);
  // // }
  // //
  // // // ***********************************************************************
  // // // Able to create form when:                                             *
  // // // The team has been assigned                                            *
  // // // There are no Full Threat Assessment create before by the current user *
  // // // ***********************************************************************
  // // isAbleToCreateRMSPlans(currentCase) {
  // //   // if the current user in assessment team -----------------------------
  // //   // this.debug.log("THE CURRENT USER ID HERE!!!!", this.currentUserId);
  // //   let isCurrentUserInTeamAssessment = false;
  // //
  // //   // ----if the case is not assigned to the team, then false
  // //   if (!currentCase.AssignedTeam) return false;
  // //
  // //   const assignedUsersArray: any[] = currentCase.AssignedTeam.Users;
  // //
  // //   for (let j = 0; j < assignedUsersArray.length; j++) {
  // //     const teamUserId = assignedUsersArray[j].id;
  // //     // this.debug.log("IN TEAM USER ID!!", teamUserId);
  // //
  // //     if (this.currentUserId + '' == teamUserId + '') {
  // //       isCurrentUserInTeamAssessment = true;
  // //       break;
  // //     }
  // //   }
  // //   // end of if the current user in assessment team ----------------------
  // //
  // //   // if there is no full threat assessment submitted by the current user
  // //   let isNotSubmittedFullThreatAssessment = true;
  // //   const lastAction = currentCase.LastAction;
  // //   for (let i = 0; i < lastAction.length; i++) {
  // //     if (
  // //       // if the 'Full Team Threat Assessment Submitted' did submit, then user cannot create a form
  // //       lastAction[i].ActionName === 'RMS Plan Documentation Submitted' &&
  // //       lastAction[i].TakenBy + '' == this.currentUserId + ''
  // //     ) {
  // //       isNotSubmittedFullThreatAssessment = false;
  // //     }
  // //   }
  // //   // end of if there is no full threat assessment submitted by the current user
  // //
  // //   return isCurrentUserInTeamAssessment && isNotSubmittedFullThreatAssessment;
  // // }
  // //
  // // canCreateForm(currentCase: IAssessCaseInfo, formNumber: number): boolean {
  // //   if (!currentCase) return false;
  // //   const formIdx = Math.max(0, formNumber - 1);
  // //
  // //   if (formIdx >= AssessForms.names.plans.length) return false;
  // //
  // //   if (!this.isAbleToCreateRMSPlans(currentCase)) return false;
  // //
  // //   const hasReviewedChecklist = this.assessService.caseHasReviewedForm(currentCase, AssessForms.names.plans[formIdx]);
  // //
  // //   return formIdx > 0
  // //     ? hasReviewedChecklist
  // //     : !hasReviewedChecklist;
  // // }
  //
  // requestReview(currentCase: IAssessCaseInfo, reviewerID: number) {
  //   this.debug.log('Evaluations Component - Attempting to request a review for case:',
  //     currentCase, ' for user with ID:', reviewerID);
  //
  //   const _subId = this.assessService.caseGetLastSubmittedFormSubId(currentCase, this.reviewForm);
  //
  //   if (!_subId) {
  //     this.toastr.error("Unable to locate submission for review! Please try again. If the error persists, contact nSide for support.");
  //     return;
  //   }
  //   this.activeSubscriptions.push(
  //     this.assessService
  //       .requestForReview(
  //         currentCase,
  //         this.reviewForm,
  //         _subId,
  //         reviewerID
  //       )
  //       .subscribe((res) => {
  //         this.toastr.success(
  //           'Request Successful.',
  //           'Status: '
  //         );
  //         this.modalService.dismissAll();
  //         this.loadData();
  //       })
  //   );
  // }
  //
  // submitThreatAssessmentTeamAssignmentTask(userID: number): void {
  //   this.isWaitingForHttpRequest = true;
  //   const buildingId = this.currentCase?.BuildingId;
  //   const assessCaseUID = this.currentCase?.UID;
  //
  //   if (!(buildingId > 0
  //     && assessCaseUID > 0
  //     && userID > 0))
  //     return;
  //   this.activeSubscriptions.push(
  //     this.assessService
  //       .assignTeamAssessmentSelectionTask(
  //         buildingId,
  //         this.currentClickedCaseId,
  //         userID,
  //         null,
  //         'Assign Threat Assessment Team member for this case.'
  //       )
  //       .subscribe((res: any) => {
  //         this.isWaitingForHttpRequest = false;
  //         if (res.statusCode == 200) {
  //           this.modalService.dismissAll();
  //           this.toastr.success(
  //             'The message has been sent to the target user!'
  //           );
  //         }
  //       })
  //   );
  // }
  //
  // submitAssignedThreatAssessmentTeam(userIDs: number[]): void {
  //   this.isWaitingForHttpRequest = true;
  //   const buildingId = this.currentCase?.BuildingId;
  //   const assessCaseUID = this.currentCase?.UID;
  //
  //   if (!(buildingId > 0
  //     && assessCaseUID > 0
  //     && userIDs?.length > 0))
  //     return;
  //   this.activeSubscriptions.push(
  //     this.assessService
  //       .updateThreatAssessmentTeam(buildingId, assessCaseUID, userIDs)
  //       .subscribe(
  //         (result: any) => {
  //           this.isWaitingForHttpRequest = false;
  //           if (result.statusCode == 200) {
  //             this.toastr.success(
  //               'The assessment team has been submitted!',
  //               'Message:'
  //             );
  //           } else {
  //             this.toastr.error(result.description, 'Error:');
  //           }
  //           this.debug.log(result);
  //
  //           //reload data
  //           this.loadData();
  //           this.modalService.dismissAll();
  //         },
  //         (error) => {
  //           this.toastr.error(error.description, 'Error:');
  //           this.debug.log(error);
  //         }
  //       )
  //   );
  // }
  //
  // openTeamSelectingModal(assessCase: IAssessCaseInfo) {
  //   this.currentCase = assessCase;
  //   this.isSelectingTeamMember = true;
  //
  //   this.assessUserList.forEach(usr => {
  //     usr.isSelected = assessCase?.AssignedTeam?.Users
  //       ?.findIndex(w => w.id?.toString() == usr.userID.toString()) >= 0;
  //   });
  //
  //   this.teamSelectionTitle = `Threat Assessment Team for case ${this.currentCase?.CaseId}`;
  //   this.open(this.threatAssessmentTeamSelectionModal);
  // }
  //
  // openAssignTaskModal(assessCase: IAssessCaseInfo) {
  //   this.currentCase = assessCase;
  //   this.isSelectingTeamMember = false;
  //   this.assignedUserId = -1;
  //   this.teamSelectionTitle = `Assign Threat Assessment Team selection task for case ${this.currentCase?.CaseId}`;
  //   this.open(this.threatAssessmentTeamSelectionModal);
  // }
  //
  // backToDecision() {
  //   this.modalService.dismissAll();
  //   this.open(this.teamSelectionConsiderModal);
  // }
  //
  // // check if the assessment has been assigned or not
  // isAbleToAssignAssessmentTeam(currentCase) {
  //   if (this.hasCanManageCasesPermission()) return true;
  //
  //   // ----if the case is not assigned to the team, then false
  //   if (!currentCase.AssignedTeam) return this.hasCanSubmitAssessmentsPermission();
  //
  //   const assignedUsersArray: any[] = currentCase.AssignedTeam?.Users;
  //   for (let j = 0; j < assignedUsersArray.length; j++) {
  //     const teamUserId = assignedUsersArray[j].id;
  //     if (this.currentUserId == teamUserId) {
  //       return true;
  //     }
  //   }
  //   return true;
  // }
  //
  // // check if the user has certain permissions or not
  // hasCanManageCasesPermission() {
  //   for (let i = 0; i < this.assessUserList.length; i++) {
  //     if (this.assessUserList[i].userID?.toString() == this.currentUserId) {
  //       return this.assessUserList[i].permissions?.settingsPermissions?.assess_permissions?.canManageCases == true;
  //     }
  //   }
  //
  //   return false;
  // }
  //
  // hasCanSubmitAssessmentsPermission() {
  //   for (let i = 0; i < this.assessUserList.length; i++) {
  //     if (this.assessUserList[i].userID?.toString() == this.currentUserId) {
  //       return this.assessUserList[i].permissions?.settingsPermissions?.assess_permissions?.canSubmitAssessments == true;
  //     }
  //   }
  //
  //   return false;
  // }
  //
  // viewDownloadClick(event: any, assessCaseId?: string, formName?: string, submissionId?: string, download?: boolean) {
  //   this.debug.log("Assessments.Component - viewDownloadClick executed. event:", event, "assessCaseId", assessCaseId, "submissionId:", submissionId);
  //   this.assessService.viewOrDownloadReport(event, assessCaseId, formName, submissionId, download);
  // }
}
