import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IStudentOfConcernInfoResult } from '../student-concern-dialogs-helpers';

@Component({
  selector: 'nside-assess-student-concern-confirmation',
  templateUrl: './student-concern-confirmation.component.html',
  styleUrls: ['./student-concern-confirmation.component.css']
})
export class StudentConcernConfirmationComponent {

  constructor(
    public dialogRef: MatDialogRef<StudentConcernConfirmationComponent>,
    @Inject(MAT_DIALOG_DATA) public result: IStudentOfConcernInfoResult) {
    this.result = result || { action: null, data: null };
    this.result.action = null;
  }

  onNoClick(): void {
    this.result.action = "Cancel";
    this.dialogRef.close(this.result);
  }

  onYesClick(): void {
    this.result.action = "OK";
    this.dialogRef.close(this.result);
  }

  isValid(): boolean {
    return this.result.data.state_identifier?.length > 0
      && this.result.data.first_name?.length > 0
      && this.result.data.last_name?.length > 0
      && this.result.data.birth_date?.length > 0
      && this.result.data.identified_gender?.length > 0
      && this.result.data.grade?.length > 0
      && this.result.data.physical_description?.length > 0
      && this.result.data.ihp?.length > 0
      && this.result.data.iep?.length > 0
      && this.result.data["504"]?.length > 0;
  }
}
