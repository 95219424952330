<h1 mat-dialog-title>No records found in nSide. Would you like to begin a new assessment?</h1>
<div mat-dialog-content>
  <mat-form-field appearance="fill">
    <mat-label>State Identifier: </mat-label>
    <input matInput disabled='true' [(ngModel)]="result.data.stateIdentifier">
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button color='warn' (click)="onNoClick()">No, Cancel</button>
  <button mat-button color='primary' (click)="onYesClick()" [disabled]='!isValid()'>Yes, Continue</button>
</div>
