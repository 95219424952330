import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { AuthorizationService } from '@clients-nside-io/shared/auth';
import { FormService } from '../../../../../forms/src/app/shared/form.service';
import { LocalStoreService } from '../../../../../../libs/shared/services/src/lib/localStore/localStore.service';

@Component({
  selector: 'clients-nside-io-unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.scss']
})
export class UnauthorizedComponent {

  constructor(private router: Router,
    private auth: AuthorizationService,
    private form: FormService,
    private localStore: LocalStoreService) { }

  isLoggedIn$ = this.auth.checkAuth().pipe(
    map((loggedIn) => {
      if (loggedIn) {
        // const redirectUrl = localStorage.getItem('currentUrl');
        const redirectUrl = this.localStore.currentRoute;
        this.router.navigateByUrl(redirectUrl?.length > 0 ? redirectUrl : '');
        return true;
      } else {
        this.form.handleError("Unauthorized", "Authorization failed. Please try logging in again. Contact support if the problem persists")
        return false;
      }
    })
  );

}
