<h1 mat-dialog-title>Please verify the correct Student of Concern information for this Behavioral Threat Assessment.</h1>
<div mat-dialog-content>
  <mat-form-field appearance="fill">
    <mat-label>State Identifier: </mat-label>
    <input matInput disabled='true' [(ngModel)]="result.data.state_identifier">
  </mat-form-field>
  <mat-form-field appearance="fill">
    <mat-label>First Name: </mat-label>
    <input matInput disabled='true' [(ngModel)]="result.data.first_name">
  </mat-form-field>
  <mat-form-field appearance="fill">
    <mat-label>Last Name: </mat-label>
    <input matInput disabled='true' [(ngModel)]="result.data.last_name">
  </mat-form-field>
  <mat-form-field appearance="fill">
    <mat-label>Birth Date: </mat-label>
    <input matInput disabled='true' [(ngModel)]="result.data.birth_date">
  </mat-form-field>
  <mat-form-field appearance="fill">
    <mat-label>Grade: </mat-label>
    <input matInput disabled='true' [(ngModel)]="result.data.grade">
  </mat-form-field>
  <mat-form-field appearance="fill">
    <mat-label>Identified Gender: </mat-label>
    <input matInput disabled='true' [(ngModel)]="result.data.identified_gender">
  </mat-form-field>
</div>
<br />
<div>
  <h5>Existing nSide Behavioral Threat Assessment Cases for this Student of Concern:</h5>
  <div *ngIf='this.result.data.assess_cases?.length > 0'>
    <mat-form-field appearance="fill" *ngFor="let case_info of result.data.assess_cases">
      <mat-label>Case ID, District, & School Name: </mat-label>
      <input matInput disabled='true' [(ngModel)]="case_info.assess_case_id">
      <input matInput disabled='true' [(ngModel)]="case_info.district_name">
      <input matInput disabled='true' [(ngModel)]="case_info.school_name">
    </mat-form-field>
  </div>
  <div *ngIf='!(this.result.data.assess_cases?.length > 0)'>
    <mat-label>
      No existing nSide Behavioral Threat Assessment cases found for this Student of Concern.
    </mat-label>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-button color='warn' (click)="onNoClick()">Cancel</button>
  <button mat-button color='primary' (click)="onYesClick()" [disabled]='!isValid()'>Continue</button>
</div>
