import { Component, Input } from '@angular/core';

@Component({
  selector: 'clients-nside-io-navigation-bar',
  templateUrl: './navigation-bar.component.html',
  styleUrls: ['./navigation-bar.component.scss']
})
export class NavigationBarComponent {
  @Input() hasHeader: boolean;
}
