<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">
    ROAR Assignments and Activity Logs for Case {{assessCase?.CaseId}}
  </h4>
  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="container-fluid">
    <div class="row" *ngIf="roarAssignments">
      <b class="text-success">ROAR Assignments: </b>
      <table class="table">
        <thead>
        <tr>
          <th scope="col">Info</th>
          <th scope="col">Last Updated</th>
          <th scope="col">Last Updated By</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let attachment of roarAssignments">
          <td>
            <div>{{attachment.name}}</div>
            <div>
              <span>Category & Type: {{ attachment.roar_category ?? 'UNKNOWN' }} - {{ attachment.roar_type ?? 'UNKNOWN' }}</span>
              <p>Review Required: {{ attachment.roar_review_required === true ? 'Yes' : 'No' }}&nbsp;&nbsp;
                <span *ngIf='attachment.roar_reviewer_display_name'>Reviewed By: {{ attachment.roar_reviewer_display_name }}</span>
              </p>
            </div>
            <div class="float-bottom">
              <p>Administrative Summary: {{ attachment.roar_admin_summary }}</p>
            </div>
          </td>
          <td>
            <div>{{formatDate(attachment.date)}}</div>
          </td>
          <td>
            <div>{{attachment.user}}</div>
            <div>&nbsp;</div>
            <div>
              <button class="btn btn-primary btn-table"
                      [disabled]='!activityLogEnabled'
                      (click)='emitLogActivity(attachment.submissionId)'>Log Activity</button>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="row" *ngIf="roarActivity">
      <b class="text-warning">ROAR Activity Logs: </b>
      <table class="table">
        <thead>
        <tr>
          <th scope="col">Info</th>
          <th scope="col">Last Updated</th>
          <th scope="col">Last Updated By</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let attachment of roarActivity">
          <td>
            <div>{{attachment.name}}</div>
            <div>
              <span>Category & Type: {{ attachment.roar_category ?? 'UNKNOWN' }} - {{ attachment.roar_type ?? 'UNKNOWN' }}</span>
              <p>Review Required: {{ attachment.roar_review_required === true ? 'Yes' : 'No' }}&nbsp;&nbsp;
                <span *ngIf='attachment.roar_reviewer_display_name'>Reviewed By: {{ attachment.roar_reviewer_display_name }}</span>
              </p>
            </div>
            <div class="float-bottom">
              <p>Administrative Summary: {{ attachment.roar_admin_summary }}</p>
            </div>
          </td>
          <td>
            <div>{{formatDate(attachment.date)}}</div>
          </td>
          <td>
            <div>{{attachment.user}}</div>
            <div>&nbsp;</div>
            <div>
              <button class="btn btn-primary btn-table"
                      *ngIf='attachment.roar_review_required'
                      [disabled]='!activityReviewEnabled'
                      (click)='emitReviewActivity(attachment.submissionId)'>Review Activity Log</button>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
