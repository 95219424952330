import { AssessStages } from './AssessGeneral';

export const AssessForms = {
  names: {
    home: [
      'assess-threat-assessment-screen'
    ],
    evaluations: [
      'assess-threat-assessment-screen'
    ],
    assessments: [
      'assess-threat-assessment-full',
      'assess-teacher-staff-interview',
      'assess-witness-interview',
      'assess-student-concern-interview',
      'assess-parent-interview'
      //"assess-suicide-interview",
    ],
    plans: [
      'assess-rms-checklist',
      'assess-roar-assignment',
      'assess-rms-review-schedule',
      'assess-rms-summary'
    ],
    interventions: [
      'assess-threat-assessment-summary',
      'assess-roar-assignment',
      'assess-roar-activity'
    ],
    monitoring: [
      'assess-case-closing-summary',
      'assess-roar-assignment',
      'assess-roar-activity',
      'assess-rms-review-log'
    ],
    closed: [],
    roarAssignment: 'assess-roar-assignment',
    roarActivity: 'assess-roar-activity'
  },
  displayNames: {
    home: [
      'Threat Assessment Screen'
    ],
    evaluations: [
      'Threat Assessment Screen'
    ],
    assessments: [
      'Full Team Threat Assessment',
      'Teacher/Staff Interview',
      'Witness Interview',
      'Student-of-Concern Interview',
      'Parent Interview'
      //"Suicide Assessment Interview",
    ],
    plans: [
      'RMS Plan Checklist',
      'ROAR Assignment Form',
      'RMS Plan Review Schedule',
      'RMS Plan Summary'
    ],
    interventions: [
      'Threat Assessment Case Summary',
      'ROAR Assignment Form',
      'ROAR Activity Log'
    ],
    monitoring: [
      'Case Closing Summary',
      'ROAR Assignment Form',
      'ROAR Activity Log',
      'RMS Plan Record of Review'
    ],
    closed: []
  },
  isReviewable(formName: string): boolean {
    return [
      this.names.evaluations[0],
      this.names.assessments[0],
      // this.names.plans[0],
      this.names.plans[3],
      this.names.interventions[0],
      this.names.interventions[2],
      this.names.monitoring[0],
      // this.names.monitoring[3]
    ].findIndex(w => w == formName) >= 0;
  },
  isReviewRequired(formName: string): boolean {
    return [
      this.names.evaluations[0],
      this.names.assessments[0],
      // this.names.plans[0],
      this.names.plans[3],
      this.names.interventions[0],
      this.names.monitoring[0],
      // this.names.monitoring[3]
    ].findIndex(w => w == formName) >= 0;
  },
  namesIfHasPrerequisiteForms(stage: string, formName: string): string[] {
    switch (stage) {
      case 'evaluations':
      case 'Evaluations':
      case AssessStages.evaluations:
        return formName == this.names.evaluations[0]
          ? []
          : [this.names.evaluations[0]];
        break;
      case 'assessments':
      case 'Assessments':
      case AssessStages.assessments:
        return [];
        break;
      case 'plans':
      case 'RMS-Plans':
      case AssessStages.plans:
        return formName == this.names.plans[0]
          ? []
          : formName == this.names.plans[3]
            ? [this.names.plans[0], this.names.plans[2]]
            : [this.names.plans[0]];
        break;
      case 'interventions':
      case 'Interventions':
      case AssessStages.interventions:
        return [];
        break;
      case 'monitoring':
      case 'Monitoring':
      case AssessStages.monitoring:
        return [];
        break;
      case 'closed':
      case 'Closed':
      case AssessStages.closed:
        return [];
        break;
      default:
        return ['ERROR'];
    }
  },
  getFormsFor(name: string): string[] {
    switch (name) {
      case 'evaluations':
      case 'Evaluations':
        return this.names.evaluations;
        break;
      case 'assessments':
      case 'Assessments':
        return this.names.assessments;
        break;
      case 'plans':
      case 'RMS-Plans':
        return this.names.plans;
        break;
      case 'interventions':
      case 'Interventions':
        return this.names.interventions;
        break;
      case 'monitoring':
      case 'Monitoring':
        return this.names.monitoring;
        break;
      case 'closed':
      case 'Closed':
        return this.names.closed;
        break;
      default:
        return [];
    }
  }
};
