<h1 mat-dialog-title>Please enter the student's unique, State-issued Identifier (SSID).</h1>
<div mat-dialog-content>
  <mat-form-field appearance="fill">
    <mat-label>State Identifier (SSID): </mat-label>
    <input matInput required [(ngModel)]="result.data.stateIdentifier">
    <mat-error>This field is required!</mat-error>
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()" color='warn'>Cancel</button>
  <button mat-button (click)="onYesClick()" color='primary' [disabled]="!isValid()">Submit</button>
</div>
