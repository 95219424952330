import { IEnvironment } from '@clients-nside-io/shared/models';

export const environment: IEnvironment = {
  production: false,
  debugging: true,
  formApiBase: 'https://api.dev.nside.io',
  formApiVersion: 'v3.1',
  platformBaseUrl: 'https://dev.nside.io',
  platformDefaultUrl: 'https://dev.nside.io/Default.aspx',
  identityConfig: {
    stsServer: 'https://identity.dev.nside.io',
    redirectUrl: `${window.location.origin}`,
    postLogoutRedirectUri: 'https://dev.nside.io/Default.aspx',
    clientId: 'assess.dev.nside.io',
    scope: 'api.dev.nside.io openid roles profile offline_access',
    responseType: 'code',
    silentRenew: true,
    silentRenewUrl: `${window.location.origin}/silent-renew.html`,
    useRefreshToken: true,
    renewTimeBeforeTokenExpiresInSeconds: 30,
    // logLevel: LogLevel.Debug,
  },
};
