<div class="container-scroller">
  <div class="container-fluid">
    <div class="row">
      <div
        class="content-wrapper full-page-wrapper d-flex align-items-center text-center error-page"
      >
        <div class="col-lg-6 offset-lg-3">
          <h1 class="display-1">500</h1>
          <h2>Your building ID is incorrect!</h2>
        </div>
      </div>
    </div>
  </div>
</div>
