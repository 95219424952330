import { Component, Input, OnInit } from '@angular/core';
import { AssessService } from '../../../services/assess.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DebugUtils } from '@clients-nside-io/shared/util';
import { DatePipe } from '@angular/common';
import { IAssessCaseInfo } from '@clients-nside-io/shared/models';

@Component({
  selector: 'nside-assess-attachments-list',
  templateUrl: './attachments-list.component.html',
  styleUrls: ['./attachments-list.component.css']
})
export class AttachmentsListComponent {
  @Input() assessCase: IAssessCaseInfo;
  @Input() activeModal: NgbActiveModal;

  constructor(
    private assessService: AssessService,
    private debug: DebugUtils) { }

  formatDate(dateValue: Date | string) {
    const datePipe: DatePipe = new DatePipe('en-US');
    return datePipe.transform(dateValue, 'dd-MMM-YYYY HH:mm:ss');
  }

  viewDownloadClick(event: any, assessCaseId?: string, formName?: string, submissionId?: string, download?: boolean) {
    this.debug.log("Assessments.Component - viewDownloadClick executed. event:", event, "assessCaseId", assessCaseId, "submissionId:", submissionId);
    this.assessService.viewOrDownloadReport(event, assessCaseId, formName, submissionId, download);
  }

}
