import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class SidebarHighlightService {
  constructor() {}

  highlightSideBar(router: Router) {
    console.log('IS USING HIGHLIGHTER SERVICE!!!');
    const url = router.url;

    const navItems = document.getElementsByClassName('nav-link');
    for (let i = 0; i < navItems.length; i++) {
      const currentItem = navItems[i];
      currentItem.classList.remove('active');
    }

    if (url.includes('evaluations')) {
      navItems[1].classList.add('active');
    } else if (url.includes('assessments')) {
      navItems[2].classList.add('active');
    } else if (url.includes('plans')) {
      navItems[3].classList.add('active');
    } else if (url.includes('interventions')) {
      navItems[4].classList.add('active');
    } else if (url.includes('monitoring')) {
      navItems[5].classList.add('active');
    } else if (url.includes('closed')) {
      navItems[6].classList.add('active');
    }
  }
}
