import { AuthorizationFormsGuard } from '@clients-nside-io/shared/auth';
import { NgModule } from '@angular/core';

import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './views/home/home.component';
import { StageComponent } from './views/stage/stage.component';
import { EvaluationsComponent } from './components/stage-components/evaluations/evaluations.component';
import { AssessmentsComponent } from './components/stage-components/assessments/assessments.component';
import { PlansComponent } from './components/stage-components/plans/plans.component';
import { InterventionsComponent } from './components/stage-components/interventions/interventions.component';
import { MonitoringComponent } from './components/stage-components/monitoring/monitoring.component';
import { ClosedComponent } from './components/stage-components/closed/closed.component';
import { PageNotFoundComponent } from './views/page-not-found/page-not-found.component';
import { RedirectComponent } from './views/redirect/redirect.component';
import { AuthorizationGuard } from '@clients-nside-io/shared/auth';
import { UnauthorizedComponent } from './views/unauthorized/unauthorized.component';
import { BuildingIdSelectionComponent } from '@clients-nside-io/shared/components';
import { ReviewTeamSelectingComponent } from './views/case-based/review-team-selecting/review-team-selecting.component';

const routes: Routes = [
  // {
  //   path: '',
  //   component: BuildingIdSelectionComponent,
  //   canActivate: [AuthorizationGuard],
  // },
  {
    path: 'action/:buildingId',
    pathMatch: 'prefix',
    component: StageComponent,
    canActivate: [AuthorizationGuard],
    children: [
      {
        path: 'evaluations/:action',
        pathMatch: 'prefix',
        children: [
          {
            path: '',
            pathMatch: 'full',
            component: EvaluationsComponent,
            canActivate: [AuthorizationFormsGuard],
          },
          {
            path: ':assessCaseID/:formName',
            pathMatch: 'full',
            canActivate: [AuthorizationFormsGuard],
            component: EvaluationsComponent,
            loadChildren: () =>
              // eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
              import('@clients-nside-io/apps/forms/form/module').then(
                (m) => m.FormModule
              ),
            data: {
              isOpeningForm: true
            }
          },
          {
            path: ':assessCaseID/:formName/:submissionId',
            pathMatch: 'full',
            canActivate: [AuthorizationFormsGuard],
            component: EvaluationsComponent,
            loadChildren: () =>
              // eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
              import('@clients-nside-io/apps/forms/form/module').then(
                (m) => m.FormModule
              ),
            data: {
              isOpeningForm: true
            }
          },
          {
            path: 'pdf/:assessCaseID/:formName/:submissionId',
            pathMatch: 'full',
            canActivate: [AuthorizationFormsGuard],
            component: PlansComponent
          }
        ],
      },
      {
        path: 'assessments/:action',
        pathMatch: 'prefix',
        children: [
          {
            path: ':assessCaseID/:formName',
            pathMatch: 'full',
            canActivate: [AuthorizationFormsGuard],
            component: AssessmentsComponent,
            loadChildren: () =>
              // eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
              import('@clients-nside-io/apps/forms/form/module').then(
                (m) => m.FormModule
              ),
            data: {
              isOpeningForm: true
            }
          },
          {
            path: 'team/:assessCaseID/:formName/:submissionId',
            pathMatch: 'full',
            component: ReviewTeamSelectingComponent,
          },
          {
            path: ':assessCaseID/:formName/:submissionId',
            pathMatch: 'full',
            canActivate: [AuthorizationFormsGuard],
            component: AssessmentsComponent,
            loadChildren: () =>
              // eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
              import('@clients-nside-io/apps/forms/form/module').then(
                (m) => m.FormModule
              ),
            data: {
              isOpeningForm: true
            }
          },
          {
            path: 'pdf/:assessCaseID/:formName/:submissionId',
            pathMatch: 'full',
            canActivate: [AuthorizationFormsGuard],
            component: PlansComponent
          }
        ],
      },
      {
        path: 'plans/:action',
        pathMatch: 'prefix',
        children: [
          {
            path: ':assessCaseID/:formName',
            pathMatch: 'full',
            canActivate: [AuthorizationFormsGuard],
            component: PlansComponent,
            loadChildren: () =>
              // eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
              import('@clients-nside-io/apps/forms/form/module').then(
                (m) => m.FormModule
              ),
            data: {
              isOpeningForm: true
            }
          },
          {
            path: ':assessCaseID/:formName/:submissionId',
            pathMatch: 'full',
            canActivate: [AuthorizationFormsGuard],
            component: PlansComponent,
            loadChildren: () =>
              // eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
              import('@clients-nside-io/apps/forms/form/module').then(
                (m) => m.FormModule
              ),
            data: {
              isOpeningForm: true
            }
          },
          {
            path: 'pdf/:assessCaseID/:formName/:submissionId',
            pathMatch: 'full',
            canActivate: [AuthorizationFormsGuard],
            component: PlansComponent
          }
        ],
      },
      {
        path: 'interventions/:action',
        pathMatch: 'prefix',
        children: [
          {
            path: ':assessCaseID/:formName',
            pathMatch: 'full',
            canActivate: [AuthorizationFormsGuard],
            component: InterventionsComponent,
            loadChildren: () =>
              // eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
              import('@clients-nside-io/apps/forms/form/module').then(
                (m) => m.FormModule
              ),
            data: {
              isOpeningForm: true
            }
          },
          {
            path: ':assessCaseID/:formName/:submissionId',
            pathMatch: 'full',
            canActivate: [AuthorizationFormsGuard],
            component: InterventionsComponent,
            loadChildren: () =>
              // eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
              import('@clients-nside-io/apps/forms/form/module').then(
                (m) => m.FormModule
              ),
            data: {
              isOpeningForm: true
            }
          },
          {
            path: 'pdf/:assessCaseID/:formName/:submissionId',
            pathMatch: 'full',
            canActivate: [AuthorizationFormsGuard],
            component: PlansComponent
          }
        ],
      },
      {
        path: 'monitoring/:action',
        pathMatch: 'prefix',
        children: [
          {
            path: ':assessCaseID/:formName',
            pathMatch: 'full',
            canActivate: [AuthorizationFormsGuard],
            component: InterventionsComponent,
            loadChildren: () =>
              // eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
              import('@clients-nside-io/apps/forms/form/module').then(
                (m) => m.FormModule
              ),
            data: {
              isOpeningForm: true
            }
          },
          {
            path: ':assessCaseID/:formName/:submissionId',
            pathMatch: 'full',
            canActivate: [AuthorizationFormsGuard],
            component: InterventionsComponent,
            loadChildren: () =>
              // eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
              import('@clients-nside-io/apps/forms/form/module').then(
                (m) => m.FormModule
              ),
            data: {
              isOpeningForm: true
            }
          },
          {
            path: 'pdf/:assessCaseID/:formName/:submissionId',
            pathMatch: 'full',
            canActivate: [AuthorizationFormsGuard],
            component: PlansComponent
          }
        ],
      },
      {
        path: 'closed/:action',
        pathMatch: 'prefix',
        children: [
          {
            path: ':assessCaseID/:formName',
            pathMatch: 'full',
            canActivate: [AuthorizationFormsGuard],
            component: InterventionsComponent,
            loadChildren: () =>
              // eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
              import('@clients-nside-io/apps/forms/form/module').then(
                (m) => m.FormModule
              ),
            data: {
              isOpeningForm: true
            }
          },
          {
            path: ':assessCaseID/:formName/:submissionId',
            pathMatch: 'full',
            canActivate: [AuthorizationFormsGuard],
            component: InterventionsComponent,
            loadChildren: () =>
              // eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
              import('@clients-nside-io/apps/forms/form/module').then(
                (m) => m.FormModule
              ),
            data: {
              isOpeningForm: true
            }
          },
          {
            path: 'pdf/:assessCaseID/:formName/:submissionId',
            pathMatch: 'full',
            canActivate: [AuthorizationFormsGuard],
            component: PlansComponent
          }
        ],
      }
    ],
  },
  {
    path: 'building/:buildingId',
    component: StageComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: HomeComponent,
        children: [
          {
            path: ':formName',
            loadChildren: () =>
              // eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
              import('@clients-nside-io/apps/forms/form/module').then(
                (m) => m.FormModule
              ),
            data: {
              isOpeningForm: true
            }
          },
        ]
      }
    ],
    canActivate: [AuthorizationGuard],
  },
  {
    path: 'stage/:buildingId',
    component: StageComponent,
    children: [
      {
        path: 'evaluations',
        component: EvaluationsComponent,
        children: [
          {
            path: 'request/:assessCaseID/review/:formName/:submissionId',
            canActivate: [AuthorizationFormsGuard],
            loadChildren: () =>
              // eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
              import('@clients-nside-io/apps/forms/form/module').then(
                (m) => m.FormModule
              ),
            pathMatch: 'full',
            data: {
              isOpeningForm: true
            }
          },
          {
            path: ':formName',
            canActivate: [AuthorizationFormsGuard],
            loadChildren: () =>
              // eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
              import('@clients-nside-io/apps/forms/form/module').then(
                (m) => m.FormModule
              ),
            pathMatch: 'full',
            data: {
              isOpeningForm: true
            }
          },
        ],
        canActivate: [AuthorizationGuard],
      },
      {
        path: 'assessments',
        component: AssessmentsComponent,
        children: [
          {
            path: 'request/:assessCaseID/review/:formName/:submissionId',
            canActivate: [AuthorizationFormsGuard],
            loadChildren: () =>
              // eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
              import('@clients-nside-io/apps/forms/form/module').then(
                (m) => m.FormModule
              ),
            pathMatch: 'full',
            data: {
              isOpeningForm: true
            }
          },
          {
            path: ':formName/:assessCaseID',
            canActivate: [AuthorizationFormsGuard],
            loadChildren: () =>
              // eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
              import('@clients-nside-io/apps/forms/form/module').then(
                (m) => m.FormModule
              ),
            pathMatch: 'full',
            data: {
              isOpeningForm: true
            }
          },

        ],
        canActivate: [AuthorizationGuard],
      },
      {
        path: 'plans',
        component: PlansComponent,
        children: [
          {
            path: ':formName/:assessCaseID',
            canActivate: [AuthorizationFormsGuard],
            loadChildren: () =>
              // eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
              import('@clients-nside-io/apps/forms/form/module').then(
                (m) => m.FormModule
              ),
            pathMatch: 'full',
            data: {
              isOpeningForm: true
            }
          },
          {
            path: 'request/:assessCaseID/review/:formName/:submissionId',
            canActivate: [AuthorizationFormsGuard],
            loadChildren: () =>
              // eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
              import('@clients-nside-io/apps/forms/form/module').then(
                (m) => m.FormModule
              ),
            pathMatch: 'full',
            data: {
              isOpeningForm: true
            }
          },
        ],
        canActivate: [AuthorizationGuard],
      },
      {
        path: 'interventions',
        component: InterventionsComponent,
        children: [
          {
            path: ':formName/:assessCaseID',
            canActivate: [AuthorizationFormsGuard],
            loadChildren: () =>
              // eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
              import('@clients-nside-io/apps/forms/form/module').then(
                (m) => m.FormModule
              ),
            pathMatch: 'full',
            data: {
              isOpeningForm: true
            }
          },
          {
            path: 'request/:assessCaseID/review/:formName/:submissionId',
            canActivate: [AuthorizationFormsGuard],
            loadChildren: () =>
              // eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
              import('@clients-nside-io/apps/forms/form/module').then(
                (m) => m.FormModule
              ),
            pathMatch: 'full',
            data: {
              isOpeningForm: true
            }
          },
        ],
        canActivate: [AuthorizationGuard],
      },
      {
        path: 'monitoring',
        component: MonitoringComponent,
        children: [
          {
            path: ':formName/:assessCaseID',
            canActivate: [AuthorizationFormsGuard],
            loadChildren: () =>
              // eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
              import('@clients-nside-io/apps/forms/form/module').then(
                (m) => m.FormModule
              ),
            pathMatch: 'full',
            data: {
              isOpeningForm: true
            }
          },
          {
            path: 'request/:assessCaseID/review/:formName/:submissionId',
            canActivate: [AuthorizationFormsGuard],
            loadChildren: () =>
              // eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
              import('@clients-nside-io/apps/forms/form/module').then(
                (m) => m.FormModule
              ),
            pathMatch: 'full',
            data: {
              isOpeningForm: true
            }
          },
        ],
        canActivate: [AuthorizationGuard],
      },
      {
        path: 'closed',
        component: ClosedComponent,
        children: [
          {
            path: ':formName/:assessCaseID',
            canActivate: [AuthorizationFormsGuard],
            loadChildren: () =>
              // eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
              import('@clients-nside-io/apps/forms/form/module').then(
                (m) => m.FormModule
              ),
            pathMatch: 'full',
            data: {
              isOpeningForm: true
            }
          },
          {
            path: 'request/:assessCaseID/review/:formName/:submissionId',
            canActivate: [AuthorizationFormsGuard],
            loadChildren: () =>
              // eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
              import('@clients-nside-io/apps/forms/form/module').then(
                (m) => m.FormModule
              ),
            pathMatch: 'full',
            data: {
              isOpeningForm: true
            }
          },
        ],
        canActivate: [AuthorizationGuard],
      },
    ],
  },
  {
    path: 'monitoring/:buildingId',
    redirectTo: 'stage/:buildingId/monitoring',
    pathMatch: 'full',
  },
  {
    path: 'evaluations/:buildingId',
    redirectTo: 'stage/:buildingId/evaluations',
    pathMatch: 'full',
  },
  {
    path: 'assessments/:buildingId',
    redirectTo: 'stage/:buildingId/assessments',
    pathMatch: 'full',
  },
  {
    path: 'plans/:buildingId',
    redirectTo: 'stage/:buildingId/plans',
    pathMatch: 'full',
  },
  {
    path: 'interventions/:buildingId',
    redirectTo: 'stage/:buildingId/interventions',
    pathMatch: 'full',
  },

  {
    path: 'closed/:buildingId',
    redirectTo: 'stage/:buildingId/closed',
    pathMatch: 'full',
  },
  {
    path: 'redirect',
    component: RedirectComponent,
  },
  {
    path: 'error',
    component: PageNotFoundComponent,
  },
  {
    path: 'unauthorized',
    component: UnauthorizedComponent,
  },
  {
    path: '**',
    component: RedirectComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      relativeLinkResolution: 'legacy',
      paramsInheritanceStrategy: 'always',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
