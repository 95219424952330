<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">
    Attachments for Case {{assessCase?.CaseId}}
  </h4>
  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="activeModal.dismiss('Cross click')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="container-fluid">
    <div class="row" *ngIf="assessCase?.Attachments?.completedForms">
      <b class="text-success">Completed Forms: </b>
      <table class="table">
        <thead>
        <tr>
          <th scope="col">Submission Info</th>
          <th scope="col">Submitted</th>
          <th scope="col">Submitted By</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let attachment of assessCase?.Attachments?.completedForms">
          <td>
            <div>{{attachment.name}}</div>
            <div>&nbsp;</div>
            <div class="float-bottom">
              <span>Form: {{attachment.formDisplayName}}</span>
              <p *ngIf="attachment.roar_admin_summary" class="card-text">
                ROAR Administrative Summary: {{ attachment.roar_admin_summary }}
              </p>
            </div>
          </td>
          <td>
            <div>{{formatDate(attachment.date)}}</div>
          </td>
          <td>
            <div>{{attachment.user}}</div>
            <div>&nbsp;</div>
            <div>
              <button class="btn btn-primary btn-table" (click)='viewDownloadClick($event, assessCase.CaseId, attachment.formName, attachment.submissionId)'>View</button>
              <button class="btn btn-primary btn-table" (click)='viewDownloadClick($event, assessCase.CaseId, attachment.formName, attachment.submissionId, true)'>Download</button>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="row" *ngIf="assessCase?.Attachments?.inProgressForms">
      <b class="text-warning">In-Progress Forms: </b>
      <table class="table">
        <thead>
        <tr>
          <th scope="col">Submission Info</th>
          <th scope="col">Last Updated</th>
          <th scope="col">Updated By</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let attachment of assessCase?.Attachments?.inProgressForms">
          <td>
            <div>{{attachment.name}}</div>
            <div>&nbsp;</div>
            <div class="float-bottom">
              <span>Form: {{attachment.formDisplayName}}</span>
              <p *ngIf="attachment.roar_admin_summary" class="card-text">
                ROAR Administrative Summary: {{ attachment.roar_admin_summary }}
              </p>
            </div>
          </td>
          <td>
            <div>{{formatDate(attachment.date)}}</div>
          </td>
          <td>
            <div>{{attachment.user}}</div>
            <div>&nbsp;</div>
            <div>
              <button class="btn btn-primary btn-table" disabled>View Summary</button>
              <button class="btn btn-primary btn-table" disabled>Resume</button>
              <button class="btn btn-cancel btn-table" disabled>Delete</button>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="row" *ngIf="assessCase?.Attachments?.fileLinks">
      <b class="text-info">File Links: </b>
      <table class="table">
        <thead>
        <tr>
          <th scope="col">File Info</th>
          <th scope="col">Attached At</th>
          <th scope="col">Attached By</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let attachment of assessCase?.Attachments?.fileLinks">
          <td>
            <div>{{attachment.name}}</div>
          </td>
          <td>
            <div>{{formatDate(attachment.date)}}</div>
          </td>
          <td>
            <div>{{attachment.user}}</div>
            <div>&nbsp;</div>
            <div>
              <button class="btn btn-primary btn-table" (click)='viewDownloadClick($event, assessCase.CaseId, attachment.formName, attachment.submissionId)'>View</button>
              <button class="btn btn-primary btn-table" (click)='viewDownloadClick($event, assessCase.CaseId, attachment.formName, attachment.submissionId, true)'>Download</button>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
