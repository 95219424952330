import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStoreService } from '../../../../../../libs/shared/services/src/lib/localStore/localStore.service';

@Component({
  selector: 'clients-nside-io-building-id-missing',
  templateUrl: './building-id-missing.component.html',
  styleUrls: ['./building-id-missing.component.scss'],
})
export class BuildingIdMissingComponent implements OnInit {
  buildingId: string = null;

  constructor(private router: Router, private localStore: LocalStoreService) {}

  ngOnInit(): void {
    //this.buildingId = localStorage.getItem('buildingId');
    this.buildingId = this.localStore.buildingId;
    this.router.navigate([`/${this.buildingId}`]);
  }
}
