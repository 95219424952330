import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IStudentOfConcernPromptResult } from '../student-concern-dialogs-helpers';

@Component({
  selector: 'nside-assess-student-concern-prompt',
  templateUrl: './student-concern-prompt.component.html',
  styleUrls: ['./student-concern-prompt.component.css']
})
export class StudentConcernPromptComponent {
  constructor(
    public dialogRef: MatDialogRef<StudentConcernPromptComponent>,
    @Inject(MAT_DIALOG_DATA) public result: IStudentOfConcernPromptResult) { }

  onNoClick(): void {
    this.result.action = "Cancel";
    this.dialogRef.close(this.result);
  }

  onYesClick(): void {
    this.result.action = "OK";
    this.dialogRef.close(this.result);
  }

  isValid(): boolean {
    return this.result.data.buildingID?.length > 0 && this.result.data.stateIdentifier != null && this.result.data.stateIdentifier.length > 0;
  }

}
