import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams, HttpResponse
} from '@angular/common/http';
import { FormlyFieldConfig } from '@ngx-formly/core';
import {
  BehaviorSubject,
  combineLatest,
  EMPTY,
  forkJoin,
  interval,
  Observable,
  of, Subscription
} from 'rxjs';
import {
  catchError,
  debounce,
  debounceTime,
  map,
  mergeMap,
  pluck,
  shareReplay,
  startWith,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { ErrorInfo } from '../models/ErrorModel';
import { AuthorizationService } from '@clients-nside-io/shared/auth';
import { ActionHandlerService, DebugUtils } from '@clients-nside-io/shared/util';
import {
  FormTypes,
  HandledApiResponseStatus, IApiResponse,
  IBoolRsp,
  IFormInfoStorage,
  IFormResponse,
  IFormSubmission,
  IFormSubmissionResponse, IStudentContactInfo
} from '@clients-nside-io/shared/models';
import { IHandledFormSubRsp, ISelectOptionInfo } from '../models/FormModel';
import { LocalStoreService } from '@clients-nside-io/shared/services';

export const URLBaseDict = {
  base: `${environment.formApiBase}/${environment.formApiVersion}/forms`,
  mississippi: `${environment.formApiBase}/${environment.formApiVersion}/forms/list`,
  skills: `${environment.formApiBase}/${environment.formApiVersion}/forms/list/skills-survey`,
};

export enum Form {
  Stepper = 'assets/incident-report.json',
  Wizard = 'assets/incident-report-wizard.json',
  Tips = 'assets/tips.json',
  Survey = 'assets/skills_survey.json',
  Security = 'assets/full-school-security.json',
  Behavioral = 'assets/FunctionalBehavioralAssessment_New.json',
  Plan = 'assets/rms-plan-new.json',
  Test = 'assets/test.json',
  Threat = 'assets/threat_assessment_summary_documentation_form.json',
  Witness = 'assets/witness_interview.json',
  Screen = 'assets/Threat_Assessment_Screen.json',
  Parent = 'assets/Parent_interview.json',
  Student = 'assets/Student_of_Concern.json',
  Teacher = 'assets/teacher_staff_interview.json',
}

const seedValue: IFormResponse = {
  data: {
    formId: null,
    schema: {
      description: '',
      fields: '[]',
      title: '',
    },
  },
  description: '',
  statusCode: null,
};

const formApiVersions = {
  'skills-survey': 'v3.0',
  default: environment.formApiVersion,
};

@Injectable({
  providedIn: 'root',
})
export class FormService {
  private isTesting = false;
  private formPathSubject: BehaviorSubject<IFormInfoStorage>;
  private formPath$: Observable<IFormInfoStorage>;
  // private currentFormNameSubject = new BehaviorSubject<string>('');
  private currentFormSubmissionSubject = new BehaviorSubject<IFormSubmission>(
    null
  );
  formURL$: Observable<string>;
  buildingId$: Observable<string>;
  loadingSubject: BehaviorSubject<boolean>;
  loading$: Observable<boolean>;
  private errorSub: BehaviorSubject<ErrorInfo>;
  error$: Observable<ErrorInfo>;
  private currentFormName$: Observable<string>;
  private currentFormSubmission$: Observable<IFormSubmission>;
  private formApiVersion$: Observable<any>;
  private paramsFormType$: Observable<{ params: HttpParams }>;
  submitFormType$: Observable<string>;
  private apiReqOpts$: Observable<{ headers: HttpHeaders; params: HttpParams }>;
  private autoSave$: Observable<any>;
  modelAutoSave$: Observable<readonly [any, number, boolean]>;
  private fullAutoSave$: Subscription;
  public mergeStudentWithExistingModel = false;

  constructor(
    private http: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    private auth: AuthorizationService,
    private debug: DebugUtils,
    private localStore: LocalStoreService,
    private actionHandlerService: ActionHandlerService
  ) {
    this.debug.trace('form.service - constructor called');
    const tmpRoute = this.route.root.snapshot;
    this.debug.info('form.service - constructor - getting savedAssessCaseId from:', tmpRoute);
    const tmpAC = tmpRoute.paramMap.get('assessCaseID')
      ?? tmpRoute.paramMap.get('assessCaseUID')
      ?? tmpRoute.queryParamMap.get('assessCaseID')
      ?? tmpRoute.queryParamMap.get('assessCaseUID');
    if (tmpAC) {
      this.debug.info('form.service - constructor - setting savedAssessCaseId:', tmpAC);
      this.savedAssessCaseIdSubject.next(tmpAC);
    }

    // this.formPathSubject = new BehaviorSubject<IFormInfoStorage>(
    //   JSON.parse(localStorage.getItem('formPath')) || {
    //     formName: '',
    //     buildingId: '',
    //     assessCaseUID: this.savedAssessCaseIdSubject?.value
    //   }
    // );
    this.formPathSubject = new BehaviorSubject<IFormInfoStorage>(
      this.localStore.getCurrentFormInfo() || {
        formName: '',
        buildingId: '',
        assessCaseUID: this.savedAssessCaseIdSubject?.value
      }
    );
    this.formPath$ = this.formPathSubject.asObservable();
    this.formURL$ = this.formPathSubject.pipe(
      map(({ buildingId, formName }) =>
        !buildingId ? `/form/${formName}` : `/building/${buildingId}/${formName}`
      )
    );

    this.buildingId$ = this.formPathSubject.asObservable().pipe(pluck('buildingId'));
    this.loadingSubject = new BehaviorSubject<boolean>(true);
    this.loading$ = this.loadingSubject.asObservable();
    this.errorSub = new BehaviorSubject<ErrorInfo>(null);
    this.error$ = this.errorSub.asObservable();
    // this.currentFormName$ = this.currentFormNameSubject.asObservable();
    this.currentFormName$ = this.formPathSubject.asObservable().pipe(pluck('formName'));

    this.currentFormSubmission$ = this.currentFormSubmissionSubject.asObservable();
    this.formApiVersion$ = this.currentFormName$.pipe(
      map((formName: any) => {
        return formName in formApiVersions
          ? formApiVersions[formName]
          : formApiVersions.default;
      })
    );

    this.paramsFormType$ = this.currentFormName$.pipe(
      map((formName: any) => {
        this.debug.log('FormService.paramsFormType$ - formName:', formName);
        const params = new HttpParams().set(
          'formType',
          formName in FormTypes ? FormTypes[formName] : FormTypes.default
        );
        return { params };
      })
    );

    this.submitFormType$ = this.currentFormName$.pipe(
      map<string, string>((formName: string) => {
        return formName in FormTypes ? FormTypes[formName] : FormTypes.default;
      })
    );

    this.apiReqOpts$ = this.submitFormType$.pipe(
      map((formType) =>
        (this.debug.isDebugging || formType == 'Forms_Anonymous_General'
          ? of(false)
          : this.auth.loggedIn$
        )
      )
    ).pipe(
      withLatestFrom(this.submitFormType$),
      map(([loggedIn, formType]) => {
        const params = new HttpParams().set(
          'formType',
          formType?.length > 0
            ? formType
            : loggedIn
              ? 'Forms_Authenticated_General'
              : 'Forms_Anonymous_General'
        );
        const headers = loggedIn
          ? this.getAuthorizationHeader()
          : new HttpHeaders();
        headers.set('Content-Type', 'application/json');
        // this.debug.log('FormService.apiReqOpts$ - headers:', headers, 'params:', params);
        return { headers, params };
      })
    );

    this.autoSave$ = this.currentModelSubject
      .asObservable()
      .pipe(debounce(() => interval(2500)));

    this.modelAutoSave$ = combineLatest([
      this.autoSave$,
      this.greatestStep$,
      this.loading$
    ]).pipe(
      tap(([model, step, isLoading]) => {
        this.debug.log('modelSave called with this model', model, 'for step', step, 'isLoading', isLoading);
        if (!isLoading && !!model) {
          this.localStore.currentFormData = { model, step };
        }
      })
    );

    // AUTO SAVE FEATURE WHEN SETTING INFO TO THE FORM ***********************************
    this.fullAutoSave$ = combineLatest(
      this.modelAutoSave$,
      this.formPath$,
      this.apiReqOpts$,
      this.formApiVersion$,
      this.auth.email$,
      this.currentFormSubmission$,
      this.paramsFormType$,
      this.savedAssessCaseId$
    ).pipe(
      map(([
             autosave,
             formPath,
             apiOpts,
             formApiVersion,
             userEmail,
             formSubmission,
             paramsFormType,
             assessCaseId
           ]) => ([
        autosave[0],
        autosave[1],
        autosave[2],
        formPath,
        apiOpts,
        formApiVersion,
        userEmail,
        formSubmission,
        paramsFormType,
        assessCaseId
      ])),
      debounceTime(5000)
    ).subscribe(
      ([
         model,
         step,
         isLoading,
         formPath,
         apiOpts,
         formApiVersion,
         userEmail,
         formSubmission,
         paramsFormType,
         assessCaseId
       ]) => {
        this.debug.log('fullAutoSave called with this model', model);
        if (!isLoading && !this.isTesting && !!model && !!userEmail && !!formSubmission) {
          this.debug.log('posting');
          const reqOpts = { ...apiOpts, ...paramsFormType };
          reqOpts.headers = reqOpts.headers || new HttpHeaders();
          reqOpts.params = reqOpts.params || new HttpParams();
          reqOpts.params = reqOpts.params.set('email', userEmail);
          this.debug.log("this is req opt", reqOpts);

          formSubmission.data = model;
          formSubmission.currentStep = step;
          formSubmission.subjects = [{ subjectId: 'assesscaseid=' + assessCaseId }];

          const reqUrl = `${environment.formApiBase}/${formApiVersion}/forms/progress/${formPath.buildingId}`;
          this.debug.log('posting with:', reqUrl, formSubmission, reqOpts);
          const rsp = this.http.post<IFormSubmissionResponse>(
            reqUrl,
            formSubmission,
            reqOpts
          );
          rsp.subscribe((val) => {
            this.debug.log("THIS IS A RETURN DATA!", val);

          });
        } else {
          this.debug.log('fullAutoSave skipped.')
        }
      }
    );

    this.actionHandlerService.formSubmissionSuccessCompleted$.subscribe(completed => {
      this.debug.trace('FormService - actionHandlerService.formSubmissionSuccessCompleted$ - completed:', completed);
      if (completed) {
        this.clearStoredForm();
        this.actionHandlerService.formSubmissionSuccessCompletedSub.next(false);
        this.actionHandlerService.formSubmissionSuccessSub.next(null);
      }
    });
  }

  private currentModelSubject = new BehaviorSubject<any>(null);
  private currentStudentOfConcernSubject = new BehaviorSubject<IStudentContactInfo>(null);
  private greatestStepSubject = new BehaviorSubject<number>(0);
  greatestStep$ = this.greatestStepSubject.asObservable();

  private savedFormTitleSubject = new BehaviorSubject<string>('');
  private savedAssessCaseIdSubject = new BehaviorSubject<string>(null);
  private savedAssessCaseId$ = this.savedAssessCaseIdSubject.asObservable();

  get savedFormTitle() {
    return this.savedFormTitleSubject.value;
  }

  saveFormStep(step: number): void {
    if (step > this.greatestStepSubject.value) {
      this.greatestStepSubject.next(step);
    }
  }

  getFormData(
    formName: string,
    assessCaseID?: string,
    submissionId?: string,
    roarAssignmentSubmissionId?: string
  ): Observable<IHandledFormSubRsp> {
    this.debug.trace('FormService - getFormData - formName:', formName,
      'assessCaseID', assessCaseID, 'submissionId', submissionId,
      'roarAssignmentSubmissionId', roarAssignmentSubmissionId);
    return this.apiReqOpts$.pipe(
      withLatestFrom(
        this.formApiVersion$,
        this.paramsFormType$,
        this.buildingId$,
        this.auth.email$.pipe(startWith('')),
        this.formPath$
      ),
      mergeMap(
        ([
          apiOpts,
          formApiVersion,
          paramsFormType,
          buildingId,
          userEmail,
          formPath,
        ]) => {
          // if (!assessCaseID) {
          //   assessCaseID = localStorage.getItem('selectedAssessCaseId');
          // }
          this.savedAssessCaseIdSubject.next(assessCaseID);
          const reqOpts = { ...apiOpts, ...paramsFormType };
          reqOpts.headers = reqOpts.headers || new HttpHeaders();
          reqOpts.params = reqOpts.params || new HttpParams();
          const theFormType = reqOpts.params['formType']?.toString()
            ? reqOpts.params['formType']?.toString()
            : FormTypes[formName] ?? FormTypes.default;
          const useBuildingId = theFormType != FormTypes.anonymous
            && buildingId?.length > 0 && buildingId != '0';
          this.debug.log(
            'FormService.getFormData - assessCaseId:',
            assessCaseID,
            'buildingId:',
            buildingId,
            'formName:',
            formName,
            'formType:',
            reqOpts.params['formType']?.toString(),
            'roarAssignmentSubmissionId:',
            roarAssignmentSubmissionId,
            'useBuildingId:',
            useBuildingId.toString(),
          );
          const theUrl =
            useBuildingId
              ? assessCaseID?.length > 0
                ? submissionId?.length > 0
                  ? `${environment.formApiBase}/${formApiVersion}/forms/${buildingId}/assess/${assessCaseID}/review/${formName}/${submissionId}`
                  : `${environment.formApiBase}/${formApiVersion}/forms/${buildingId}/${formName}`
                : `${environment.formApiBase}/${formApiVersion}/forms/${buildingId}/${formName}`
              : `${environment.formApiBase}/${formApiVersion}/forms/${formName}`;
          this.debug.log('FormService.getFormData - theUrl:', theUrl);
          if (userEmail) {
            reqOpts.params = reqOpts.params.set('userEmail', userEmail);
          }
          //const storedCaseId = localStorage.getItem('selectedAssessCaseId');
          if (assessCaseID) {
            reqOpts.params = reqOpts.params.set('assessCaseId', assessCaseID);
            reqOpts.params = reqOpts.params.set('caseId', assessCaseID);
            //localStorage.removeItem('selectedAssessCaseId');
            //const storedUserId = localStorage.getItem('currentAssessUserId');
            const storedUserId = this.localStore.userId;
            // if (submissionId) {
            //   reqOpts.params = reqOpts.params.set('submissionId', submissionId);
            // }
            if (storedUserId) {
              reqOpts.params = reqOpts.params.set('userId', storedUserId);
            }
          }
          if (formPath?.subjects) {
            formPath.subjects.forEach((sbj) => {
              reqOpts.params = reqOpts.params.append('subject', sbj);
            });
          }
          if (roarAssignmentSubmissionId) {
            reqOpts.params = reqOpts.params.set('roarAssignmentSubmissionId', roarAssignmentSubmissionId);
          }

          return this.http.get<IFormSubmissionResponse>(theUrl, reqOpts).pipe(
            map((res) => {
              if (!res.data) {
                throw new HttpErrorResponse({
                  status: res.statusCode as number,
                  error: res.error,
                });
              }
              this.isTesting = false;
              const rv = {
                apiResponse: res,
                status: HandledApiResponseStatus.Success,
                errorMessage: '',
              } as IHandledFormSubRsp;
              if (res?.data?.schema?.fields) {
                try {
                  const tmp: FormlyFieldConfig[] =
                    typeof res.data.schema.fields === 'string'
                      ? JSON.parse(res.data.schema.fields)
                      : res.data.schema.fields;
                  if (tmp) {
                    const tmpArr: FormlyFieldConfig[] = [];
                    tmp.forEach((f) => {
                      tmpArr.push(this.transformFieldConfig(f));
                    });
                    rv.apiResponse.data.schema.fields = JSON.stringify(tmpArr);
                  }
                } catch (err) {
                  console.error(err);
                }

                const thisData = rv.apiResponse?.data;
                if (this.mergeStudentWithExistingModel) {
                  this.mergeStudentWithExistingModel = false;
                  const thisModel = thisData.data ?? {};
                  thisModel.screen_student_info = { ...thisModel.screen_student_info, ...this.currentStudentOfConcernSubject.value };
                  thisModel.student_information = { ...thisModel.screen_student_info, ...thisModel.student_information };
                  thisData.data = { ...thisModel };
                }
                this.processInitialSubmission(thisData);
              }
              return rv;
            }),
            // We could have seed data that we could also use as a fallback
            startWith({
              apiResponse: seedValue,
              status: HandledApiResponseStatus.Loading,
              errorMessage: '',
            } as IHandledFormSubRsp),

            catchError((err: HttpErrorResponse) => {
              // We are assuming server errors are there any other kind?
              const { errorTitle, errorMsg } = this.buildError(err);
              // console.log('You made an error', err);
              this.handleError(errorTitle, errorMsg);
              return EMPTY;
            }),
            shareReplay(1)
          );
        }
      )
    );
  }

  getTestData(filename: string): Observable<any> {
    const empty: any = {};
    const rv: Observable<any> = this.http
      .get<any>(filename)
      .pipe(catchError((error) => of(empty)));

    return rv;
  }

  getTestFields(form: Form | string): Observable<IHandledFormSubRsp> {
    this.isTesting = true;
    const formFilename = form.endsWith('.json')
      ? form.substring(0, form.length - 5)
      : form;
    return forkJoin([
      this.http.get<IHandledFormSubRsp>('assets/api-response.json'),
      this.http.get<FormlyFieldConfig>(formFilename + '.json'),
      this.getTestData(formFilename + '.data.json'),
    ]).pipe(
      tap(([res, formFields, formTestData]) => {
        this.debug.log(
          'FormService.getTestFields (' + form + ') - ApiResponse:',
          res
        );
        this.debug.log(
          'FormService.getTestFields (' +
          formFilename +
          '.json) - FormlyFieldConfig:',
          formFields
        );
        this.debug.log(
          'FormService.getTestFields (' +
          formFilename +
          '.data.json) - FormTestData:',
          formTestData
        );
      }),
      map(([res, formFields, formTestData]) => {
        let fieldConfig: FormlyFieldConfig[] = null;
        const fields = formFields as FormlyFieldConfig[];
        if (fields) {
          fieldConfig = [];
          fields.forEach((fld) => {
            fieldConfig.push(this.transformFieldConfig(fld));
          });
        }
        res.apiResponse.data ??= {
          formId: res.apiResponse['form-identifier'],
          schema: res.apiResponse['form-schema'],
          submissionId: '00000000-0000-0000-0000-000000000000',
          created: new Date().toString(),
          currentStep: 0,
          data: {},
        };
        res.apiResponse.data.data ??= {};
        if (formTestData) {
          res.apiResponse.data.data = {
            ...res.apiResponse.data.data,
            ...formTestData,
          };
        }
        const fieldStr = fieldConfig
          ? JSON.stringify(fieldConfig)
          : JSON.stringify(formFields);
        res.apiResponse.data.schema.fields = fieldStr;
        this.processInitialSubmission(res.apiResponse.data);
        return {
          apiResponse: res.apiResponse,
          status: HandledApiResponseStatus.Success,
          errorMessage: '',
        } as IHandledFormSubRsp;
      })
    );
  }

  resetCurrentSubmission() {
    this.currentFormSubmissionSubject.next(null);
    this.greatestStepSubject.next(-1);
  }

  processInitialSubmission(data: IFormSubmission) {
    this.debug.log('form.service - processInitialSubmission called', data);
    if (!data) {
      return;
    }
    this.currentModelSubject.next(data?.data);
    this.currentFormSubmissionSubject.next(data);
    let tmpStep = parseInt((data?.currentStep as string) ?? '0', 10);
    if (isNaN(tmpStep)) {
      tmpStep = 0;
    }
    this.saveFormStep(tmpStep);
  }

  postFormSubmit(
    form: IFormSubmission,
    id: string,
    email: string,
    assessCaseID?: string
  ): Observable<IApiResponse<any>> {
    let params = new HttpParams().set('email', email);
    return this.apiReqOpts$.pipe(
      withLatestFrom(
        this.currentFormName$,
        this.formApiVersion$,
        this.currentFormSubmission$,
        this.submitFormType$
      ),
      mergeMap(
        ([
          { headers },
          currentFormName,
           formApiVersion,
          savedSubmission,
          formType,
        ]) => {
          let UrlAddition =
            currentFormName === 'skills-survey' ? '/skills-survey' : '';
          if (id?.toString().length > 0)
            UrlAddition += `/${id}`;
          const SubmitUrl =
            formType === 'Forms_Authenticated_Assess'
              ? `${environment.formApiBase}/${formApiVersion}/forms/assess/submit${UrlAddition}`
              : `${environment.formApiBase}/${formApiVersion}/forms/submit${UrlAddition}`;
          if (savedSubmission?.submissionId) {
            form.submissionId = savedSubmission.submissionId;
          }
          if (assessCaseID) {
            params = params.set('caseId', assessCaseID);
          }
          // if (this.debug.isDebugging) {
          //   this.debug.log(
          //     'FormService.postFormSubmit - Skipping API call - savedSubmission:',
          //     savedSubmission
          //   );
          //   const fakeRsp: IBoolRsp = {
          //     statusCode: 200,
          //     data: true,
          //     description: 'skipped calling the API',
          //   };
          //   return of(fakeRsp);
          // }
          return this.http.post<IBoolRsp>(SubmitUrl, form, { headers, params });
        }
      )
    );
  }

  setFormPath(path: IFormInfoStorage, resetForm: boolean = false): void {
    this.formPathSubject.next(path);
    // localStorage.setItem('formPath', JSON.stringify(path));
    this.localStore.setFromFormInfoStorage(path, resetForm);
  }

  // setCurrentForm(form: string): void {
  //   this.currentFormNameSubject.next(form);
  //   const curr = this.formPathSubject.value ?? {
  //     formName: '',
  //     buildingId: '',
  //   };
  //
  //   if (curr.formName === '' && this.savedAssessCaseIdSubject.value?.length > 0)
  //     curr.assessCaseUID = this.savedAssessCaseIdSubject.value;
  //
  //   if (form != curr.formName) {
  //     this.setFormPath({
  //       formName: form,
  //       buildingId: 'test',
  //     });
  //   }
  // }

  setError(title: string, message: string): void {
    this.errorSub.next({
      title,
      message,
      status: true,
    });
  }

  resetError(): void {
    this.errorSub.next(null);
  }

  handleError(title: string, message: string): void {
    const error: ErrorInfo = {
      title,
      message,
      status: true,
    };
    this.errorSub.next(error);
    // console.log('Should be redirected');
    // this.router.navigate([errorEndpoint], { state: error });
  }

  // Auto Save Functionality
  // ****************************
  public setCurrentModel(model: any): void {
    this.currentModelSubject.next(model);
  }

  public setCurrentStudentOfConcern(studentInfo: IStudentContactInfo): void {
    this.currentStudentOfConcernSubject.next(studentInfo);
  }

  getStoredModel(): any {
    // const formName = this.savedFormTitleSubject.value;
    // return JSON.parse(localStorage.getItem(formName))?.model;
    return this.localStore.currentFormData?.model;
  }

  updateForm(formTitle): void {
    this.savedFormTitleSubject.next(formTitle);
  }

  // postFormProgress$ = this.autoSave$
  //   .pipe(debounce(() => interval(10000)))
  //   .pipe(
  //     withLatestFrom(this.apiReqOpts$, this.formVersion$, this.formPath$, this.auth.email$,
  //       this.greatestStep$, this.currentFormSubmission$),
  //     mergeMap(([latestModel, { headers }, formVersion, formPath, userEmail,
  //                 latestStep, formSubmission]) => {
  //       console.log('posting');
  //       const params = new HttpParams();
  //       if (!!userEmail) {
  //         params.set('email', userEmail);
  //       }
  //       if (!!formSubmission) {
  //         formSubmission.data = latestModel;
  //         formSubmission.currentStep = latestStep;
  //       }
  //       return this.http.post<FormResponse>(
  //         `${environment.formApiBase}/${formVersion}/forms/progress/${formPath.buildingId}`,
  //         formSubmission,
  //         { headers, params }
  //       );
  //     })
  //   ).subscribe(result => {
  //     const stored = {
  //       submissionId: result.data.submissionId,
  //       formName: result.data.formId.name,
  //       step: result.data.currentStep
  //     };
  //     localStorage.setItem(stored.formName + '_stored', JSON.stringify(stored));
  //   });

  clearStoredForm(): void {
    this.debug.log('FormService.clearStoredForm - clearing stored form. Current formPath:', this.formPathSubject.value);
    // const storedFormName = this.savedFormTitleSubject.value;
    // localStorage.removeItem(storedFormName);
    this.savedFormTitleSubject.next(null);
    // this.setCurrentForm(null);
    this.setCurrentModel(null);
    this.savedAssessCaseIdSubject.next(null);
    this.greatestStepSubject.next(-1);
    this.localStore.resetForm('');
    this.formPathSubject.next(this.localStore.getCurrentFormInfo());
    this.currentFormSubmissionSubject.next(null);
    this.debug.log('FormService.clearStoredForm - cleared stored form. Current formPath:', this.formPathSubject.value);
  }
  // ****************************

  private getAuthorizationHeader(): HttpHeaders {
    return new HttpHeaders().set(
      'Authorization',
      `Bearer ${this.auth.getToken()}`
    );
  }

  private buildError(err: HttpErrorResponse): {
    errorTitle: string;
    errorMsg: string;
  } {
    let errorTitle = 'Server Error';
    let errorMsg = ' There was an issue retrieving the requested form data.';

    switch (err.status) {
      case 404:
        errorTitle = 'Not Found';
        errorMsg = err.error.log;
        break;
      default:
        break;
    }

    return { errorTitle, errorMsg };
  }

  transformFieldConfig(field: FormlyFieldConfig): FormlyFieldConfig {
    let rv: FormlyFieldConfig = {};
    if (!field) {
      return rv;
    }

    rv = field;

    // rv = {
    //   key: field.key,
    //   type: field.type,
    // };
    //
    // if (!!field.asyncValidators) { rv.asyncValidators = field.asyncValidators; }
    // if (!!field.className) { rv.className = field.className; }
    // if (!!field.expressionProperties) { rv.expressionProperties = field.expressionProperties; }
    // if (!!field.fieldGroupClassName) { rv.fieldGroupClassName = field.fieldGroupClassName; }
    // if (!!field.focus) { rv.focus = field.focus; }
    // if (!!field.hide) { rv.hide = field.hide; }
    // if (!!field.hideExpression) { rv.hideExpression = field.hideExpression; }
    // if (!!field.id) { rv.id = field.id; }
    // if (!!field.modelOptions) { rv.modelOptions = field.modelOptions; }
    // if (!!field.name) { rv.name = field.name; }
    // if (!!field.optionsTypes) { rv.optionsTypes = field.optionsTypes; }
    // if (!!field.template) { rv.template = field.template; }
    // if (!!field.templateOptions) { rv.templateOptions = field.templateOptions; }
    // if (!!field.validation) { rv.validation = field.validation; }
    // if (!!field.validators) { rv.validators = field.validators; }
    // if (!!field.wrappers) { rv.wrappers = field.wrappers; }
    // if (!!field.parsers) { rv.parsers = field.parsers; }

    // eslint-disable-next-line no-prototype-builtins
    if (!!field.defaultValue && field.defaultValue.hasOwnProperty('value')) {
      rv.defaultValue = field.defaultValue.value;
    }

    if (field.fieldArray) {
      rv.fieldArray = this.transformFieldConfig(field.fieldArray);
    }

    if (field.fieldGroup) {
      const tmp: FormlyFieldConfig[] = [];
      field.fieldGroup.forEach((grp) => {
        tmp.push(this.transformFieldConfig(grp));
      });
      rv.fieldGroup = tmp;
    }

    return rv;
  }

  getFileNameFromResponse(res: HttpResponse<any>): string {
    const disposition = res.headers.get('Content-Disposition');
    if (!disposition) {
      // either the disposition was not sent, or is not accessible
      //  (see CORS Access-Control-Expose-Headers)
      return null;
    }
    const utf8FilenameRegex = /filename\*=UTF-8''([\w%\-.]+)(?:; |$)/;
    const asciiFilenameRegex = /filename=(["'])(.*?[^\\])\1(?:; |$)/;

    let fileName: string = null;
    if (utf8FilenameRegex.test(disposition)) {
      fileName = decodeURIComponent(utf8FilenameRegex.exec(disposition)[1]);
    } else {
      const matches = asciiFilenameRegex.exec(disposition);
      if (matches != null && matches[2]) {
        fileName = matches[2];
      }
    }
    return fileName;
  }

  getAssessFormPdf(assessCaseId?: string, formName?: string, submissionId?: string, onlyLatest?: boolean): Observable<HttpResponse<Blob>> {
    if (!assessCaseId || !formName || !submissionId) return;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/pdf',
        Authorization : `Bearer: ${this.auth.getToken()}`,
        responseType : 'blob' as 'json',
        Accept : 'application/pdf',
        observe : 'response'
      }),
      responseType: 'blob' as 'json'
    };
    let theUrl = URLBaseDict.base
      + `/pdf/${this.auth.buildingIdSubject.value}/${formName}`
      + `?formType=Forms_Authenticated_Assess&reportType=qa&onlyLatest=${onlyLatest == true}`
      + '&excludeChildren=false&allowCreate=true&forceCreate=false';
    if (submissionId?.length > 0) {
      theUrl = theUrl + `&submissionId=${submissionId}`
    }
    if (assessCaseId?.length > 0) {
      theUrl = theUrl + `&assessCaseId=${assessCaseId}`
    }
    return this.http.get<Blob>(theUrl, {...httpOptions, observe: 'response'});
  }

  getSelectOptions(dataName: string, valueFilter?: string): Observable<ISelectOptionInfo[]> {
    this.debug.log('form.service - getSelectOptions called with params:', dataName, valueFilter);
    let { assessCaseId, formName, buildingId } = this.formPathSubject.value;
    // const formApiVersion = formName in formApiVersions
    //   ? formApiVersions[formName]
    //   : formApiVersions.default;
    const formApiVersion = formApiVersions.default;

    formName = formName?.length > 0
      ? formName
      : this.localStore.currentFormName;

    assessCaseId = assessCaseId?.length > 0
      ? assessCaseId
      : this.savedAssessCaseIdSubject.value;

    buildingId = buildingId?.length > 0 && parseInt(buildingId) > 0
      ? buildingId
      : this.localStore.buildingId;

    const formType: string = formName in FormTypes
      ? FormTypes[formName]
      : FormTypes.default;

    const needsAuth = !formType.startsWith('Forms_Anonymous');

    const thisFormType = formName in FormTypes
      ? FormTypes[formName]
      : FormTypes.default;

    this.debug.log('form.service - getSelectOptions using variables (dataName, buildingId, assessCaseID, formName, formApiVersion):',
      dataName, buildingId, assessCaseId, formName, formApiVersion);

    if (needsAuth && (!dataName || !assessCaseId || !formName || !buildingId))
      return;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // Authorization: needsAuth
        //   ? `Bearer: ${this.auth.getToken()}`
        //   : undefined,
        responseType: 'blob' as 'json',
        Accept: 'application/json'
      }),
      params: new HttpParams()
        .set('formName', formName)
        .set('formType', thisFormType)
        .set('assessCaseId', assessCaseId)
    };

    if (needsAuth) {
      httpOptions.headers = httpOptions.headers.set('Authorization', `Bearer: ${this.auth.getToken()}`);
    }

    if (buildingId?.length > 0) {
      httpOptions.params = httpOptions.params.set('buildingId', buildingId);
    }

    if (assessCaseId?.length > 0) {
      httpOptions.params = httpOptions.params.set('assessCaseId', assessCaseId);
    }

    if (valueFilter?.length > 0) {
      httpOptions.params = httpOptions.params
        .set('valueFilter', valueFilter);
    }
    const theUrl = `${environment.formApiBase}/${formApiVersion}/forms/data/select/${dataName}`;

    const result = this.http.get<IApiResponse<ISelectOptionInfo[]>>(theUrl, httpOptions);

    return result
      .pipe(map(response => {
        this.debug.log('form.service - getSelectOptions response', response);
        return response?.data as ISelectOptionInfo[];
      }));
  }
}
