import { Component, OnInit } from '@angular/core';
import { AssessService } from '../../../services/assess.service';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DebugUtils } from '@clients-nside-io/shared/util';

@Component({
  selector: 'nside-assess-review-team-selecting',
  templateUrl: './review-team-selecting.component.html',
  styleUrls: ['./review-team-selecting.component.css']
})
export class ReviewTeamSelectingComponent implements OnInit {
  buildingId;
  caseId = "";
  caseUID = -1;
  stage;
  userId;
  reviewers: any[];
  selectedReviewers: any[];
  currentCase: any = null;
  assessCases: any = null;

  // conditions
  isDoneWithAssigning = false;
  isNotAvailable = false;
  isSelecting = true;

  constructor(private assessService: AssessService, private route: ActivatedRoute, private toastr: ToastrService, private debug: DebugUtils) { }

  ngOnInit(): void {

    this.buildingId = this.route.snapshot.paramMap.get('buildingId');
    this.caseId = this.route.snapshot.paramMap.get('assessCaseID');

    console.log(this.caseId);
    this.stage = this.route.snapshot.paramMap.get('stage');
    this.userId = this.route.snapshot.paramMap.get('userId');

    // this.assessService.getAssessInfoByBuildingID(this.buildingId)
    //   .subscribe((response:any) => {
    //     // find the target case
    //     this.assessCases = response.data;
    //
    //     for (let i = 0; i < this.assessCases.length; i++) {
    //       if (this.assessCases[i].CaseId === this.caseId) {
    //         this.currentCase = this.assessCases[i];
    //         break;
    //       }
    //     }
    //
    //     this.debug.log("The current case is", this.currentCase);
    //
    //     // check if the case is assigned or not
    //     if (this.currentCase.AssignedTeam) {
    //       this.toastr.error("This case is assigned to a team!");
    //       this.isDoneWithAssigning = true;
    //       this.isSelecting = false;
    //     }
    //
    //     // check if the case is in Assessment stage or not
    //     if (this.currentCase.CurrentStage !== 'Assessments') {
    //       this.toastr.error("This case is not available for Assessment!");
    //       this.isNotAvailable = true;
    //       this.isSelecting = false;
    //     }
    //
    //     // getting the case uid
    //     this.caseUID = this.currentCase.UID;
    //
    //
    //
    //     // set up reviewer list
    //     const users = response.assessUsers;
    //     this.reviewers = [];
    //
    //     for (let i = 0; i < users.length; i++) {
    //       this.reviewers.push(
    //         {
    //           ...users[i],
    //           isChecked: false
    //         }
    //       );
    //     }
    //   });
  }

  submitReviewersTeam() {
    this.selectedReviewers = this.reviewers.filter((reviewer) => reviewer.isChecked == true);

    // getting userIds
    const userIds = [];
    for (let i = 0; i < this.selectedReviewers.length; i++) {
      userIds.push(this.selectedReviewers[i].userID);
    }

    this.debug.log(userIds);

    this.assessService.updateThreatAssessmentTeam(this.buildingId, this.caseUID, userIds)
    .subscribe(
      (result: any) => {
        if (result.statusCode == 200) {
          this.toastr.success(
            'The assessment team has been submitted!',
            'Message:'
          );
        } else {
          this.toastr.error(result.description, 'Error:');
        }
        this.debug.log(result);
      },
      (error) => {
        this.toastr.error(error.description, 'Error:');
        this.debug.log(error);
      }
    );;
  }

}
