<div class="container-scroller">
  <div class="container-fluid">
    <div class="row row-offcanvas row-offcanvas-right">
      <div class="content-wrapper">
        <mat-drawer-container class="example-container" autosize>
          <mat-drawer #drawer opened class="example-sidenav" mode="side">
            <ul class="nav-content">
              <li class="nav-item" id="dashboard-sidenav">
                <a class="nav-link" [href]="'/building/' + buildingId">
                  <div>
                    <i class="fa fa-home mr-3" aria-hidden="true"></i>
                    <span class="menu-title"><b>Dashboard</b></span>
                  </div>
                </a>
              </li>
              <li class="nav-item" id="evaluations-sidenav">
                <a
                  class="nav-link"
                  [routerLink]="'/stage/' + buildingId + '/evaluations'"
                >
                  <i class="fa fa-question-circle mr-3" aria-hidden="true"></i>
                  <span class="menu-title"
                    ><b>Evaluations</b> ({{ stageJobsCount.Evaluations }})
                  </span>
                </a>
              </li>
              <li class="nav-item" id="assessments-sidenav">
                <a
                  class="nav-link"
                  [routerLink]="'/stage/' + buildingId + '/assessments'"
                >
                  <i class="fa fa-check-square mr-3" aria-hidden="true"></i>
                  <span class="menu-title"
                    ><b>Assessments</b> ({{ stageJobsCount.Assessments }})</span
                  >
                </a>
              </li>
              <li class="nav-item" id="plans-sidenav">
                <a
                  class="nav-link"
                  [routerLink]="'/stage/' + buildingId + '/plans'"
                >
                  <i class="fa fa-file-text mr-3" aria-hidden="true"></i>
                  <span class="menu-title"
                    ><b>Plans</b> ({{ stageJobsCount.Plans }})</span
                  >
                </a>
              </li>
              <li class="nav-item" id="interventions-sidenav">
                <a
                  class="nav-link"
                  [routerLink]="'/stage/' + buildingId + '/interventions'"
                >
                  <i
                    class="fa fa-exclamation-circle mr-3"
                    aria-hidden="true"
                  ></i>
                  <span class="menu-title"
                    ><b>Interventions</b> ({{
                      stageJobsCount.Interventions
                    }})</span
                  >
                </a>
              </li>
              <li class="nav-item" id="monitoring-sidenav">
                <a
                  class="nav-link"
                  [routerLink]="'/stage/' + buildingId + '/monitoring'"
                >
                  <i class="fa fa-refresh mr-3" aria-hidden="true"></i>
                  <span class="menu-title"
                    ><b>Monitoring</b> ({{ stageJobsCount.Monitoring }})</span
                  >
                </a>
              </li>
              <li class="nav-item" id="closed-sidenav">
                <a class="nav-link" [routerLink]="'/stage/' + buildingId + '/closed'">
                  <i class="fa fa-stop mr-3" aria-hidden="true"></i>
                  <span class="menu-title"
                    ><b>Closed</b> ({{ stageJobsCount.Closed }})</span
                  >
                </a>
              </li>
              <li
                class="nav-item"
                id="current-case-sidenav"
                *ngIf="currentCase?.CaseId?.length > 0"
              >
                <div
                  class="nav-link border rounded m-2"
                  style="font-size: 12px"
                >
                  <small
                    ><b>Current Case:</b><br />{{ currentCase.CaseId }}
                  </small>
                </div>
              </li>
              <li
                class="nav-item"
                id="current-student-sidenav"
                *ngIf="currentCase?.IncidentContact"
              >
                <div
                  class="nav-link border rounded m-2"
                  style="font-size: 12px"
                >
                  <small><b>Student Name:</b><br />{{ [currentCase.IncidentContact.ContactFirstName,
                    currentCase.IncidentContact.ContactLastName].join(' ') }}
                  </small>
                </div>
              </li>
            </ul>
          </mat-drawer>
          <div class="example-sidenav-content p-3">
            <button
              class="navbar-toggler navbar-toggler align-self-center mr-3"
              type="button"
              data-toggle="minimize"
              mat-button
              (click)="drawer.toggle()"
            >
              <i
                class="fa fa-align-justify"
                style="color: #f15c23"
                aria-hidden="true"
              ></i>
            </button>
            <router-outlet (activate)='onRouterOutletActivate($event)' (deactivate)='onRouterOutletDeactivate($event)'></router-outlet>
          </div>
        </mat-drawer-container>
      </div>
    </div>
  </div>
</div>
