<div>
  <div class="header-container">
    <div class="logo-container col-2">
      <a class="logo" href="/"> </a>
    </div>
    <div class="col-1"></div>
    <div class="col-9">
        <div class="d-inline-flex font-weight-bold mt-4 h4 text-secondary">
            <div class="mr-5">
              <i class="fa fa-home"></i>
            </div>
            <div class="mr-5">
              <i class="fa fa-map"></i>
              Maps
            </div>
            <div class="mr-5">
              <i class="fa fa-file-text"></i>
              Layers
            </div>
            <div class="mr-5">
              <i class="fa fa-file-text"></i>
              Documents
            </div>
            <div class="mr-5">
              <i class="fa fa-address-book"></i>
              Contacts
            </div>
            <div class="mr-5">
              <i class="fa fa-exclamation-circle"></i>
              EOP
            </div>
            <div class="mr-5">
              <i class="fa fa-exclamation-triangle"></i>
              Assess
            </div>
            <div class="mr-5">
              Select a Building
            </div>
            <div class="mr-5">
              <i class="fa fa-user"></i>
            </div>
          </div>
        </div>
    </div>
</div>
