<h1 mat-dialog-title>Please complete the Student Information form for this Behavioral Threat Assessment.</h1>
<div mat-dialog-content>
  <p class='small-gray'>* denotes required fields.</p>
  <div>
    <mat-form-field appearance='fill'>
      <mat-label>State Student Identifier (SSID):</mat-label>
      <input matInput required disabled='true' [(ngModel)]='result.data.state_identifier'>
      <mat-error>This field is required!</mat-error>
    </mat-form-field>
  </div>
  <div>
    <mat-form-field appearance='fill'>
      <mat-label>First Name:</mat-label>
      <input matInput required [(ngModel)]='result.data.first_name'>
      <mat-error>This field is required!</mat-error>
    </mat-form-field>
  </div>
  <div>
    <mat-form-field appearance='fill'>
      <mat-label>Last Name:</mat-label>
      <input matInput required [(ngModel)]='result.data.last_name'>
      <mat-error>This field is required!</mat-error>
    </mat-form-field>
  </div>
  <div>
    <mat-form-field appearance='fill'>
      <mat-label>Birthdate:</mat-label>
      <input matInput required [(ngModel)]='result.data.birth_date' type='date'>
      <mat-error>This field is required!</mat-error>
    </mat-form-field>
  </div>
  <div>
    <mat-form-field appearance='fill'>
      <mat-label>Age:</mat-label>
      <input matInput disabled='true' [(ngModel)]='result.data.age' [value]='getAge()'>
    </mat-form-field>
  </div>
  <div>
    <mat-form-field appearance='fill'>
      <mat-label>Grade:</mat-label>
      <input matInput required [(ngModel)]='result.data.grade'>
      <mat-error>This field is required!</mat-error>
    </mat-form-field>
  </div>
  <div>
    <mat-label>Identified Gender:</mat-label>
    <br />
    <mat-radio-group required aria-label='Select the Identified Gender for the Student of Concern'
                     [(ngModel)]='result.data.identified_gender'>
      <mat-label>Male</mat-label>
      <mat-radio-button value='Male' aria-label='Male'></mat-radio-button>
      <br />
      <mat-label>Female</mat-label>
      <mat-radio-button value='Female' aria-label='Female'></mat-radio-button>
      <br />
      <mat-label>Other</mat-label>
      <mat-radio-button value='Other' aria-label='Other'></mat-radio-button>
      <br />
    </mat-radio-group>
  </div>
  <div>
    <mat-form-field appearance='fill'>
      <mat-label>Physical Description:</mat-label>
      <input matInput required [(ngModel)]='result.data.physical_description'>
    </mat-form-field>
  </div>
  <div>
    <mat-label>Does the student have an IEP?:</mat-label>
    <br />
    <mat-radio-group required aria-label='Does the student have an IEP?' [(ngModel)]='result.data.iep'>
      <mat-label>Yes</mat-label>
      <mat-radio-button value='Yes' aria-label='Yes'></mat-radio-button>
      <br />
      <mat-label>No</mat-label>
      <mat-radio-button value='No' aria-label='No'></mat-radio-button>
      <br />
    </mat-radio-group>
  </div>
  <div *ngIf="result.data.iep === 'Yes'">
    <mat-label>IEP Contacts (email address):</mat-label>
    <br />
    <mat-form-field>
      <mat-chip-list #IEPContactsList aria-label='IEP Contacts (email address)'>
        <mat-chip *ngFor='let contact of result.data.iep_contacts'
                  [removable]='true'
                  (removed)='removeIEPContact(contact)'>
          {{ contact }}
          <button matChipRemove>
            <mat-icon matChipRemove>cancel</mat-icon>
          </button>
        </mat-chip>
        <input matInput placeholder='Add contact email...'
               type='email'
               [required]="result.data.iep === 'Yes'"
               [matChipInputFor]='IEPContactsList'
               [matChipInputSeparatorKeyCodes]='separatorKeysCodes'
               [matChipInputAddOnBlur]='true'
               (matChipInputTokenEnd)='addIEPContact($event)'
        >
      </mat-chip-list>
    </mat-form-field>
  </div>
  <div>
    <mat-label>Does the student have an IHP?:</mat-label>
    <br />
    <mat-radio-group required aria-label='Does the student have an IHP?' [(ngModel)]='result.data.ihp'>
      <mat-label>Yes</mat-label>
      <mat-radio-button value='Yes' aria-label='Yes'></mat-radio-button>
      <br />
      <mat-label>No</mat-label>
      <mat-radio-button value='No' aria-label='No'></mat-radio-button>
    </mat-radio-group>
  </div>
  <div>
    <mat-label>Does the student have a 504?:</mat-label>
    <br />
    <mat-radio-group required aria-label='Does the student have a 504?' [(ngModel)]="result.data['504']">
      <mat-label>Yes</mat-label>
      <mat-radio-button value='Yes' aria-label='Yes'></mat-radio-button>
      <br />
      <mat-label>No</mat-label>
      <mat-radio-button value='No' aria-label='No'></mat-radio-button>
      <br />
    </mat-radio-group>
  </div>
  <div>
    <mat-form-field appearance='fill'>
      <mat-label>Link to Photo (optional):</mat-label>
      <input matInput [(ngModel)]='result.data.photo'>
    </mat-form-field>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-button color='warn' (click)='onNoClick()'>Cancel</button>
  <button mat-button color='primary' (click)='onYesClick()' [disabled]='!isValid()'>Continue</button>
</div>
