import { Component, OnDestroy, OnInit } from '@angular/core';
import { AssessService } from '../../services/assess.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { LocalStoreService } from '@clients-nside-io/shared/services';
import { getFullDataForRoute } from '@clients-nside-io/shared/util';
import { IAssessCaseInfo } from '@clients-nside-io/shared/models';

@Component({
  selector: 'clients-nside-io-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {
  isInFrame = false;
  buildingId = "";
  assessCases: IAssessCaseInfo[] = null;
  private assessCasesSub: Subscription;
  private firstDataCount = -1;

  public get hasNoCases(): boolean {
    return this.firstDataCount == 0
      && this.assessCases != null
      && this.assessCases.length == 0;
  }

  constructor(private assessService: AssessService,
              private route: ActivatedRoute,
              private router: Router,
              private localStore: LocalStoreService) { }

  ngOnInit(): void {
    //localStorage.setItem('currentUrl', this.router.url);
    //this.localStore.currentRoute = this.router.url;

    if (window.location !== window.parent.location) {
      this.isInFrame = true;
    } else { console.log("not inside");
      this.isInFrame = false;
    }

    // //get building id
    // this.buildingId = this.route.snapshot.paramMap.get('buildingId');
    // // localStorage.setItem('buildingId', this.buildingId);
    // // localStorage.setItem('currentAssessBuildingId', this.buildingId);
    // this.localStore.buildingId = this.buildingId;
    const routeData = getFullDataForRoute(this.router);
    this.localStore.setFromRouteData(routeData);
    this.buildingId = this.localStore.buildingId;

    if (!parseInt(this.buildingId)) {
      this.router.navigate(['notfound']);
    }

    //api loading
    // this.assessService.getAssessInfoByBuildingID(parseInt(this.buildingId))
    //   .subscribe({
    //     next: (data) => {
    //       this.apiResponse = data;
    //     },
    //     error: (err) => {
    //       console.log(err);
    //     }
    //   });
    this.assessCasesSub = this.assessService.activeCases$
      .subscribe(cases => {
        if (cases == null)
          return;

        if (this.firstDataCount <= 0)
          this.firstDataCount = cases.length;

        this.assessCases = cases;
      });

    this.assessService.updateBuildingId(parseInt(this.buildingId));
  }

  ngOnDestroy(): void {
    this.assessCasesSub?.unsubscribe();
  }

}
