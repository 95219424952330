<div>
  <div class="row mt-2">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-block">
          <div class="card-title mb-4">
            <h3 class="text-secondary">{{myTitle}}</h3>
          </div>

          <div *ngIf="isOpeningForm">
            <button
              class="btn text-white"
              style="background-color: #f15c23"
              (click)="closeForm()"
              data-toggle="tooltip"
              data-placement="top"
              *ngIf="isOpeningForm"
            >
              <h4>
                <i class="fa fa-times-circle" aria-hidden="true"></i> Cancel
              </h4>
            </button>
            <div class="table-responsive mt-5">
              <router-outlet></router-outlet>
            </div>
          </div>

          <div *ngIf="!isOpeningForm">
            <div *ngIf="!stageCaseData && !hasNoCases">
              <mat-spinner></mat-spinner>
            </div>
            <div *ngIf="stageCaseData || hasNoCases">
              <input
                class="w-100"
                type="text"
                style="padding: 8px; margin: 15px auto; width: 30%"
                placeholder="Search..."
                (keyup)="updateFilter($event)"
              />

              <div class="table-responsive">
                <ngx-datatable
                  class="material"
                  [rows]="stageCaseData"
                  #table
                  columnMode="force"
                  [headerHeight]="50"
                  [footerHeight]="50"
                  rowHeight="auto"
                  [limit]="5"
                  (activate)="rowClickHandler($event)"
                >
                  <ngx-datatable-column name="Case ID" prop="CaseId">
                  </ngx-datatable-column>
                  <ngx-datatable-column name="Summary" prop="IncidentSummary">
                  </ngx-datatable-column>
                  <ngx-datatable-column name="Current Status" prop="CurrentStatus">
                  </ngx-datatable-column>
                  <ngx-datatable-column name="Last Action">
                    <ng-template ngx-datatable-cell-template let-row="row">
                      {{ assessService.caseLastActionDisplay(row) }}
                    </ng-template>
                  </ngx-datatable-column>
                  <ngx-datatable-column name="Taken" prop="LastUpdated">
                    <ng-template ngx-datatable-cell-template let-value="value">
                      {{ formatDate(value) }}
                    </ng-template>
                  </ngx-datatable-column>
                  <ngx-datatable-column
                    name="Taken By"
                    prop="LastUpdatedByName"
                  ></ngx-datatable-column>
                  <ngx-datatable-column
                    [sortable]="false"
                    [canAutoResize]="false"
                    [draggable]="false"
                    [resizeable]="false"
                  >
                    <ng-template ngx-datatable-header-template>
                      Actions
                    </ng-template>
                    <ng-template ngx-datatable-cell-template let-row="row">
                      <button
                        [disabled]="!assessService.isAbleToCreateAny(row, currentUserId)"
                        class="btn btn-primary border"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Start Form"
                        [matMenuTriggerFor]="menuCreateForm"
                      >
                        <i
                          style="font-size: 10px"
                          class="fa fa-pencil-square-o"
                          aria-hidden="true"
                        ></i>
                      </button>
                      <mat-menu #menuCreateForm="matMenu">
                        <ng-container *ngFor='let formDisplayName of myFormDisplayNames; let formIdx = index;'>
                          <button
                            [disabled]='!assessService.isAbleToCreate(row, formIdx + 1, currentUserId)'
                            mat-menu-item
                            (click)="chooseForm(formIdx + 1, row)"
                          >
                            {{ formDisplayName }}
                          </button>
                        </ng-container>
                      </mat-menu>
                      <button
                        *ngIf='assessService.showAskReviewButton(row, currentUserId)'
                        [disabled]='!assessService.enableAskReviewButton(row, currentUserId)'
                        class="btn btn-danger border"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Request Review"
                        (click)="open(askReview, row)"
                      >
                        <i
                          style="font-size: 10px"
                          class="fa fa-check-square-o"
                        ></i>
                      </button>
                      <button
                        *ngIf='!assessService.showAskReviewButton(row, currentUserId)'
                        [disabled]='!assessService.enableStartReviewButton(row, currentUserId)'
                        class="btn btn-success border"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Start Review"
                        (click)="open(startReview, row)"
                      >
                        <i
                          style="font-size: 11px"
                          class="fa fa-check-square"
                        ></i>
                      </button>
                      <button
                        [disabled]="!isAbleToAssignAssessmentTeam(row)"
                        [class]='"btn " + (row.AssignedTeam?.Users?.length > 0 ? "btn-warning" : "btn-danger") + " border"'
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Threat Assessment Team"
                        (click)="!!row.AssignedTeam
                          ? openTeamSelectingModal(row)
                          : openTeamSelectionConsiderModal(row)"
                      >
                        <i style="font-size: 9px" class="fa fa-users"></i>
                      </button>
                      <button
                        class="btn btn-info border"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="View Attachments"
                        (click)="open(attachments, row)"
                      >
                        <i
                          style="font-size: 9px"
                          class="fa fa-list"
                          aria-hidden="true"
                        ></i>
                      </button>
                      <button
                        class="btn btn-secondary border"
                        (click)="open(history, row)"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="View History"
                      >
                        <i style="font-size: 10px" class="fa fa-history"></i>
                      </button>
                    </ng-template>
                  </ngx-datatable-column>
                </ngx-datatable>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #history let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-history-title">History</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form>
      <div class="form-group">
          <mat-accordion displayMode='flat'>
            <mat-expansion-panel *ngFor="let stage of currentCaseHistory" [expanded]='isCurrentStage(stage)'>
              <mat-expansion-panel-header>
                <mat-panel-title
                >{{ stage.Stage }}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="card mb-2" style="width: 100%" *ngFor="let action of stage.Actions">
                <div class="card-body">
                  <h5 class="card-title">{{ action.Action }}</h5>
                  <p class="card-text">Taken at: {{ action.Taken }}</p>
                  <p class="card-text">Taken by: {{ action.TakenByName }}</p>
                  <p class="card-text">Action name: {{ action.ActionName }}</p>
                  <p *ngIf="action.Data?.assignedUser" class="card-text">
                    Assigned to: {{ action.Data?.assignedUser?.displayName ?? (action.Data?.assignedUser?.name + ' ' + action.Data?.assignedUser?.email) }}
                  </p>
                  <p *ngIf="action.Data?.roar_admin_summary" class="card-text">
                    ROAR Administrative Summary: {{ action.Data?.roar_admin_summary }}
                  </p>
                </div>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="modal.close('Save click')">
      Close
    </button>
  </div>
</ng-template>

<ng-template #askReview let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-request-review-title">Request review:</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group">
      Select a user to review the current form for the case <b>{{ currentCase.CaseId }}</b>:
    </div>
    <div class="form-group">
      <select
        class="form-control"
        placeholder="Select a user..."
        [(ngModel)]="reviewerId"
      >
        <option *ngFor='let user of (selectedUserIDs.length > 0 ? selectedUsers : assessUserList)'
                [value]='user.userID'
                [label]='user.userDisplayName ?? user.email'>{{ user.userDisplayName ?? user.email }}</option>
      </select>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-success"
      (click)="requestReview(currentCase, reviewerId)"
    >
      Yes
    </button>
    <button
      type="button"
      class="btn btn-danger"
      (click)="modal.dismiss('No click')"
    >
      No
    </button>
  </div>
</ng-template>

<ng-template #startReview let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-start-review-title">Start to review:</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.close('Close click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    Do you want to review the current form for the case <b>{{ currentCase?.CaseId }}</b>?
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-success"
      (click)="startStageReview(currentCase)"
    >
      Yes
    </button>
    <button
      type="button"
      class="btn btn-danger"
      (click)="modal.close('Save click')"
    >
      No
    </button>
  </div>
</ng-template>

<ng-template #teamSelectionConsider let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-team-selection-consider-title">
      Do you want to designate the Threat Assessment Team Members for this case
      or assign that task to someone else?
    </h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="container">
      <button
        type="button"
        class="btn btn-primary"
        (click)="modalService.dismissAll(); openTeamSelectingModal(currentCase)"
        style="width: 100%"
      >
        Designate the Team Members
      </button>
      <button
        type="button"
        class="btn btn-secondary"
        style="width: 100%"
        (click)="modalService.dismissAll(); openAssignTaskModal(currentCase)"
      >
        Assign the Task
      </button>
    </div>
  </div>
  <div class="modal-footer">

  </div>
</ng-template>

<ng-template #threatAssessmentTeamSelection let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-team-selection-title">
      {{ teamSelectionTitle }}
    </h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="container">
      <ng-container *ngIf='isSelectingTeamMember'>
        <div class="form-check" *ngFor="let user of assessUserList">
          <input
            class="form-check-input"
            type="checkbox"
            [value]="user.userID"
            [id]="user.userID"
            [(ngModel)]='user.isSelected'
          />
          <label class="form-check-label">
            {{ user.userDisplayName }}
          </label>
        </div>
      </ng-container>
      <ng-container *ngIf='!isSelectingTeamMember'>
        <div class="form-group">
          <select
            class="form-control"
            placeholder="Select a user..."
            [(ngModel)]="assignedUserId"
          >
            <option *ngFor='let user of assessUserList'
                    [value]='user.userID'
                    [label]='user.userDisplayName ?? user.email'>{{ user.userDisplayName ?? user.email }}</option>
          </select>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="modal-footer">
    <button
      [disabled]="isWaitingForHttpRequest
       || (isSelectingTeamMember
         ? !(selectedUserIDs?.length > 0) || selectedTeamMatches
         : !(assignedUserId > 0))"
      type="button"
      class="btn btn-success"
      (click)="isSelectingTeamMember
        ? submitAssignedThreatAssessmentTeam(selectedUserIDs)
        : submitThreatAssessmentTeamAssignmentTask(assignedUserId)"
    >
      {{ isSelectingTeamMember ? "Assign Selected Users to Team" : "Assign Task to Selected User" }}
    </button>
    <button
      *ngIf='!(currentCase?.AssignedTeam?.Users?.length > 0)'
      [disabled]="isWaitingForHttpRequest"
      type="button"
      class="btn btn-warning"
      (click)="backToDecision()"
    >
      Back
    </button>
    <button
      [disabled]="isWaitingForHttpRequest"
      type="button"
      class="btn btn-danger"
      (click)="modal.dismiss('Cancel')"
    >
      Cancel
    </button>
  </div>
</ng-template>

<ng-template #attachments let-modal>
  <nside-assess-attachments-list [assessCase]='currentCase' [activeModal]='modal'></nside-assess-attachments-list>
</ng-template>

<ng-template #roarActivity let-modal>
  <nside-assess-attached-roars-list [assessCase]='currentCase'
                                    [currentUserId]='currentUserId'
                                    [activeModal]='modal'
                                    (logActivity)='startRoarActivityLog($event.assessCase, $event.submissionId)'
                                    (reviewActivity)='startRoarActivityReview($event.assessCase, $event.submissionId)'
  ></nside-assess-attached-roars-list>
</ng-template>
