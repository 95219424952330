<div class="contaner p-4">
  <div class="card">
    <div class="card-header">
      <h4 class="card-title">Select the Threat Assessment Team for case <b>{{caseId}}</b>: </h4>
    </div>
    <div class="card-body">
      <div class="container p-3">

        <div class="text-success" *ngIf="isDoneWithAssigning"><h3>This case is assigned to a team!</h3></div>
        <div class="text-danger" *ngIf="isNotAvailable"><h3>This case is not available for Assessment!</h3></div>
        <div *ngIf="reviewers && isSelecting">
          <div *ngFor="let reviewer of reviewers" class="custom-control custom-checkbox ml-4 mt-3">
            <input
              type="checkbox"
              class="custom-control-input"
              [value]="reviewer.userID"
              [id]="reviewer.userID"
              [(ngModel)]="reviewer.isChecked"
            />
            <label class="custom-control-label" [for]="reviewer.userID"
              ><b>{{reviewer.userDisplayName}}</b></label
            >
          </div>
        </div>
        <div *ngIf="!reviewers">
          <mat-spinner></mat-spinner>
        </div>
        
      </div>

      
    </div>

    <div class="card-footer text-right" *ngIf="isSelecting && assessCases">
        <button (click)="submitReviewersTeam()" style="background-color: #f15c23" class="btn text-white"><h5><b>Submit</b></h5></button>

    </div>
  </div>
</div>
