import { IEnvironment } from '@clients-nside-io/shared/models';
import {LogLevel} from "angular-auth-oidc-client";

export const environment: IEnvironment = {
  production: false,
  debugging: true,
  platformBaseUrl: 'https://dev.nside.io',
  platformDefaultUrl: 'https://dev.nside.io/Default.aspx',
  formApiBase: 'https://api.dev.nside.io',
  formApiVersion: 'v3.1',
  identityConfig: {
    stsServer: 'https://identity.dev.nside.io',
    redirectUrl: `${window.location.origin}/redirect`,
    postLogoutRedirectUri: 'https://dev.nside.io/Default.aspx',
    clientId: 'forms.nside.io',
    scope: 'api.dev.nside.io openid roles profile offline_access',
    responseType: 'code',
    silentRenew: true,
    silentRenewUrl: `${window.location.origin}/silent-renew.html`,
    useRefreshToken: true,
    renewTimeBeforeTokenExpiresInSeconds: 30,
    logLevel: LogLevel.Debug,
  }
};

// // For local debugging
// export const environment: IEnvironment = {
//   production: false,
//   debugging: true,
//   formApiBase: 'https://localhost:5011',
//   formApiVersion: 'v3.1',
//   platformBaseUrl: 'https://staging.nside.io',
//   platformDefaultUrl: 'https://staging.nside.io/Default.aspx',
//   defaultBuildingId: '130',
//   identityConfig: {
//     stsServer: 'https://identity.staging.nside.io',
//     redirectUrl: `${window.location.origin}`,
//     postLogoutRedirectUri: 'https://staging.nside.io/Default.aspx',
//     clientId: 'assess.staging.nside.io',
//     scope: 'api.staging.nside.io openid roles profile offline_access',
//     responseType: 'code',
//     silentRenew: true,
//     silentRenewUrl: `${window.location.origin}/silent-renew.html`,
//     useRefreshToken: true,
//     renewTimeBeforeTokenExpiresInSeconds: 30,
//     logLevel: LogLevel.Warn,
//   },
// };

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

