import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AssessService } from '../../../services/assess.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DebugUtils } from '@clients-nside-io/shared/util';
import { DatePipe } from '@angular/common';
import { AssessForms } from '../../../models/Constants';
import { IAssessCaseInfo, ICaseAttachmentInfo } from '@clients-nside-io/shared/models';

@Component({
  selector: 'nside-assess-attached-roars-list',
  templateUrl: './attached-roars-list.component.html',
  styleUrls: ['./attached-roars-list.component.css']
})
export class NsideAttachedRoarsListComponent {
  @Input() assessCase: IAssessCaseInfo;
  @Input() currentUserId: string | number;
  @Input() activeModal: NgbActiveModal;
  @Output() logActivity = new EventEmitter<IRoarActivityLogEvent>();
  @Output() reviewActivity = new EventEmitter<IRoarActivityLogEvent>();

  constructor(
    private assessService: AssessService,
    private debug: DebugUtils) { }

  private formatDate(dateValue: Date | string): string {
    const datePipe: DatePipe = new DatePipe('en-US');
    return datePipe.transform(dateValue, 'dd-MMM-YYYY HH:mm:ss');
  }

  private viewDownloadClick(event: any, assessCaseId?: string, formName?: string, submissionId?: string, download?: boolean): void {
    this.debug.log("Assessments.Component - viewDownloadClick executed. event:", event, "assessCaseId", assessCaseId, "submissionId:", submissionId);
    this.assessService.viewOrDownloadReport(event, assessCaseId, formName, submissionId, download);
  }

  get roarAssignments(): ICaseAttachmentInfo[] {
    return this.assessCase?.Attachments?.completedForms
      ?.filter(w => w.formName == AssessForms.names.roarAssignment);
  }

  get roarActivity(): ICaseAttachmentInfo[] {
    return this.assessCase?.Attachments?.completedForms
      ?.filter(w => w.formName == AssessForms.names.roarActivity);
  }

  get activityLogEnabled(): boolean {
    const formNumber = AssessForms.getFormsFor(this.assessCase?.CurrentStage)
      ?.indexOf(AssessForms.names.roarActivity) + 1;
    return this.assessService.isAbleToCreate(this.assessCase, formNumber, this.currentUserId);
  }

  get activityReviewEnabled(): boolean {
    const formNumber = AssessForms.getFormsFor(this.assessCase?.CurrentStage)
      ?.indexOf(AssessForms.names.roarActivity) + 1;
    return this.assessService.isAbleToReview(this.assessCase, formNumber, this.currentUserId, false);
  }

  emitLogActivity(roarAssignmentSubmissionId: string): void {
    this.logActivity.emit({
      assessCase: this.assessCase,
      submissionId: roarAssignmentSubmissionId
    });
  }

  emitReviewActivity(roarActivitySubmissionId: string): void {
    this.reviewActivity.emit({
      assessCase: this.assessCase,
      submissionId: roarActivitySubmissionId
    });
  }
}

export interface IRoarActivityLogEvent {
  assessCase: IAssessCaseInfo;
  submissionId: string;
}
