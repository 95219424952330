/*
  Developer: Quan Nguyen
  Date: 07/10/2021
  File: stage.component.ts
  Description: Handle the sidebar for stages view, which include current stage highlighted
  number of case, and current student name, and current case id when user clicked.
*/

import { Subscription } from 'rxjs';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ActionHandlerService } from '@clients-nside-io/shared/util';
import { AssessService } from '../../services/assess.service';
import { AuthorizationService } from '@clients-nside-io/shared/auth';
import { StageCasesCounts } from '../../models/AssessGeneral';
import { AssessStageBaseComponent } from '../../components/shared/AssessStageBase.component';
import { IAssessCaseInfo, IAssessDataForRoute } from '@clients-nside-io/shared/models';
import { isNumeric } from 'rxjs/internal-compatibility';
import { LocalStoreService } from '@clients-nside-io/shared/services';

@Component({
  selector: 'clients-nside-io-stage',
  templateUrl: './stage.component.html',
  styleUrls: ['./stage.component.scss'],
})
export class StageComponent implements OnInit, OnDestroy {
  private activeSubscriptions = new Map<string, Subscription>();
  private _buildingId: string;
  // private showSideBar = true;
  // private opened = true;
  isInFrame = false;
  stageJobsCount = new StageCasesCounts();
  currentUsername = "";
  currentCase: IAssessCaseInfo;
  // private currentCaseNumberSubscription: Subscription;
  // private updateCurrentCaseIdSubscription: Subscription;
  // private updateCurrentStudentNameSubscription: Subscription;
  // private currentStudentName = null;
  // private currentCaseId = null;

  get buildingId(): string {
    return isNumeric(this._buildingId)
      ? this._buildingId
      : this.localStore.buildingId;
  };

  constructor(
    private route: ActivatedRoute,
    private actionHandlerService: ActionHandlerService,
    private assessService: AssessService,
    private authorizationService: AuthorizationService,
    private localStore: LocalStoreService
  ) {}

  ngOnDestroy(): void {
    this.activeSubscriptions.forEach(sub => {
      sub?.unsubscribe();
    });
    this.activeSubscriptions.clear();
  }

  ngOnInit(): void {
    if (window.location !== window.parent.location) {
      this.isInFrame = true;
    } else {
      this.isInFrame = false;
    }

    //getting user info from auth service
    this.activeSubscriptions.set('auth_user$',
      this.authorizationService.user$.subscribe(user => this.currentUsername = user.name)
    );

    this.activeSubscriptions.set('activeStageCasesCounts$',
      this.assessService.activeStageCasesCounts$
        .subscribe(counts => {
          this.stageJobsCount = counts ?? new StageCasesCounts();
        })
    );

    //get building id
    const thisBuildingId = this.route.snapshot.paramMap.get('buildingId');
    if (isNumeric(thisBuildingId)) {
      this.assessService.updateBuildingId(parseInt(this.buildingId));
    }
  }

  private onNavEnd(data: IAssessDataForRoute) {
    if (isNumeric(data.buildingId)) {
      this._buildingId = data.buildingId.toString();
      this.assessService.updateBuildingId(parseInt(data.buildingId.toString()));
    }
  }

  private onCaseChange(data: IAssessCaseInfo) {
    this.currentCase = data;
  }

  public onRouterOutletActivate(ref: any): void {
    if (!(ref instanceof AssessStageBaseComponent))
      return;

    const child: AssessStageBaseComponent = ref;

    this.activeSubscriptions.set('navEnd',
      child.navEnd.subscribe(data => {
        this.onNavEnd(data);
      })
    );

    this.activeSubscriptions.set('caseChange',
      child.caseChange.subscribe(data => {
        this.onCaseChange(data);
      })
    );
  }

  public onRouterOutletDeactivate(ref: any): void {
    if (this.activeSubscriptions.has('navEnd')) {
      this.activeSubscriptions['navEnd']?.unsubscribe();
      this.activeSubscriptions.delete('navEnd');
    }
    if (this.activeSubscriptions.has('caseChange')) {
      this.activeSubscriptions['caseChange']?.unsubscribe();
      this.activeSubscriptions.delete('caseChange');
    }
  }
}
