import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AssessService } from '../../../services/assess.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { ActionHandlerService, DebugUtils } from '@clients-nside-io/shared/util';
import { ToastrService } from 'ngx-toastr';
import { interval, Subscription } from 'rxjs';
import { SidebarHighlightService } from '../../../services/sidebar-highlight.service';
import { AssessForms } from '../../../models/Constants';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AssessStages } from '../../../models/AssessGeneral';
import { AssessStageBaseComponent } from '../../shared/AssessStageBase.component';
import { LocalStoreService } from '@clients-nside-io/shared/services';

@Component({
  selector: 'clients-nside-io-closed',
  templateUrl: '../../shared/AssessStageBase.component.html',
  // templateUrl: './closed.component.html',
  styleUrls: ['./closed.component.scss']
})
export class ClosedComponent extends AssessStageBaseComponent implements OnInit, OnDestroy {
  public myTitle = 'Closed';
  protected readonly myStageName: AssessStages = AssessStages.closed;
  protected readonly myFormDisplayNames: string[] = AssessForms.displayNames.closed;
  protected readonly myFormNames: string[] = AssessForms.names.closed;
  protected readonly myReviewFormNumber: number = 1;
  protected readonly myReviewForm: string = '';

  constructor(
    protected assessService: AssessService,
    protected route: ActivatedRoute,
    protected router: Router,
    protected modalService: NgbModal,
    protected actionHandlerService: ActionHandlerService,
    protected toastr: ToastrService,
    protected debug: DebugUtils,
    protected sidebarHighlighter: SidebarHighlightService,
    protected localStore: LocalStoreService
  ) {
    super(assessService, route, router, modalService, actionHandlerService, toastr, debug, sidebarHighlighter, localStore);
  }

  // @ViewChild(DatatableComponent) table: DatatableComponent;
  // private currentActionHistory: ICaseStageActionInfo[];
  // private formDisplayNames = AssessForms.displayNames.closed;
  // private currentUserId: string | number;
  // private isOpeningForm: boolean;
  // private reloader$ub: Subscription;
  //
  // isAdding = false;
  // buildingId = "";
  // closedData: any = null;
  // temp = [];
  // currentCase: any;
  //
  // //current case number
  // stageJobsCount = {
  //   Evaluations: 0,
  //   Assessments: 0,
  //   Plans: 0,
  //   Interventions: 0,
  //   Monitoring: 0,
  //   Closed: 0,
  // };
  //
  // constructor(
  //   private assessService: AssessService,
  //   private route: ActivatedRoute,
  //   private router: Router,
  //   private modalService: NgbModal,
  //   private actionHandlerService: ActionHandlerService,
  //   private debug: DebugUtils,
  //   private toastr: ToastrService,
  //   private sidebarHighlighter: SidebarHighlightService) { }
  //
  // ngOnDestroy(): void {
  //   this.reloader$ub?.unsubscribe();
  // }
  //
  // ngOnInit(): void {
  //   localStorage.setItem('currentUrl', this.router.url);
  //
  //   // update userId
  //   this.currentUserId = this.assessService.getActiveUserId()
  //     ?? localStorage.getItem('currentAssessUserId');
  //
  //   this.sidebarHighlighter.highlightSideBar(this.router);
  //
  //   //get building id
  //   this.buildingId = this.route.parent.snapshot.paramMap.get('buildingId');
  //
  //   this.loadData();
  //   this.reloader$ub = interval(90000).subscribe(() => {
  //     this.loadData();
  //   });
  // }
  //
  // addClosed() {
  //   if (this.isAdding) {
  //     this.isAdding = false;
  //     this.router.navigate([`stage/${this.buildingId}/closed`]);
  //   } else {
  //     this.isAdding = true;
  //     this.router.navigate([`stage/${this.buildingId}/closed/${AssessForms.names.closed[0]}`]);
  //   }
  // }
  //
  // chooseForm(formNumber: number, caseId: string) {
  //   // console.log(caseId);
  //   const thisFormName = AssessForms.names.assessments[formNumber - 1];
  //   if (this.isOpeningForm) {
  //     this.isOpeningForm = false;
  //     this.router.navigate([`stage/${this.buildingId}/assessments`]);
  //   } else {
  //     this.isOpeningForm = true;
  //     this.debug.log(
  //       'AssessmentsComponent.chooseForm - buildingId:',
  //       this.buildingId
  //     );
  //     localStorage.setItem('selectedAssessCaseId', caseId);
  //     this.router.navigate([
  //       `stage/${this.buildingId}/assessments/${thisFormName}/${caseId}`,
  //     ]);
  //   }
  // }
  //
  // loadData() {
  //   //load data from the
  //   this.assessService.getAssessInfoByBuildingID(parseInt(this.buildingId))
  //     .subscribe({
  //       next: (res: any) => {
  //         this.closedData = this.getClosedData(res.data);
  //
  //         //update current case number--------------------------------------------
  //         const dataArray = res.data;
  //
  //         this.stageJobsCount.Evaluations = 0;
  //         this.stageJobsCount.Assessments = 0;
  //         this.stageJobsCount.Plans = 0;
  //         this.stageJobsCount.Interventions = 0;
  //         this.stageJobsCount.Monitoring = 0;
  //         this.stageJobsCount.Closed = 0;
  //
  //         for (let i = 0; i < dataArray.length; i++) {
  //           const data = dataArray[i].CurrentStage;
  //
  //           if (data == 'Evaluations') {
  //             this.stageJobsCount.Evaluations += 1;
  //           }
  //           if (data == 'Assessments') {
  //             this.stageJobsCount.Assessments += 1;
  //           }
  //           if (data == 'RMS-Plans') {
  //             this.stageJobsCount.Plans += 1;
  //           }
  //           if (data == 'Interventions') {
  //             this.stageJobsCount.Interventions += 1;
  //           }
  //           if (data == 'Monitoring') {
  //             this.stageJobsCount.Monitoring += 1;
  //           }
  //           if (data == 'Closed') {
  //             this.stageJobsCount.Closed += 1;
  //           }
  //         }
  //
  //         this.actionHandlerService.currentCaseNumber.next(this.stageJobsCount);
  //         //end update current case number--------------------------------------------
  //         console.log('closed data:', this.closedData);
  //       },
  //       error: (err: any) => {
  //         console.log(err);
  //       }
  //     });
  // }
  //
  // getClosedData(apiResponse: IAssessCaseInfo[]) {
  //   const dataArray = [];
  //
  //   apiResponse.forEach((data: any) => {
  //     dataArray.push(data);
  //   });
  //
  //   // console.log(dataArray);
  //
  //   const closedArray = [];
  //
  //   dataArray.forEach((data: any) => {
  //     console.log(data);
  //
  //
  //     if (data.CurrentStage == "Closed") {
  //       closedArray.push(data);
  //     }
  //   });
  //   const evaluationsArray = [];
  //
  //   if (dataArray.length > 0) {
  //     dataArray.forEach((data: any) => {
  //       if (data.CurrentStage == 'Closed') {
  //         const addData = {
  //           CaseId: data.CaseId,
  //           CaseUID: data.UID,
  //           CurrentStatus: data.CurrentStatus,
  //           Taken: data.LastUpdated,
  //           LastUpdatedByName: data.LastUpdatedByName,
  //           LastAction: data.Stages.Closed.Actions,
  //           IncidentSummary: data.IncidentSummary,
  //         };
  //
  //         evaluationsArray.push(addData);
  //       }
  //     });
  //
  //     return evaluationsArray.reverse();
  //   } else {
  //     this.toastr.error('We dont have any data yet!', 'Message: ');
  //     return dataArray;
  //   }
  // }
  //
  // rowClickHandler(event) {
  //   if (event.type == 'click') {
  //     this.actionHandlerService.updateCurrentStudentName.next(
  //       event.row.IncidentContact.ContactFirstName +
  //       ' ' +
  //       event.row.IncidentContact.ContactLastName
  //     );
  //     this.actionHandlerService.updateCurrentCaseId.next(event.row.CaseId);
  //     this.debug.log('=> row here', event.row);
  //     this.currentCase = event.row;
  //     this.currentActionHistory = this.assessService.caseCurrentStageActions(event.row);
  //   }
  // }
  //
  // getDataFromActivityArray(actArray: any[]) {
  //   if (!(actArray?.length > 0))
  //     return null;
  //
  //   const res =
  //     actArray[actArray.length - 1].Action +
  //     ' (' +
  //     actArray[actArray.length - 1].ActionName +
  //     ')';
  //   return res;
  // }
  //
  // formatDate(dateString: string) {
  //   const d = new Date(dateString);
  //   return `${d.getMonth()+1}-${d.getDate()}-${d.getFullYear()} ${d.getHours()}:${d.getMinutes()}:${d.getSeconds()}`
  // }
  //
  // timeConverter(time) {
  //   const [sHours, minutes] = time.match(/([0-9]{1,2}):([0-9]{2})/).slice(1);
  //   const period = +sHours < 12 ? 'AM' : 'PM';
  //   const hours = +sHours % 12 || 12;
  //
  //   return `${hours}:${minutes} ${period}`;
  // }
  //
  // updateFilter(event) {
  //   const val = event.target.value.toLowerCase();
  //
  //   // filter our data
  //   const temp = this.temp.filter(function (d) {
  //     return d.ActionName.toLowerCase().indexOf(val) !== -1 || !val;
  //   });
  //
  //   // update the rows
  //   this.closedData = temp;
  //   // Whenever the filter changes, always go back to the first page
  //   this.table.offset = 0;
  // }
  //
  // open(content) {
  //   // this.debug.log(content);
  //   this.modalService
  //     .open(content, {
  //       ariaLabelledBy: 'modal-basic-title',
  //       backdrop: 'static',
  //       keyboard: false,
  //       size: 'lg'
  //     })
  //     .result.then(
  //     (result) => {
  //       //this.closeResult = `Closed with: ${result}`;
  //     },
  //     (reason) => {
  //       //this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
  //     }
  //   );
  // }
  //
  // viewDownloadClick(event: any, assessCaseId?: string, formName?: string, submissionId?: string, download?: boolean) {
  //   this.debug.log("Assessments.Component - viewDownloadClick executed. event:", event, "assessCaseId", assessCaseId, "submissionId:", submissionId);
  //   this.assessService.viewOrDownloadReport(event, assessCaseId, formName, submissionId, download);
  // }
}
