import { Component, OnInit } from '@angular/core';
import { AuthorizationService } from '@clients-nside-io/shared/auth';
import { map, pluck, take } from 'rxjs/operators';
import { LocalStoreService } from '../../../../libs/shared/services/src/lib/localStore/localStore.service';

@Component({
  selector: 'clients-nside-io-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  isInFrame = false;
  title = 'nSide | Assess';
  loggedIn$ = this.authorizationService.loggedIn$;
  loaded$ = this.authorizationService.loading$;
  username$ = this.authorizationService.userName$;
  userId$ = this.authorizationService.user$.pipe(
    pluck("user_data"),
    map((el: any) =>
      JSON.parse(el.filter((item: any) => item.toLowerCase().includes("nsideid"))[0])
    ),
    pluck("Value"),
    take(1)
  );

  constructor(private authorizationService: AuthorizationService,
              private localStore: LocalStoreService) { }

  ngOnInit(): void {
    if (window.location !== window.parent.location) {
      this.isInFrame = true;
    } else {
      // console.log("not inside");
      this.isInFrame = false;
    }

    this.userId$.subscribe(res => this.localStore.userId = (res + ""));
  }

  logOut() {
    this.localStore.clear();
    this.authorizationService.logout();
  }
}
