import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { COMMA, ENTER, SEMICOLON, SPACE } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { IStudentOfConcernInfoResult } from '../student-concern-dialogs-helpers';
import { DebugUtils } from '@clients-nside-io/shared/util';

@Component({
  selector: 'nside-assess-student-concern-create',
  templateUrl: './student-concern-create.component.html',
  styleUrls: ['./student-concern-create.component.css']
})
export class StudentConcernCreateComponent {
  readonly separatorKeysCodes = [ENTER, COMMA, SEMICOLON, SPACE] as const;

  constructor(
    public dialogRef: MatDialogRef<StudentConcernCreateComponent>,
    public debug: DebugUtils,
    @Inject(MAT_DIALOG_DATA) public result: IStudentOfConcernInfoResult) { }

  onNoClick(): void {
    this.result.action = "Cancel";
    this.dialogRef.close(this.result);
  }

  onYesClick(): void {
    this.result.action = "OK";
    this.dialogRef.close(this.result);
  }

  isValid(): boolean {
    return this.result.data.state_identifier?.length > 0
      && this.result.data.first_name?.length > 0
      && this.result.data.last_name?.length > 0
      && this.result.data.birth_date?.length > 0
      && this.result.data.identified_gender?.length > 0
      && this.result.data.grade?.length > 0
      && this.result.data.physical_description?.length > 0
      && this.result.data.ihp?.length > 0
      && this.result.data.iep?.length > 0
      && this.result.data["504"]?.length > 0;
  }

  getAge(): number | null {
    const updBirthDate = new Date(isNaN(Date.parse(this.result.data?.birth_date.toString()))
      ? Date.now()
      : Date.parse(this.result.data?.birth_date.toString()));
    const nowDate = new Date(Date.now());

    if (nowDate == updBirthDate) { return null; }

    const calcAge = nowDate.getMonth() > updBirthDate.getMonth()
      || nowDate.getMonth() == updBirthDate.getMonth()
      && nowDate.getDay() >= updBirthDate.getDay()
        ? nowDate.getFullYear() - updBirthDate.getFullYear()
        : (nowDate.getFullYear() - 1) - updBirthDate.getFullYear();

    return calcAge > 0 && calcAge <= 120 ? calcAge : null;
  }

  addIEPContact($event: MatChipInputEvent) {
    this.debug.log('StudentConcernCreateComponent.addIEPContact - $event:', $event);
    const value = ($event.value || '').trim().toLowerCase();

    // Add contact email
    if (value) {
      this.result.data.iep_contacts.push(value);
    }

    // Clear the input value
    $event.chipInput?.clear();
  }

  removeIEPContact(email: string) {
    const index = this.result.data.iep_contacts.indexOf(email);

    if (index >= 0) {
      this.result.data.iep_contacts.splice(index, 1);
    }
  }
}
