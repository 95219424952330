import {
  Component,
  ChangeDetectorRef,
  Input,
  OnInit,
  OnDestroy
} from '@angular/core';
import { TestDataService } from '../../../services/test-data.service';
import { DomSanitizer } from '@angular/platform-browser';
import { AssessService } from '../../../services/assess.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AssessForms } from '../../../models/Constants';
import { StageCasesCounts } from '../../../models/AssessGeneral';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { ActionHandlerService, DebugUtils } from '@clients-nside-io/shared/util';
import { IAssessCaseInfo, IAssessToDoInfo, IStudentContactInfo } from '@clients-nside-io/shared/models';
import { LocalStoreService } from '@clients-nside-io/shared/services';
import { StudentConcernDialogs } from '../../shared/student-concern-dialogs/student-concern-dialogs.class';
import { MatDialog } from '@angular/material/dialog';
import {
  IStudentOfConcernPromptInfo,
  IStudentOfConcernPromptResult
} from '../../shared/student-concern-dialogs/student-concern-dialogs-helpers';

@Component({
  selector: 'clients-nside-io-home-overview',
  templateUrl: './home-overview.component.html',
  styleUrls: ['./home-overview.component.scss'],
})
export class HomeOverviewComponent implements OnInit, OnDestroy {
  @Input() assessCases: IAssessCaseInfo[];
  @Input() buildingId: string;
  @Input() hasNoCases: boolean;
  @Input() assessToDos: IAssessToDoInfo[];

  isAdding = false;
  isLoading = false;
  stageCasesCounts: StageCasesCounts;
  private activeSubscriptions = new Map<string, Subscription>();
  private studentDialogs: StudentConcernDialogs = null;

  constructor(
    private router: Router,
    private assessService: AssessService,
    private actionHandlerService: ActionHandlerService,
    private toastr: ToastrService,
    private localStore: LocalStoreService,
    private debug: DebugUtils,
    private matDialog: MatDialog
  ) {
    this.stageCasesCounts = new StageCasesCounts();
    this.studentDialogs = new StudentConcernDialogs(this.matDialog);
  }

  ngOnDestroy(): void {
    this.activeSubscriptions.forEach(sub => {
      sub?.unsubscribe();
    });
    this.activeSubscriptions.clear();
  }

  ngOnInit(): void {
    this.localStore.currentRoute = this.router.url;

    this.activeSubscriptions.set('formSubmissionSuccess$', this.actionHandlerService
      .formSubmissionSuccess$
      .subscribe((rsp) => {
        this.debug.log('HomeOverview Component - formSubmissionSuccess$ - response:', rsp);
        if (!(rsp?.statusCode == 200)) return;

        this.toastr.success(rsp.description ?? 'Form submitted successfully.', 'Submission Status:');

        if (this.assessService.isCaseApiResponse(rsp)) {
          this.assessService.processCaseApiResponse(rsp);
        }

        // this.localStore.resetForm('');
        // this.actionHandlerService.formSubmissionSuccessSub.next(null);
        // this.actionHandlerService.formSubmissionSuccessCompletedSub.next(true);
        this.isAdding = false;
        this.router.navigate([`building/${this.buildingId ?? this.localStore.buildingId ?? 0}`]);
      })
    );

    this.activeSubscriptions.set('formSubmissionError$', this.actionHandlerService
      .formSubmissionError$
      .subscribe((rsp) => {
        this.debug.warn('StageBase Component - formSubmissionError$ - value:', rsp);
        if (rsp == null) return;

        this.toastr.error(rsp.description
          ?? rsp.error?.log
          ?? 'Form submission error, please try again.', 'Submission Status:');
      })
    );

    this.activeSubscriptions.set('formServiceError$',
      this.assessService.formServiceError$.subscribe(err => {
        this.debug.warn('StageBase Component - formServiceError$ - value:', err);
        if (err == null)
          return;

        this.toastr.error(
          err.message?.length > 0
            ? err.message
            : 'Form service error, please try again.',
          err.title?.length > 0
            ? err.title
            : 'Form Error');
      })
    );

    this.activeSubscriptions.set('activeCases$',
      this.assessService.activeCases$.subscribe(cases => {
        this.assessCases = cases;
      })
    );

    this.activeSubscriptions.set('assessToDos$',
      this.assessService.assessToDoList$.subscribe(todos => {
        this.assessToDos = todos;
      })
    );

    this.activeSubscriptions.set('activeStageCasesCounts$',
      this.assessService.activeStageCasesCounts$.subscribe(counts => {
        this.stageCasesCounts = counts;
      })
    );
  }

  navigateTo(event) {
    switch (event) {
      case 'evaluations': {
        this.router.navigateByUrl('/stage/' + this.buildingId + '/evaluations');
        break;
      }

      case 'assessments': {
        this.router.navigateByUrl('/stage/' + this.buildingId + '/assessments');
        break;
      }

      case 'plans': {
        this.router.navigateByUrl('/stage/' + this.buildingId + '/plans');
        break;
      }

      case 'interventions': {
        this.router.navigateByUrl(
          '/stage/' + this.buildingId + '/interventions'
        );
        break;
      }

      case 'monitoring': {
        this.router.navigateByUrl('/stage/' + this.buildingId + '/monitoring');
        break;
      }
      case 'closed': {
        this.router.navigateByUrl('/stage/' + this.buildingId + '/closed');
        break;
      }
    }
  }

  private showConfirmationDialog(studentInfo: IStudentContactInfo) {
    const confirmDlg = this.studentDialogs.openConfirmationDialog(null, null, studentInfo);

    confirmDlg.subscribe((result) => {
      this.isLoading = false;

      if (result.action == "Cancel") {
        return;
      }

      const thisFormName = AssessForms.names.evaluations[0];
      this.isAdding = true;
      this.localStore.resetForm(thisFormName);
      this.router.navigate(
        [
          `stage/${this.buildingId}/evaluations/${thisFormName}`
        ],
        { state: { studentInfo: studentInfo } }
      );
    });

  }

  addingNewAssessment() {
    if (this.isAdding) {
      this.isAdding = false;
      this.router.navigate([`building/${this.buildingId}`]);
    } else {
      this.isLoading = true;
      const promptData: IStudentOfConcernPromptInfo = { ...this.studentDialogs.defaultPromptInfo, ...{ buildingID: this.buildingId, stateIdentifier: '' } };
      const promptDlg = this.studentDialogs.openPromptDialog(null, null, promptData);

      promptDlg.subscribe((result) => {
        if (result.action == "Cancel") {
          this.isLoading = false;
          return;
        }

        if (result.data?.stateIdentifier == null || result.data.stateIdentifier.length == 0) {
          this.toastr.error('State Identifier is required to create a new assessment.', 'Assessment Creation Error');
          this.isLoading = false;
          return;
        }

        this.assessService.getStudentInfoByIdentifier(result.data.stateIdentifier).subscribe((studentInfo) => {
          if (studentInfo == null) {
            const notFoundDlg = this.studentDialogs.openNotFoundDialog(null, null, result.data);

            notFoundDlg.subscribe((result) => {
              if (result.action == "Cancel") {
                this.isLoading = false;
                return;
              }

              if (result.data?.stateIdentifier == null || result.data.stateIdentifier.length == 0) {
                this.toastr.error('State Identifier is required to create a new assessment.', 'Assessment Creation Error');
                this.isLoading = false;
                return;
              }

              const createData: IStudentContactInfo = { ...this.studentDialogs.defaultContactInfo, ...{ state_identifier: result.data.stateIdentifier } };
              const createDlg = this.studentDialogs.openCreateDialog(null, null, createData);

              createDlg.subscribe((result) => {
                if (result.action == "Cancel") {
                  this.isLoading = false;
                  return;
                }

                if (result.data?.state_identifier == null || result.data.state_identifier.length == 0) {
                  this.toastr.error('State Identifier is required to create a new assessment.', 'Assessment Creation Error');
                  this.isLoading = false;
                  return;
                }

                this.assessService.addOrUpdateStudentInfo(result.data.state_identifier, result.data).subscribe((studentInfo) => {
                  if (studentInfo == null) {
                    this.toastr.error('Error creating Student Contact record. Please try again. If the problem persists, contact your administrator or nSide Support.', 'Assessment Creation Error');
                    this.isLoading = false;
                    return;
                  }

                  this.showConfirmationDialog(studentInfo);
                });
              });
            });
          } else {
            this.showConfirmationDialog(studentInfo);
          }
        });
      });
    }
  }

  getDataFromActivityArray(actArray: any[]) {
    const res =
      actArray[actArray.length - 1].Action +
      ' on \n' +
      this.formatDate(actArray[actArray.length - 1].Taken);
    return res;
  }

  timeConverter(time) {
    // const [sHours, minutes] = time.match(/([0-9]{1,2}):([0-9]{2})/).slice(1);
    // const period = +sHours < 12 ? 'AM' : 'PM';
    // const hours = +sHours % 12 || 12;

    // return `${hours}:${minutes} ${period}`;
    return time;
  }

  formatDate(dateString: string) {
    const d = new Date(dateString);
    const convertedTimeto12Format = this.timeConverter(
      `${d.getHours()}:${d.getMinutes()}:${d.getSeconds()}`
    );
    return `${d.getMonth() + 1}-${d.getDate()}-${d.getFullYear()}`;
  }
}
