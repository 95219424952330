import { IAddPropsBase, IAddPropsDefault } from './models';

export enum HandledApiResponseStatus {
  Loading = 'loading',
  Success = 'success',
  Error = 'error',
}

export interface IApiResponseBase extends IAddPropsDefault {
  statusCode: bigint | number;
  description?: string;
  error?: IApiResponseError;
  _links?: IApiResponseLinks;
}

export interface IApiResponseError extends IAddPropsDefault {
  type?: string | null | undefined;
  log?: string | null | undefined;
  exception?: any;
}

export interface IApiLinkInfo extends IAddPropsDefault {
  href: string | null | undefined;
  method?: string | null | undefined;
  body?: any;
  content?: string | null | undefined;
  accept?: string | null | undefined;
}

export type IApiResponseLinks = IAddPropsBase<IApiLinkInfo>

export interface IApiResponse<TData> extends IApiResponseBase {
  data?: TData;
}

export interface INsideUserPartial {
  address: string | undefined;
  city: string | undefined;
  county: string | undefined;
  email: string;
  id: number | string;
  isAdmin: boolean | string | undefined;
  name: string;
  organization: string | undefined;
  phone: string;
  phoneCell: string | undefined;
  position: string;
  state: string | undefined;
  zip: string | undefined;
  displayName: string | undefined;
}

interface IStudentContactInfoBase extends IAddPropsDefault {
  // required properties
  last_name: string;
  first_name: string;
  birth_date: string;
  physical_description: string;
  grade: string;
  identified_gender: string;
  iep: "Yes" | "No";
  504: "Yes" | "No";
  ihp: "Yes" | "No";

  // optional properties
  nside_student_id?: number | null | undefined;
  age?: number | undefined;
  photo?: string | undefined;
}

export interface IStudentStateIdentifierInfo extends IAddPropsDefault {
  // required properties
  location_id: number;
  state_identifier: string;

  // optional properties
  nside_student_id?: number | null | undefined;
  location_name?: string | null | undefined;
}

export interface IStudentAssessCaseInfo extends IAddPropsDefault {
  assess_case_id: string;
  district_name: string;
  school_name: string;
}

export interface IStudentContactInfoApiObj extends IStudentContactInfoBase {
  state_identifiers?: IStudentStateIdentifierInfo[] | null | undefined;
  iep_contacts_array?: string[] | null | undefined;
  assess_cases?: IStudentAssessCaseInfo[] | null | undefined;
}

export interface IStudentContactInfo extends IStudentContactInfoBase {
  // required properties
  state_identifier: string;

  // optional properties
  iep_contacts?: string[] | null | undefined;
  assess_cases?: IStudentAssessCaseInfo[] | null | undefined;
}

export type IStudentContactInfoApiRsp = IApiResponse<IStudentContactInfoApiObj>
