import { BrowserModule } from '@angular/platform-browser';
import {
  APP_INITIALIZER,
  NgModule,
  CUSTOM_ELEMENTS_SCHEMA,
} from '@angular/core';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';

import { HomeComponent } from './views/home/home.component';
import { StageComponent } from './views/stage/stage.component';
import { HeaderComponent } from './components/home-components/header/header.component';
import { NavigationBarComponent } from './components/home-components/navigation-bar/navigation-bar.component';
import { HomeOverviewComponent } from './components/home-components/home-overview/home-overview.component';
import { EvaluationsComponent } from './components/stage-components/evaluations/evaluations.component';
import { AssessmentsComponent } from './components/stage-components/assessments/assessments.component';
import { PlansComponent } from './components/stage-components/plans/plans.component';
import { InterventionsComponent } from './components/stage-components/interventions/interventions.component';
import { MonitoringComponent } from './components/stage-components/monitoring/monitoring.component';
import { ClosedComponent } from './components/stage-components/closed/closed.component';
import { BuildingIdMissingComponent } from './views/building-id-missing/building-id-missing.component';
import { PageNotFoundComponent } from './views/page-not-found/page-not-found.component';

import { AuthModule, OidcConfigService } from 'angular-auth-oidc-client';
import { AuthInterceptor } from '@clients-nside-io/shared/auth';
import { environment } from '../environments/environment';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { MaterialModule } from './material';

import { RedirectComponent } from './views/redirect/redirect.component';

//for preventing redirect
import { SharedComponentsModule } from '@clients-nside-io/shared/components';
import { UnauthorizedComponent } from './views/unauthorized/unauthorized.component';

import { NgxDatatableModule } from '@swimlane/ngx-datatable';

import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyBootstrapModule } from '@ngx-formly/bootstrap';
import * as ngxToastr from 'ngx-toastr';
import { ReviewTeamSelectingComponent } from './views/case-based/review-team-selecting/review-team-selecting.component';
import { MatDialog } from '@angular/material/dialog';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIcon, MatIconModule } from '@angular/material/icon';
import { AttachmentsListComponent } from './components/shared/nside-attachments-list/attachments-list.component';
import {
  NsideAttachedRoarsListComponent
} from './components/shared/nside-attached-roars-list/attached-roars-list.component';
import { DebugUtils } from '@clients-nside-io/shared/util';
import { StudentConcernPromptComponent } from './components/shared/student-concern-dialogs/student-concern-prompt/student-concern-prompt.component';
import { StudentConcernConfirmationComponent } from './components/shared/student-concern-dialogs/student-concern-confirmation/student-concern-confirmation.component';
import { StudentConcernNotFoundComponent } from './components/shared/student-concern-dialogs/student-concern-not-found/student-concern-not-found.component';
import { StudentConcernCreateComponent } from './components/shared/student-concern-dialogs/student-concern-create/student-concern-create.component';
import { MatChipsModule } from '@angular/material/chips';

//set up authentication function
export function configureAuth(
  oidcConfigService: OidcConfigService
): () => Promise<any> {
  return () => oidcConfigService.withConfig(environment.identityConfig);
}

@NgModule({
  declarations: [
    AppComponent,
    StageComponent,
    HomeComponent,
    HeaderComponent,
    NavigationBarComponent,
    HomeOverviewComponent,
    EvaluationsComponent,
    AssessmentsComponent,
    PlansComponent,
    InterventionsComponent,
    MonitoringComponent,
    ClosedComponent,
    BuildingIdMissingComponent,
    PageNotFoundComponent,
    RedirectComponent,
    UnauthorizedComponent,
    ReviewTeamSelectingComponent,
    AttachmentsListComponent,
    NsideAttachedRoarsListComponent,
    StudentConcernPromptComponent,
    StudentConcernConfirmationComponent,
    StudentConcernNotFoundComponent,
    StudentConcernCreateComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    MaterialModule,
    AuthModule.forRoot(),
    SharedComponentsModule,
    NgxDatatableModule,
    FormsModule,
    ReactiveFormsModule,
    FormlyBootstrapModule,
    FormlyModule.forRoot({ extras: { lazyRender: true } }),
    ngxToastr.ToastrModule.forRoot(),
    MatDialogModule,
    MatIconModule,
    MatChipsModule
  ],
  providers: [
    //set up providers for security service
    OidcConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: configureAuth,
      deps: [OidcConfigService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    MatDialog,
    MatIcon
  ],
  bootstrap: [AppComponent],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class AppModule {
  constructor(private debug: DebugUtils) {
    debug.setEnvironment(environment);
  }
}
