import { IApiLinkInfo, IApiResponse, IApiResponseError, IApiResponseLinks, INsideUserPartial } from './ApiModels';

export class LinkDto implements IApiLinkInfo {
  href: string | null | undefined = '';
  method: string | null | undefined = '';
  body: any;
  content: string | null | undefined = '';
  accept: string | null | undefined = '';
}

export class LinkDtoList implements IApiResponseLinks {
  [key: string]: LinkDto;
}

export class ResponseError implements IApiResponseError {
  type: string | null | undefined = '';
  log: string | null | undefined = '';
  exception: any | null | undefined = undefined;
}

export type IBoolRsp = IApiResponse<boolean>;

export interface IAssessCaseInfo {
  AssignedTeam: ICaseAssignedTeam | null | undefined;
  Attachments: ICaseAttachments | null | undefined;
  BuildingId: number;
  CaseId: string;
  Created: Date;
  CreatedBy: number;
  CurrentStage: string;
  CurrentStatus: string;
  IncidentContact: ICaseContact | null | undefined;
  IncidentOccurred: Date;
  IncidentSummary: string;
  LastUpdated: Date;
  LastUpdatedBy: number;
  LastUpdatedByName: string;
  Stages: ICaseStages | null | undefined;
  UID: number;
  [additionalProperties: string]: any;
}

export interface ICaseContact {
  ContactFirstName: string;
  ContactLastName: string;
  ContactUid: number | null;
}

export interface ICaseAssignedTeam {
  LastUpdated: Date;
  LastUpdatedBy: number;
  LastUpdatedByName: string;
  Users: INsideUserPartial[];
}

export interface ICaseAttachments {
  completedForms: ICaseAttachmentInfo[] | null | undefined;
  inProgressForms: ICaseAttachmentInfo[] | null | undefined;
  fileLinks: CaseAttachmentInfo[] | null | undefined;
}

export interface ICaseAttachmentInfo {
  attachmentType: number;
  name: string;
  description: string | null | undefined;
  date: Date;
  user: string;
  url: string | null | undefined;
  formDisplayName: string | null | undefined;
  formName: string | null | undefined;
  formType: string | null | undefined;
  submissionId: string | null | undefined;
  roar_admin_summary?: string | null | undefined;
  roar_category?: string | null | undefined;
  roar_type?: string | null | undefined;
  roar_reviewer_display_name?: string | null | undefined;
  roar_review_required?: boolean | null | undefined;

  get isForm(): boolean;
  get isFormCompleted(): boolean;
  get isFormInProgress(): boolean;
  get isFileLink(): boolean;
  get isFileLinkInternal(): boolean;
  get isFileLinkExternal(): boolean;
}

export class CaseAttachmentInfo implements ICaseAttachmentInfo {
  attachmentType: number;
  date: Date;
  description: string | null | undefined;
  formDisplayName: string | null | undefined;
  formName: string | null | undefined;
  formType: string | null | undefined;
  name: string;
  submissionId: string | null | undefined;
  url: string | null | undefined;
  user: string;

  get isForm(): boolean {
    return this.attachmentType < 3;
  }

  get isFormInProgress(): boolean {
    return this.attachmentType === 1;
  }

  get isFormCompleted(): boolean {
    return this.attachmentType === 0;
  }

  get isFileLink(): boolean {
    return this.attachmentType >= 1000
      && this.attachmentType <= 2999;
  }

  get isFileLinkInternal(): boolean {
    return this.attachmentType >= 1000
      && this.attachmentType <= 1999;
  }

  get isFileLinkExternal(): boolean {
    return this.attachmentType >= 2000
      && this.attachmentType <= 2999;
  }
}

export interface ICaseStages {
  Evaluations: ICaseStageInfo | null | undefined;
  Assessments: ICaseStageInfo | null | undefined;
  "RMS-Plans": ICaseStageInfo | null | undefined;
  Interventions: ICaseStageInfo | null | undefined;
  Monitoring: ICaseStageInfo | null | undefined;
  Closed: ICaseStageInfo | null | undefined;
}

export interface ICaseStageInfo {
  Actions: ICaseStageActionInfo[] | null | undefined;
  Data: any | null | undefined;
  LastUpdated: Date;
  LastUpdatedBy: number;
  LastUpdatedByName: string;
  Stage: string;
  SubmissionIds: string[] | null | undefined;
}

export interface ICaseStageActionInfo {
  Action: string;
  ActionName: string;
  Data: ICaseStageActionData | null | undefined;
  SubmissionId: string;
  Taken: Date;
  TakenBy: number;
  TakenByName: string;
}

export interface ICaseStageActionData {
  assignedUser: INsideUserPartial | null | undefined;
  [additionalProperties: string]: any;
}

export interface IAssessToDoInfo {
  'assess-case-id': string;
  'current-stage': string;
  subject: string;
  body: string;
}
