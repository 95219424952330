import { DebugUtils} from '@clients-nside-io/shared/util';
import { ActionHandlerService } from '@clients-nside-io/shared/util';
import { AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import { AssessService } from '../../../services/assess.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { SidebarHighlightService } from '../../../services/sidebar-highlight.service';
import { AssessForms } from '../../../models/Constants';
import { AssessStages} from '../../../models/AssessGeneral';
import { AssessStageBaseComponent } from '../../shared/AssessStageBase.component';
import { LocalStoreService } from '@clients-nside-io/shared/services';

@Component({
  selector: 'clients-nside-io-assessments',
  templateUrl: '../../shared/AssessStageBase.component.html',
  styleUrls: ['./assessments.component.scss'],
})
export class AssessmentsComponent extends AssessStageBaseComponent implements OnInit, OnDestroy, AfterViewInit {
  public myTitle = 'Assessments';
  protected readonly myStageName: AssessStages = AssessStages.assessments;
  protected readonly myFormDisplayNames: string[] = AssessForms.displayNames.assessments;
  protected readonly myFormNames: string[] = AssessForms.names.assessments;
  protected readonly myReviewFormNumber: number = 1;
  protected readonly myReviewForm: string = this.myFormNames[this.myReviewFormNumber - 1];

  constructor(
    protected assessService: AssessService,
    protected route: ActivatedRoute,
    protected router: Router,
    protected modalService: NgbModal,
    protected actionHandlerService: ActionHandlerService,
    protected toastr: ToastrService,
    protected debug: DebugUtils,
    protected sidebarHighlighter: SidebarHighlightService,
    protected localStore: LocalStoreService
  ) {
    super(assessService, route, router, modalService, actionHandlerService, toastr, debug, sidebarHighlighter, localStore);
  }
}
