<div class="container-fluid navigation-bar mt-3">
  <div class="row">
    <ng-container *ngIf='!hasHeader'>
      <div class="logo-container col-auto">
        <a class="logo" href="/"> </a>
      </div>
      <div class="col">
        <div class="row">
          <div class="col-12 h4 text-secondary">
            Assess
          </div>
          <div class="col-12 text-secondary">
            <p>Home <i class="fa fa-angle-right"></i> <span class="text-danger ml-1">Dashboard</span></p>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf='hasHeader'>
      <div class="col-12 h4 text-secondary">
          Assess
      </div>
      <div class="col-12 text-secondary">
          <p class='mb-2'>Home <i class="fa fa-angle-right"></i> <span class="text-danger ml-1">Dashboard</span></p>
      </div>
    </ng-container>
  </div>
</div>
