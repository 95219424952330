<div class="container-fluid overview-content w-100 h-100">
  <div *ngIf="(!(assessCases?.length > 0) && !hasNoCases) || isLoading">
    <mat-spinner></mat-spinner>
  </div>
  <button
    *ngIf="isAdding"
    class="btn btn-warning float mr-4"
    (click)="addingNewAssessment()"
    style="z-index: 2"
  >
    <i class="fa fa-times fa-2x my-float mb-2"></i>
  </button>
  <button
    *ngIf="!isAdding"
    class="btn btn-warning float mr-4"
    (click)="addingNewAssessment()"
    style="z-index: 2"
  >
    <i class="fa fa-plus fa-2x my-float"></i>
  </button>

  <div *ngIf="isAdding">
    <div class="row justify-content-center">
      <div class="form-container">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
  <div *ngIf="!isAdding && !isLoading">
    <div class="row">
      <div class="col-6">
        <h4 class="text-center text-secondary p-3">Recent Actions</h4>
      </div>
      <div class="col-6">
        <h4 class="text-center text-secondary p-3">My Tasks</h4>
      </div>
    </div>
    <div class="row">
      <div class="col-6 p-2">
        <ngx-datatable
          class="material"
          [rows]="assessCases"
          #table
          columnMode="force"
          [headerHeight]="50"
          [footerHeight]="50"
          rowHeight="auto"
          [limit]="8"
          [messages]="{emptyMessage: 'No Assess Case Actions to Display.'}">
          <ngx-datatable-column name="Case ID" prop="CaseId" [width]='-5'>
          </ngx-datatable-column>
          <ngx-datatable-column name="Stage" prop="CurrentStage" [width]='-10'>
          </ngx-datatable-column>
          <ngx-datatable-column name="Current Status" prop="CurrentStatus" [width]='-10'>
          </ngx-datatable-column>
          <ngx-datatable-column name="Last Action">
            <ng-template ngx-datatable-cell-template let-row="row">
              {{ assessService.caseLastActionDisplay(row) }}
            </ng-template>
          </ngx-datatable-column>
        </ngx-datatable>
      </div>
      <div class="col-6 p-2">
        <ngx-datatable
          class="material"
          [rows]="assessToDos"
          #table
          columnMode="force"
          [headerHeight]="50"
          [footerHeight]="50"
          rowHeight="auto"
          [limit]="5"
          [messages]="{emptyMessage: 'No Task Messages to Display.'}">
          <ngx-datatable-column name="Case ID" prop="assess-case-id" [width]='-5'>
          </ngx-datatable-column>
          <ngx-datatable-column name="Stage" prop="current-stage" [width]='-10'>
          </ngx-datatable-column>
          <ngx-datatable-column name="Subject" prop="subject" [width]='-10'>
          </ngx-datatable-column>
          <ngx-datatable-column name="Message" prop="body">
          </ngx-datatable-column>
        </ngx-datatable>
      </div>
    </div>
  </div>
</div>
