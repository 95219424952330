import {
  AfterViewInit,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { AssessStages, StageCasesCounts } from '../../models/AssessGeneral';
import { BehaviorSubject, defer, Observable, Subscription } from 'rxjs';
import { IAssessUserInfo } from '../../models/DTOs';
import { AssessService } from '../../services/assess.service';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import {
  ActionHandlerService,
  DebugUtils,
  getAssessDataForRoute,
  getFullDataForRoute,
  navigationEndEventFilter
} from '@clients-nside-io/shared/util';
import { ToastrService } from 'ngx-toastr';
import { SidebarHighlightService } from '../../services/sidebar-highlight.service';
import { DatePipe } from '@angular/common';
import { AssessForms } from '../../models/Constants';
import { LocalStoreService } from '@clients-nside-io/shared/services';
import {
  IAssessCaseInfo,
  IAssessDataForRoute,
  ICaseStageActionInfo,
  ICaseStageInfo, IStudentContactInfo
} from '@clients-nside-io/shared/models';
import { isNumeric } from 'rxjs/internal-compatibility';
import { StudentConcernDialogs } from './student-concern-dialogs/student-concern-dialogs.class';

@Component({
  template: ''
})
export abstract class AssessStageBaseComponent implements OnInit, OnDestroy, AfterViewInit{
  @Output() navEnd = new EventEmitter<IAssessDataForRoute>();
  @Output() caseChange = new EventEmitter<IAssessCaseInfo>();

  @ViewChild(DatatableComponent) table: DatatableComponent;
  @ViewChild('askReview') reviewModal: TemplateRef<any>;
  @ViewChild('threatAssessmentTeamSelection') threatAssessmentTeamSelectionModal: TemplateRef<any>;
  @ViewChild('teamSelectionConsider') teamSelectionConsiderModal: TemplateRef<any>;
  @ViewChild('roarActivity') roarActivityModal: TemplateRef<any>;
  @ViewChild('attachments') attachmentsModal: TemplateRef<any>;

  private firstDataCount = -1;
  private _buildingId: string;

  public abstract readonly myTitle: string;
  protected abstract readonly myStageName: AssessStages;
  protected abstract readonly myReviewForm: string;
  protected abstract readonly myFormNames: string[];
  protected abstract readonly myFormDisplayNames: string[];

  protected readonly myReviewFormNumber: number = 1;
  protected activeSubscriptions = new Map<string, Subscription>();
  protected afterViewInitObservables: Observable<any>[] = [];
  protected assessUserList: IAssessUserInfo[] = [];
  protected reviewerId: number;
  protected assignedUserId: number;
  protected currentFormName: string;
  protected stageCaseData: IAssessCaseInfo[] = null;
  protected stageCaseDataSubject = new BehaviorSubject<IAssessCaseInfo[]>(null);
  // protected loadingInfo: DataLoadingInfo;
  protected currentCaseSubject = new BehaviorSubject<IAssessCaseInfo>(null);
  protected currentUserId: string | number;
  protected stageJobsCount = new StageCasesCounts();
  protected currentActionHistory: ICaseStageActionInfo[];
  protected isWaitingForHttpRequest: boolean;
  protected isSelectingTeamMember: boolean;
  protected teamSelectionTitle: string;
  protected mySelectedUserIDs: number[];
  protected isLoading = false;
  protected studentDialogs: StudentConcernDialogs = null;
  protected currentStudentInfo: IStudentContactInfo = null;

  public isOpeningForm: boolean;

  protected get buildingId(): string {
    return isNumeric(this._buildingId)
      ? this._buildingId
      : this.localStore.buildingId;
  };

  protected get currentCase(): IAssessCaseInfo {
    return this.currentCaseSubject?.getValue();
  }

  protected get selectedUsers(): IAssessUserInfo[] {
    return this.assessUserList
        ?.filter(f => f.isSelected == true)
      ?? [];
  }

  protected get selectedUserIDs(): number[] {
    this.mySelectedUserIDs = this.selectedUsers
        ?.map(m => m.userID)
      ?? [];
    return this.mySelectedUserIDs;
  }

  protected get selectedTeamMatches(): boolean {
    const currAssigned = this.currentCase?.AssignedTeam?.Users ?? [];
    return currAssigned.length == this.selectedUserIDs.length
      && currAssigned.every(user => this.selectedUserIDs
          ?.findIndex(val => val.toString() == user.id?.toString()) >= 0 ) == true;
  }

  protected get myRelativeUrlBase(): string {
    switch (this.myStageName) {
      case AssessStages.evaluations:
        return `stage/${this.buildingId}/evaluations`;
      case AssessStages.assessments:
        return `stage/${this.buildingId}/assessments`;
      case AssessStages.plans:
        return `stage/${this.buildingId}/plans`;
      case AssessStages.interventions:
        return `stage/${this.buildingId}/interventions`;
      case AssessStages.monitoring:
        return `stage/${this.buildingId}/monitoring`;
      case AssessStages.closed:
        return `stage/${this.buildingId}/closed`;
      default:
        return `stage/${this.buildingId}/evaluations`;
    }
  }

  protected get hasNoCases(): boolean {
    return this.firstDataCount == 0
      && this.stageCaseData != null
      && this.stageCaseData.length == 0;
  }

  protected isCurrentStage(stage: ICaseStageInfo): boolean {
    return this.currentCase?.CurrentStage?.toLowerCase() === stage?.Stage?.toLowerCase();
  }

  protected get currentCaseHistory(): ICaseStageInfo[] {
    const rv: ICaseStageInfo[] = [];

    if (this.currentCase == null) return [];

    if (this.currentCase?.Stages?.Evaluations) {
      rv.push(this.currentCase.Stages.Evaluations);
    }

    if (this.currentCase?.Stages?.Assessments) {
      rv.push(this.currentCase.Stages.Assessments);
    }

    if (this.currentCase?.Stages?.['RMS-Plans']) {
      rv.push(this.currentCase.Stages['RMS-Plans']);
    }

    if (this.currentCase?.Stages?.Interventions) {
      rv.push(this.currentCase.Stages.Interventions);
    }

    if (this.currentCase?.Stages?.Monitoring) {
      rv.push(this.currentCase.Stages.Monitoring);
    }

    if (this.currentCase?.Stages?.Closed) {
      rv.push(this.currentCase.Stages.Closed);
    }

    return rv;
  };

  protected constructor(
    protected assessService: AssessService,
    protected route: ActivatedRoute,
    protected router: Router,
    protected modalService: NgbModal,
    protected actionHandlerService: ActionHandlerService,
    protected toastr: ToastrService,
    protected debug: DebugUtils,
    protected sidebarHighlighter: SidebarHighlightService,
    protected localStore: LocalStoreService
  ) {
    this.activeSubscriptions.set('formSubmissionSuccess$', this.actionHandlerService
      .formSubmissionSuccess$
      .subscribe((rsp) => {
        this.debug.log('StageBase Component - formSubmissionSuccess$ - response:', rsp);
        if (!(rsp?.statusCode == 200)) return rsp;

        this.toastr.success(rsp.description ?? 'Form submitted successfully.', 'Submission Status:');

        if (this.assessService.isCaseApiResponse(rsp)) {
          this.assessService.processCaseApiResponse(rsp);
        }

        // this.localStore.resetForm('');
        this.actionHandlerService.formSubmissionSuccessSub.next(null);
        // this.actionHandlerService.formSubmissionSuccessCompletedSub.next(true);
        this.closeForm();
      })
    );

    this.activeSubscriptions.set('formSubmissionError$', this.actionHandlerService
      .formSubmissionError$
      .subscribe((rsp) => {
        if (rsp == null) return;
        this.debug.warn('StageBase Component - formSubmissionError$ - value:', rsp);

        this.toastr.error(rsp.description
          ?? rsp.error?.log
          ?? 'Form submission error, please try again.', 'Submission Status:');
      })
    );

    this.activeSubscriptions.set('formServiceError$',
      this.assessService.formServiceError$.subscribe(err => {
        if (err == null)
          return;

        this.debug.warn('StageBase Component - formServiceError$ - value:', err);
        this.toastr.error(
          err.message?.length > 0
            ? err.message
            : 'Form service error, please try again.',
          err.title?.length > 0
            ? err.title
            : 'Form Error');
      })
    );

    this.activeSubscriptions.set('currentCaseSubject',
      this.currentCaseSubject.subscribe(selectedCase => {
        this.assessUserList.forEach(usr => {
          usr.isSelected = selectedCase?.AssignedTeam?.Users
            ?.findIndex(w => w.id?.toString() == usr.userID.toString()) >= 0;
        });

        this.currentActionHistory = this.assessService.caseCurrentStageActions(selectedCase);
        this.caseChange.emit(selectedCase);
      })
    );

    this.activeSubscriptions.set('router_events', this.router.events
      .pipe(navigationEndEventFilter)
      .subscribe(event => {
        const val = getFullDataForRoute(this.router);
        const data = getAssessDataForRoute(val);

        this.navEnd.emit(data);

        // if (data?.buildingId) {
        //   this.buildingId = data.buildingId.toString();
        //   localStorage.setItem('buildingId', this.buildingId);
        //   localStorage.setItem('currentAssessBuildingId', this.buildingId);
        // }
        this.localStore.setFromRouteData(val);

        if (data?.isOpeningForm == true
          && data.formName?.length > 0
          && this.myFormNames.findIndex(w => w == data.formName.toLowerCase()) >= 0) {
          this.isOpeningForm = true;
          this.currentFormName = data.formName.toLowerCase();
        }

        if (val.url[0].path.toLowerCase().startsWith('action')
          && data?.action?.length > 0
          && data?.assessCaseID?.length > 0
          && this.stageCaseData.findIndex(w => w.CaseId == data.assessCaseID) >= 0) {

          this.currentCaseSubject.next(this.stageCaseData.find(w => w.CaseId == data.assessCaseID));

          switch (data.action.toLowerCase()) {
            case 'create':
              //this.actionHandlerService.updateCurrentCaseId.next(data.assessCaseID);
              break;
            case 'review':
              // do something?
              break;
            case 'view':
              if (data.formName?.length > 0 && data.submissionId?.length > 0) {
                this.afterViewInitObservables.push(
                  defer(() =>
                    {
                      this.assessService
                        .viewOrDownloadReport(null, data.assessCaseID, data.formName, data.submissionId, false)
                    }
                  )
                );
              }
              else
              {
                this.toastr.warning('Unable to view requested form.');
                this.debug.warn('Unable to process request to view form! Route Data:', val);
              }
              break;
            case 'download':
              if (data.formName?.length > 0 && data.submissionId?.length > 0) {
                this.afterViewInitObservables.push(
                  defer(() =>
                    {
                      this.assessService
                        .viewOrDownloadReport(null, data.assessCaseID, data.formName, data.submissionId, true)
                    }
                  )
                );
              }
              else
              {
                this.toastr.warning('Unable to download requested form.');
                this.debug.warn('Unable to process request to download form! Route Data:', val);
              }
              break;
            case '':
              // do something
              break;
            default:
              // do nothing
              break;
          }
        }
      })
    );
  }

  ngAfterViewInit(): void {
    let idx = 0;
    this.afterViewInitObservables.forEach(obs => {
      this.activeSubscriptions.set(`afterViewInitObservables_${idx++}`,
        obs.subscribe()
      );
    });
    this.afterViewInitObservables = [];
  }

  ngOnDestroy(): void {
    this.activeSubscriptions.forEach(sub => {
      sub?.unsubscribe();
    });
    this.activeSubscriptions.clear();
  }

  ngOnInit(): void {
    this.localStore.currentRoute = this.router.url;

    // update userId
    this.currentUserId = this.assessService.getActiveUserId()
      ?? this.localStore.userId;

    if (!this._buildingId) {
      this._buildingId = this.localStore.buildingId;
    }

    this.sidebarHighlighter.highlightSideBar(this.router);

    this.activeSubscriptions.set('stageCaseDataSubject',
      this.stageCaseDataSubject
        .subscribe((data) => {
          this.stageCaseData = data;
        })
    );

    // this.loadingInfo = new DataLoadingInfo(this.myStageName);
    // this.loadData();
    // this.activeSubscriptions.set('reloadObs$',
    //   this.loadingInfo.reloadObs$.subscribe(doUpdate => {
    //     if (doUpdate) this.loadData(this.loadingInfo.lastLoaded);
    //   })
    // );

    this.activeSubscriptions.set('activeAssessUsers$',
      this.assessService.activeAssessUsers$.subscribe(users => {
        this.assessUserList = users;
      })
    );

    this.activeSubscriptions.set('activeCases$',
      this.assessService.activeCases$.subscribe(cases => {
        if (cases == null)
          return;

        if (this.firstDataCount <= 0)
          this.firstDataCount = cases.length;

        const theseCases = cases.filter(w => w.CurrentStage == this.myStageName);
        this.stageCaseDataSubject.next(theseCases);
      })
    );
  }

  protected rowClickHandler(event) {
    if (event.type == 'click') {
      // this.actionHandlerService.updateCurrentCaseId.next(event.row.CaseId);
      // this.actionHandlerService.updateCurrentStudentName.next(
      //   event.row.IncidentContact.ContactFirstName +
      //   ' ' +
      //   event.row.IncidentContact.ContactLastName
      // );
      this.currentCaseSubject.next(event.row);
      // this.currentActionHistory = this.assessService.caseCurrentStageActions(event.row);
    }
  }

  protected chooseForm(formNumber: number, assessCase: IAssessCaseInfo, skipNavigation?: boolean): void {
    const thisFormName = this.myFormNames[formNumber - 1];
    const thisCase = assessCase ?? this.currentCase;

    if (!thisCase) {
      this.toastr.error("An error occurred. Please try again.");
      return;
    }

    if (thisFormName == AssessForms.names.roarActivity) {
      this.open(this.roarActivityModal, thisCase, true);

      return;
    }

    this.isOpeningForm = true;
    this.currentCaseSubject.next(thisCase);
    //localStorage.setItem('selectedAssessCaseId', thisCase.CaseId);
    this.localStore.setFromAssessCase(thisCase);

    if (skipNavigation == true) return;

    this.router.navigate([
      `${this.myRelativeUrlBase}/${thisFormName}/${thisCase.CaseId}`,
    ]);
  }

  // protected loadData(activitySince?: Date) {
  //   this.loadingInfo.pause();
  //   const requestStarted = new Date();
  //   const sub = this.assessService
  //     .getAssessInfoByBuildingID(parseInt(this.buildingId),
  //       this.loadingInfo.currentStage, activitySince)
  //     .subscribe({
  //       next: (res: IAssessCasesRsp) => {
  //         this.assessUserList = [];
  //         const assessUsers: any[] = res.assessUsers;
  //         assessUsers.forEach((user) => {
  //           if (user.permissions?.settingsPermissions?.
  //             assess_permissions?.canSubmitAssessments == true) {
  //             this.assessUserList.push(user);
  //           }
  //         });
  //
  //         this.stageCaseDataSubject.next(this.getStageData(res.data));
  //         this.stageJobsCount.update(res.data);
  //         this.actionHandlerService.currentCaseNumber.next(this.stageJobsCount);
  //         this.loadingInfo.update(requestStarted);
  //         this.loadingInfo.resume();
  //         sub.unsubscribe();
  //       },
  //       error: (err: any) => {
  //         this.debug.log(`${this.myStageName} Component - loadData - There was an error loading/reloading data:`, err);
  //         this.loadingInfo.resume();
  //         sub.unsubscribe();
  //       },
  //     });
  // }

  protected getStageData(apiData: IAssessCaseInfo[]) {
    const dataArray = apiData
      .filter(f => f.CurrentStage == this.myStageName)
      .sort((a,b) =>
        a.LastUpdated > b.LastUpdated
          ? 1
          : a.LastUpdated < b.LastUpdated
            ? -1
            : 0
      ).reverse();

    return dataArray;
  }

  protected formatDate(dateString: string) {
    const datepipe: DatePipe = new DatePipe('en-US');
    return datepipe.transform(dateString, 'dd-MMM-YYYY HH:mm:ss');
  }

  protected updateFilter(event) {
    const val = event.target.value?.trim()?.toLowerCase() ?? '';

    if (!(val.length > 0)) {
      const fullData = this.stageCaseDataSubject.value;
      this.stageCaseDataSubject.next(fullData);
    }

    const filtered = this.stageCaseData.filter(function (d) {
      return (
        d.CaseId.toLowerCase().indexOf(val) !== -1 ||
        d.LastUpdatedByName.toLowerCase().indexOf(val) !== -1 ||
        d.IncidentSummary.toLowerCase().indexOf(val) !== -1 ||
        !val
      );
    });
    this.stageCaseData = filtered;
    this.table.offset = 0;
  }

  protected open(content, assessCase?: IAssessCaseInfo, bgDismiss?: boolean, modalSize?: string) {
    if (assessCase)
      this.currentCaseSubject.next(assessCase);

    const canDismiss = bgDismiss == true;
    const options: NgbModalOptions = {
      ariaLabelledBy: 'modal-basic-title',
      backdrop: canDismiss ? true : 'static',
      keyboard: canDismiss,
      size: modalSize?.length > 0 ? modalSize : 'lg'
    }

    this.modalService
      .open(content, options);
  }

  protected getDataFromActivityArray(actArray: any[]) {
    if (!(actArray?.length > 0))
      return null;

    const res =
      actArray[actArray.length - 1].Action +
      ' (' +
      actArray[actArray.length - 1].ActionName +
      ')';
    return res;
  }

  protected closeForm() {
    this.isOpeningForm = false;
    this.sidebarHighlighter.highlightSideBar(this.router);
    this.currentCaseSubject.next(null);
    // this.actionHandlerService.updateCurrentCaseId.next('');
    // this.actionHandlerService.updateCurrentStudentName.next('');
    this.debug.log('StageBase Component - closeForm - navigating to:', this.myRelativeUrlBase);
    this.router.navigateByUrl(this.myRelativeUrlBase);
  }

  protected requestReview(currentCase: IAssessCaseInfo, reviewerID: number) {
    this.debug.log(`${this.myStageName} Component - Attempting to request a review for case:`,
      currentCase, ' for user with ID:', reviewerID);

    const _subId = this.assessService.caseGetLastSubmittedFormSubId(currentCase, this.myReviewForm);

    if (!_subId) {
      this.toastr.error("Unable to locate submission for review! Please try again. If the error persists, contact nSide for support.");
      return;
    }

    this.assessService
      .requestForReview(
        currentCase,
        this.myReviewForm,
        _subId,
        reviewerID
      )
      .subscribe((res) => {
        this.toastr.success(
          'Request Successful.',
          'Status: '
        );
        this.modalService.dismissAll();
        // this.loadData(this.loadingInfo.lastLoaded);
        if (this.assessService.isCaseApiResponse(res)) {
          this.assessService.processCaseApiResponse(res);
        }
      });
  }

  protected submitThreatAssessmentTeamAssignmentTask(userID: number): void {
    this.isWaitingForHttpRequest = true;
    const buildingId = this.currentCase?.BuildingId;
    const assessCaseUID = this.currentCase?.UID;

    if (!(buildingId > 0
      && assessCaseUID > 0
      && userID > 0))
      return;

    this.assessService
      .assignTeamAssessmentSelectionTask(
        buildingId,
        this.currentCase?.CaseId,
        userID,
        `Assign Threat Assessment Team members for case ${this.currentCase?.CaseId}.`,
        null
      )
      .subscribe((res: any) => {
        this.isWaitingForHttpRequest = false;
        if (res.statusCode == 200) {
          this.modalService.dismissAll();
          this.toastr.success(
            'The message has been sent to the target user!'
          );
          if (this.assessService.isCaseApiResponse(res)) {
            this.assessService.processCaseApiResponse(res);
          }
        }
      });
  }

  protected submitAssignedThreatAssessmentTeam(userIDs: number[]): void {
    this.isWaitingForHttpRequest = true;
    const buildingId = this.currentCase?.BuildingId;
    const assessCaseUID = this.currentCase?.UID;

    if (!(buildingId > 0
      && assessCaseUID > 0
      && userIDs?.length > 0))
      return;

    this.assessService
      .updateThreatAssessmentTeam(buildingId, assessCaseUID, userIDs)
      .subscribe(
        (result) => {
          this.isWaitingForHttpRequest = false;
          if (result.statusCode == 200) {
            this.toastr.success(
              'The assessment team has been submitted!',
              'Message:'
            );
            // //reload data
            // this.loadData(this.loadingInfo.lastLoaded);
            if (this.assessService.isCaseApiResponse(result)) {
              this.assessService.processCaseApiResponse(result);
            }

            this.modalService.dismissAll();
          } else {
            this.toastr.error(result.description, 'Error:');
            this.debug.log(result);
          }
        },
        (error) => {
          this.toastr.error(error.description, 'Error:');
          this.debug.error(error);
        }
      );
  }

  protected openTeamSelectionConsiderModal(assessCase: IAssessCaseInfo) {
    this.currentCaseSubject.next(assessCase);
    this.open(this.teamSelectionConsiderModal);
  }

  protected openTeamSelectingModal(assessCase: IAssessCaseInfo) {
    this.currentCaseSubject.next(assessCase);
    this.isSelectingTeamMember = true;
    this.teamSelectionTitle = `Threat Assessment Team for case ${this.currentCase?.CaseId}`;
    this.open(this.threatAssessmentTeamSelectionModal);
  }

  protected openAssignTaskModal(assessCase: IAssessCaseInfo) {
    this.currentCaseSubject.next(assessCase);
    this.isSelectingTeamMember = false;
    this.assignedUserId = -1;
    this.teamSelectionTitle = `Assign Threat Assessment Team selection task for case ${this.currentCase?.CaseId}`;
    this.open(this.threatAssessmentTeamSelectionModal);
  }

  protected isAbleToAssignAssessmentTeam(currentCase: IAssessCaseInfo) {
    return currentCase.CurrentStage != AssessStages.closed;
  }

  protected hasCanManageCasesPermission() {
    for (let i = 0; i < this.assessUserList.length; i++) {
      if (this.assessUserList[i].userID?.toString() == this.currentUserId) {
        return this.assessUserList[i].permissions?.settingsPermissions?.assess_permissions?.canManageCases == true;
      }
    }

    return false;
  }

  protected hasCanSubmitAssessmentsPermission() {
    for (let i = 0; i < this.assessUserList.length; i++) {
      if (this.assessUserList[i].userID?.toString() == this.currentUserId) {
        return this.assessUserList[i].permissions?.settingsPermissions?.assess_permissions?.canSubmitAssessments == true;
      }
    }

    return false;
  }

  protected backToDecision() {
    this.modalService.dismissAll();
    this.open(this.teamSelectionConsiderModal);
  }

  protected viewDownloadClick(event: any, assessCaseId?: string, formName?: string, submissionId?: string, download?: boolean) {
    this.debug.log(`${this.myStageName} Component - viewDownloadClick executed. event:`, event, "assessCaseId", assessCaseId, "submissionId:", submissionId);
    this.assessService.viewOrDownloadReport(event, assessCaseId, formName, submissionId, download);
  }

  protected startStageReview(p_case: IAssessCaseInfo, skipNavigation?: boolean): void {
    this.debug.log('Attempting to start review process for case:', p_case?.CaseId);
    const _subId = this.assessService.caseGetLastSubmittedFormSubId(p_case, this.myReviewForm);

    if (!_subId) {
      this.toastr.error("Unable to locate submission for review! Please try again. If the error persists, contact nSide for support.");
      return;
    }

    // localStorage.setItem('formsFormName', this.myReviewForm);
    // localStorage.removeItem(this.myReviewForm);
    this.localStore.resetForm(this.myReviewForm);
    this.isOpeningForm = true;

    if (!skipNavigation) {
      const _path = `${this.myRelativeUrlBase}/request/${p_case.CaseId}/review/${this.myReviewForm}/${_subId}`;
      this.modalService.dismissAll();
      this.router.navigate([_path]);
    }
  }

  protected startRoarActivityLog(assessCase: IAssessCaseInfo,
                                 submissionID: string,
                                 skipNavigation?: boolean): void {
    this.debug.log('Attempting to record ROAR Activity Log for case:', assessCase?.CaseId);

    const thisFormName = AssessForms.names.roarActivity;
    const thisCase = assessCase ?? this.currentCase;

    if (!thisCase || !(submissionID?.length > 0)) {
      this.toastr.error("An error occurred. Please try again.");
      return;
    }

    this.isOpeningForm = true;
    this.currentCaseSubject.next(thisCase);
    //localStorage.setItem('selectedAssessCaseId', thisCase.CaseId);
    this.localStore.setFromAssessCase(thisCase);
    this.localStore.resetForm(thisFormName);
    this.modalService.dismissAll();

    if (skipNavigation == true) return;

    const extras: NavigationExtras = {
      queryParams: {
        "roarAssignmentSubmissionId": submissionID
      }
    }

    this.router.navigate([
      `${this.myRelativeUrlBase}/${thisFormName}/${thisCase.CaseId}`,
      extras.queryParams
    ]);
  }

  protected startRoarActivityReview(assessCase: IAssessCaseInfo,
                                    submissionID: string,
                                    skipNavigation?: boolean): void {
    this.debug.log('Attempting to start review process for case:', assessCase?.CaseId);

    const thisFormName = AssessForms.names.roarActivity;
    const thisCase = assessCase ?? this.currentCase;

    if (!thisCase || !(submissionID?.length > 0)) {
      this.toastr.error("An error occurred. Please try again.");
      return;
    }

    // localStorage.setItem('formsFormName', thisFormName);
    // localStorage.removeItem(thisFormName);
    this.localStore.resetForm(thisFormName);
    this.isOpeningForm = true;
    this.modalService.dismissAll();

    if (!skipNavigation) {
      const _path = `${this.myRelativeUrlBase}/request/${assessCase.CaseId}/review/${thisFormName}/${submissionID}`;
      this.modalService.dismissAll();
      this.router.navigate([_path]);
    }
  }
}
