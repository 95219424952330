import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { AuthorizationService } from '@clients-nside-io/shared/auth';
import { FormService } from '../../../../../forms/src/app/shared/form.service';
import { LocalStoreService } from '../../../../../../libs/shared/services/src/lib/localStore/localStore.service';

@Component({
  selector: 'app-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.scss'],
})
export class RedirectComponent {
  constructor(
    private router: Router,
    private authService: AuthorizationService,
    private form: FormService,
    private localStore: LocalStoreService
  ) {
  }

  isLoggedIn$ = this.authService.checkAuth().pipe(
    map((loggedIn) => {
      if (loggedIn) {
        //const redirectUrl = localStorage.getItem('currentUrl');
        const redirectUrl = this.localStore.currentRoute.length > 0 ? this.localStore.currentRoute : `/building/${this.localStore.buildingId}`;
        this.router.navigateByUrl(redirectUrl);
        return true;
      } else {
        this.form.handleError("Unauthorized", "Authorization failed. Please try logging in again. Contact support if the problem persists")
        return false;
      }
    })
  );
}
