/*
  Filename: evaluations.component.ts
  Date: 2/2/2022
  Developer: Quan N Nguyen.
  Description: controller for evaluation component/stage of nSide|Assess
*/

// import necessary library
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { AssessForms } from '../../../models/Constants';
import { AssessStages } from '../../../models/AssessGeneral';
import { AssessStageBaseComponent } from '../../shared/AssessStageBase.component';
import { AssessService } from '../../../services/assess.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActionHandlerService, DebugUtils } from '@clients-nside-io/shared/util';
import { ToastrService } from 'ngx-toastr';
import { SidebarHighlightService } from '../../../services/sidebar-highlight.service';
import { LocalStoreService } from '../../../../../../../libs/shared/services/src/lib/localStore/localStore.service';
import { IStudentContactInfo } from '@clients-nside-io/shared/models';
import { IStudentOfConcernPromptInfo } from '../../shared/student-concern-dialogs/student-concern-dialogs-helpers';
import { StudentConcernDialogs } from '../../shared/student-concern-dialogs/student-concern-dialogs.class';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'clients-nside-io-evaluations',
  // templateUrl: '../../shared/AssessStageBase.component.html',
  templateUrl: './evaluations.component.html',
  styleUrls: ['./evaluations.component.scss'],
})
export class EvaluationsComponent extends AssessStageBaseComponent implements OnInit, OnDestroy, AfterViewInit {
  public myTitle = 'Evaluations';
  protected readonly myStageName: AssessStages = AssessStages.evaluations;
  protected readonly myFormDisplayNames: string[] = AssessForms.displayNames.evaluations;
  protected readonly myFormNames: string[] = AssessForms.names.evaluations;
  protected readonly myReviewFormNumber: number = 1;
  protected readonly myReviewForm: string = this.myFormNames[this.myReviewFormNumber - 1];

  constructor(
    protected assessService: AssessService,
    protected route: ActivatedRoute,
    protected router: Router,
    protected modalService: NgbModal,
    protected actionHandlerService: ActionHandlerService,
    protected toastr: ToastrService,
    protected debug: DebugUtils,
    protected sidebarHighlighter: SidebarHighlightService,
    protected localStore: LocalStoreService,
    private matDialog: MatDialog
  ) {
    super(assessService, route, router, modalService, actionHandlerService, toastr, debug, sidebarHighlighter, localStore);
    this.studentDialogs = new StudentConcernDialogs(this.matDialog);
    if (!!this.router.getCurrentNavigation()?.extras?.state?.studentInfo) {
      this.debug.log('studentInfo:', this.router.getCurrentNavigation().extras.state.studentInfo);
      this.currentStudentInfo = this.router.getCurrentNavigation().extras.state.studentInfo;
      this.localStore.currentStudentInfo = this.currentStudentInfo;
    }
  }

  private showConfirmationDialog(studentInfo: IStudentContactInfo) {
    const confirmDlg = this.studentDialogs.openConfirmationDialog(null, null, studentInfo);

    confirmDlg.subscribe((result) => {
      this.isLoading = false;

      if (result.action == "Cancel") {
        return;
      }

      const thisFormName = AssessForms.names.evaluations[0];
      this.isOpeningForm = true;
      this.modalService.dismissAll();
      this.localStore.resetForm(thisFormName);
      this.currentStudentInfo = studentInfo;
      this.localStore.currentStudentInfo = this.currentStudentInfo;
      this.router.navigate(
        [
          `stage/${this.buildingId}/evaluations/${thisFormName}`
        ],
        { state: { studentInfo: studentInfo } }
      );
    });
  }

  addEvaluation(): void {
    if (this.isOpeningForm) {
      this.isOpeningForm = false;
      this.sidebarHighlighter.highlightSideBar(this.router);
      this.router.navigate([`building/${this.buildingId}`]);
    } else {
      this.isLoading = true;
      const promptData: IStudentOfConcernPromptInfo = { ...this.studentDialogs.defaultPromptInfo, ...{ buildingID: this.buildingId, stateIdentifier: '' } };
      const promptDlg = this.studentDialogs.openPromptDialog(null, null, promptData);

      promptDlg.subscribe((result) => {
        if (result.action == "Cancel") {
          this.isLoading = false;
          return;
        }

        if (result.data?.stateIdentifier == null || result.data.stateIdentifier.length == 0) {
          this.toastr.error('State Identifier is required to create a new assessment.', 'Assessment Creation Error');
          this.isLoading = false;
          return;
        }

        this.assessService.getStudentInfoByIdentifier(result.data.stateIdentifier).subscribe((studentInfo) => {
          if (studentInfo == null) {
            const notFoundDlg = this.studentDialogs.openNotFoundDialog(null, null, result.data);

            notFoundDlg.subscribe((result) => {
              if (result.action == "Cancel") {
                this.isLoading = false;
                return;
              }

              if (result.data?.stateIdentifier == null || result.data.stateIdentifier.length == 0) {
                this.toastr.error('State Identifier is required to create a new assessment.', 'Assessment Creation Error');
                this.isLoading = false;
                return;
              }

              const createData: IStudentContactInfo = { ...this.studentDialogs.defaultContactInfo, ...{ state_identifier: result.data.stateIdentifier } };
              const createDlg = this.studentDialogs.openCreateDialog(null, null, createData);

              createDlg.subscribe((result) => {
                if (result.action == "Cancel") {
                  this.isLoading = false;
                  return;
                }

                if (result.data?.state_identifier == null || result.data.state_identifier.length == 0) {
                  this.toastr.error('State Identifier is required to create a new assessment.', 'Assessment Creation Error');
                  this.isLoading = false;
                  return;
                }

                this.assessService.addOrUpdateStudentInfo(result.data.state_identifier, result.data).subscribe((studentInfo) => {
                  if (studentInfo == null) {
                    this.toastr.error('Error creating Student Contact record. Please try again. If the problem persists, contact your administrator or nSide Support.', 'Assessment Creation Error');
                    this.isLoading = false;
                    return;
                  }

                  this.showConfirmationDialog(studentInfo);
                });
              });
            });
          } else {
            this.showConfirmationDialog(studentInfo);
          }
        });
      });
    }
  }
}
