import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { AuthorizationService } from '@clients-nside-io/shared/auth';
import { DebugUtils } from '@clients-nside-io/shared/util';
import { environment } from '../../environments/environment';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { FormService } from '@clients-nside-io/apps/forms/shared/service';
import {
  IAssessCasesRsp,
  IAssessUserInfo
} from '../models/DTOs';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { AssessForms } from '../models/Constants';
import { isNumeric } from 'rxjs/internal-compatibility';
import { AssessStages, DataLoadingInfo, StageCasesCounts } from '../models/AssessGeneral';
import {
  IApiResponse,
  IAssessCaseInfo, IAssessToDoInfo,
  ICaseStageActionInfo,
  ICaseStageInfo,
  IFormData, IStudentContactInfo, IStudentContactInfoApiObj, IStudentContactInfoApiRsp
} from '@clients-nside-io/shared/models';
import { ErrorInfo } from '../models/ErrorModel';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AssessService implements OnDestroy {
  private activeUserIdSubject: BehaviorSubject<string | number>;
  private activeBuildingIdSubject: BehaviorSubject<number>;
  private activeCasesSubject: BehaviorSubject<IAssessCaseInfo[]>;
  private assessToDoListSubject: BehaviorSubject<IAssessToDoInfo[]>;
  // private activeStageSubject: BehaviorSubject<AssessStages>;
  private activeStageCasesCounts: StageCasesCounts;
  private activeStageCasesCountsSubject: BehaviorSubject<StageCasesCounts>;
  private activeAssessUsersSubject: BehaviorSubject<IAssessUserInfo[]>;
  private formServiceErrorSubject: BehaviorSubject<ErrorInfo>;
  private loadingInfo: DataLoadingInfo;
  private activeSubscriptions = new Map<string, Subscription>();
  private assessCasesMap = new Map<string, IAssessCaseInfo>();

  activeUserId$: Observable<string | number>;
  activeBuildingId$: Observable<number>;
  activeCases$: Observable<IAssessCaseInfo[]>;
  assessToDoList$: Observable<IAssessToDoInfo[]>;
  // activeStage$: Observable<AssessStages>;
  activeStageCasesCounts$: Observable<StageCasesCounts>;
  activeAssessUsers$: Observable<IAssessUserInfo[]>;
  formServiceError$: Observable<ErrorInfo>;

  constructor(
    private authorizationService: AuthorizationService,
    private formService: FormService,
    private http: HttpClient,
    private debug: DebugUtils
  ) {
    // Initialization section
    this.activeUserIdSubject = new BehaviorSubject<string | number>(null);
    this.activeBuildingIdSubject = new BehaviorSubject<number>(null);
    this.activeCasesSubject = new BehaviorSubject<IAssessCaseInfo[]>(null);
    // this.activeStageSubject = new BehaviorSubject<AssessStages>(null);
    this.assessToDoListSubject = new BehaviorSubject<IAssessToDoInfo[]>(null);
    this.activeStageCasesCountsSubject = new BehaviorSubject<StageCasesCounts>(null);
    this.activeStageCasesCounts = new StageCasesCounts();
    this.activeAssessUsersSubject = new BehaviorSubject<IAssessUserInfo[]>(null);
    this.formServiceErrorSubject = new BehaviorSubject<ErrorInfo>(null);
    this.loadingInfo = new DataLoadingInfo(null, null, null, null, true);

    //Observable section
    this.activeUserId$ = this.activeUserIdSubject.asObservable();
    this.activeBuildingId$ = this.activeBuildingIdSubject.asObservable();
    this.activeCases$ = this.activeCasesSubject.asObservable();
    // this.activeStage$ = this.activeStageSubject.asObservable();
    this.activeStageCasesCounts$ = this.activeStageCasesCountsSubject.asObservable();
    this.activeAssessUsers$ = this.activeAssessUsersSubject.asObservable();
    this.formServiceError$ = this.formServiceErrorSubject.asObservable();
    this.assessToDoList$ = this.assessToDoListSubject.asObservable();

    // Subscription section
    this.activeSubscriptions.set('auth_userId$',
      this.authorizationService.userId$
        .subscribe(value => {
          this.activeUserIdSubject.next(value);
        })
    );

    this.activeSubscriptions.set('formService_error$',
      this.formService.error$.subscribe(err => {
        if (err == null)
          return;

        this.formServiceErrorSubject.next(err);
        this.formService.resetError();
        this.formServiceErrorSubject.next(null);
      })
    );

    this.activeSubscriptions.set('activeBuildingIdSubject',
      this.activeBuildingIdSubject.subscribe(val => {
        if (!(val > 0))
          return;

        this.loadingInfo.pause();
        this.loadingInfo.reset();
        this.activeCasesSubject.next(null);
        this.assessToDoListSubject.next(null);
        this.activeAssessUsersSubject.next(null);
        this.activeStageCasesCounts.reset();
        this.activeStageCasesCountsSubject.next(this.activeStageCasesCounts);
        this.loadingInfo.resume();
      })
    );

    this.activeSubscriptions.set('reloadObs$',
      this.loadingInfo.reloadObs$.subscribe(doUpdate => {
        if (doUpdate) this.loadData(this.loadingInfo.lastLoaded);
      })
    );

    this.loadingInfo.resume();
  }

  ngOnDestroy(): void {
    this.activeSubscriptions.forEach(sub => {
      sub?.unsubscribe();
    });
    this.activeSubscriptions.clear();
  }

  pauseLoading(): void {
    this.loadingInfo.pause();
  }

  resumeLoading(): void {
    this.loadingInfo.resume();
  }

  getActiveUserId(): string | number {
    return this.activeUserIdSubject.value;
  }

  /**
   * Updates the current building ID if the provided value
   * is valid and different from the current one.
   * @param buildingId
   */
  updateBuildingId(buildingId: number) {
    const current = this.activeBuildingIdSubject.value;
    if (buildingId > 0 && buildingId != current)
      this.activeBuildingIdSubject.next(buildingId);
  }

  isCaseApiResponse(rsp: IApiResponse<any>): rsp is IApiResponse<IAssessCaseInfo> {
    return (<IAssessCaseInfo>rsp?.data)?.CaseId?.length > 0;
  }

  processCaseApiResponse(rsp: IApiResponse<IAssessCaseInfo>) {
    const thisCase: IAssessCaseInfo = rsp?.data;
    if (!(thisCase?.CaseId?.length > 0 && thisCase?.CurrentStage?.length > 0))
      return;

    if (!this.assessCasesMap.has(thisCase.CaseId)
      || this.assessCasesMap.get(thisCase.CaseId).CurrentStage != thisCase.CurrentStage) {
      this.activeStageCasesCounts.update([thisCase], false);
      this.activeStageCasesCountsSubject.next(this.activeStageCasesCounts);
    }

    this.assessCasesMap.set(thisCase.CaseId, thisCase);
    this.activeCasesSubject.next(this.sortCases([...this.assessCasesMap.values()]));
  }

  private sortCases(cases: IAssessCaseInfo[]): IAssessCaseInfo[] {
    if (cases == null)
      return [];

    return [...cases]
      .sort((a,b) =>
        a.LastUpdated > b.LastUpdated
          ? 1
          : a.LastUpdated < b.LastUpdated
            ? -1
            : 0
      ).reverse();
  }

  private loadData(activitySince?: Date) {
    const thisBuildingId = this.activeBuildingIdSubject.value;
    if (!(thisBuildingId > 0))
      return;

    this.loadingInfo.pause();
    const requestStarted = new Date();
    const sub = this.getAssessInfoByBuildingID(thisBuildingId,
        this.loadingInfo.currentStage, activitySince)
      .subscribe({
        next: (res: IAssessCasesRsp) => {
          if (res?.assessUsers?.length > 0) {
            this.activeAssessUsersSubject.next(res.assessUsers);
          }

          if (res?.['assess-to-do-list']?.length > 0) {
            this.assessToDoListSubject.next(res['assess-to-do-list']);
          }

          if (!(res?.data?.length > 0)) {
            if (this.activeCasesSubject.value == null) {
              this.activeCasesSubject.next([]);
            }
            this.loadingInfo.update(requestStarted);
            this.loadingInfo.resume();
            sub.unsubscribe();
            return;
          }

          const newOrStageChanged: IAssessCaseInfo[] = [];

          res.data.forEach(cs => {
            if (!(cs?.CaseId?.length > 0 && cs?.CurrentStage?.length > 0))
              return;

            if (!this.assessCasesMap.has(cs.CaseId)
              || this.assessCasesMap.get(cs.CaseId)?.CurrentStage != cs.CurrentStage) {
              newOrStageChanged.push(cs);
            }

            this.assessCasesMap.set(cs.CaseId, cs);
          });

          // if (newOrStageChanged.length > 0) {
          //   this.activeStageCasesCounts.update(newOrStageChanged, false);
          //   this.activeStageCasesCountsSubject.next(this.activeStageCasesCounts);
          // }

          const sortedCases = this.sortCases([...this.assessCasesMap.values()]);
          this.activeStageCasesCounts.update(sortedCases, true);
          this.activeStageCasesCountsSubject.next(this.activeStageCasesCounts);
          this.activeCasesSubject.next(sortedCases);
          this.debug.log(`Assess Service - loadData - Data updated successfully: ${res.data.length} cases.`);
          this.loadingInfo.update(requestStarted);
          this.loadingInfo.resume();
          sub.unsubscribe();
        },
        error: (err: any) => {
          this.debug.log('Assess Service - loadData - There was an error loading/reloading data:', err);
          this.loadingInfo.resume();
          sub.unsubscribe();
        },
      });
  }

  private getAssessInfoByBuildingID(buildingID: number, forStage?: AssessStages, activitySince?: Date): Observable<IAssessCasesRsp> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.authorizationService.getToken()}`
    });
    let params = new HttpParams();
    if (forStage != null)
      params = params.set('filterByStage', forStage.toString());
    if (activitySince != null)
      params = params.set('filterByDate', activitySince.toISOString());
    return this.http.get<IAssessCasesRsp>(
      `${environment.formApiBase}/${environment.formApiVersion}/Assess/${buildingID}/list`,
      {
        headers: headers,
        params: params
      }
    );
  }

  requestForReview(
    assessCase: IAssessCaseInfo,
    formName: string,
    submissionId: string,
    userId: number
  ): Observable<IApiResponse<IAssessCaseInfo>> {
    if (!assessCase || !(formName?.length > 0)
      || !(submissionId?.length > 0) || !(userId > 0))
      return null;

    const userParams = new HttpParams();
    userParams.append('submissionId', submissionId);
    userParams.append('userId', userId);

    const userHeaders = new HttpHeaders();
    userHeaders.append(
      'Authorizations',
      `Bearer ${this.authorizationService.getToken()}`
    );

    return this.http.get<IApiResponse<IAssessCaseInfo>>(
      `${environment.formApiBase}/${environment.formApiVersion}/Assess/${assessCase.BuildingId}/request/${assessCase.CaseId}/review/${formName}?submissionId=${submissionId}&userId=${userId}`,
      {
        headers: userHeaders,
      }
    );
  }

  // reviewCase(
  //   buildingId: string,
  //   assessCaseId: string,
  //   formName: string,
  //   submissionId: string
  // ) {
  //   const userHeaders = new HttpHeaders();
  //   userHeaders.append(
  //     'Authorizations',
  //     `Bearer ${this.authorizationService.getToken()}`
  //   );
  //   return this.http.get(
  //     `${environment.formApiBase}/${environment.formApiVersion}/Forms/${buildingId}/assess/${assessCaseId}/review/${formName}/${submissionId}`,
  //     {
  //       headers: userHeaders,
  //     }
  //   );
  // }

  // // get corresponding submission id by caseId, currentStage, and userId
  // getSubmissionId(currentStage: string) {
  //
  //   // set up param filterByStage
  //   const filterByStageParams = {
  //     "evaluations": "Evaluations",
  //     "assessments": "Assessments",
  //     "rms-plans": "RMS-Plans",
  //     "interventions": "Interventions",
  //     "monitoring": "Monitoring",
  //     "closed": "Closed"
  //   }
  //
  //
  //   // make an api call, to get all case, respect to currentStage
  //   const filterByStage = filterByStageParams[currentStage];
  //
  //   // prepare http header for the api call
  //   const userHeaders = new HttpHeaders();
  //   userHeaders.append(
  //     'Authorizations',
  //     `Bearer ${this.authorizationService.getToken()}`
  //   );
  //
  //   // make an api call
  //   return this.http.get(`${environment.formApiBase}/${environment.formApiVersion}/Assess/130/list?filterByStage=${filterByStage}`, {
  //     headers: userHeaders
  //   })
  //
  // }

  updateThreatAssessmentTeam(buildingId: number, assessCaseUID: number, teamIDsArray: number[]): Observable<IApiResponse<IAssessCaseInfo>> {
    const reqURL = `${environment.formApiBase}/${environment.formApiVersion}/Assess/${buildingId}/update/${assessCaseUID}/team`;

    // prepare http header for the api call
    const userHeaders = new HttpHeaders();
    userHeaders.append(
      'Authorizations',
      `Bearer ${this.authorizationService.getToken()}`
    );

    const reqBody = {
      'teamIDs': teamIDsArray
    };

    const reqHeader = {
      headers: userHeaders
    }

    return this.http.post<IApiResponse<IAssessCaseInfo>>(reqURL, reqBody, reqHeader);
  }

  assignTeamAssessmentSelectionTask(buildingId: number,
                                    assessCaseId: string,
                                    userId: number,
                                    subject: string,
                                    body: string): Observable<IApiResponse<IAssessCaseInfo>> {
    const reqURL = `${environment.formApiBase}/${environment.formApiVersion}/Assess/${buildingId}/assign/${assessCaseId}/todo`;
    const reqBody = {
      userID: userId,
      body: body,
      subject: undefined
    };

    if (subject?.length > 0)
      reqBody.subject = subject;

    // prepare http header for the api call-----------
    const userHeaders = new HttpHeaders();
    userHeaders.append(
      'Authorizations',
      `Bearer ${this.authorizationService.getToken()}`
    );
    const reqHeader = {
      headers: userHeaders
    }
    //------------------------------------------------

    return this.http.post<IApiResponse<IAssessCaseInfo>>(reqURL, reqBody, reqHeader);
  }

  viewOrDownloadReport(event: any, assessCaseId?: string, formName?: string, submissionId?: string, download?: boolean) {
    if (event?.target)
      event.target.disabled = true;
    this.formService.getAssessFormPdf(assessCaseId, formName, submissionId, true)
      .subscribe((rsp: HttpResponse<Blob>) => {
        const filename = this.formService.getFileNameFromResponse(rsp);
        const pdfBlob = new Blob([rsp.body as BlobPart], {type: 'application/pdf'});
        const downloadURL = URL.createObjectURL(pdfBlob);
        const link = document.createElement('a');
        link.href = downloadURL;
        if (download) {
          link.download = filename;
        } else {
          link.target = "_blank";
        }
        link.click();
        if (event?.target)
          event.target.disabled = false;
      });
  }

  viewOrDownloadReports(event: any, assessCaseId?: string, formName?: string, submissionId?: string, download?: boolean) {
    event.target.disabled = true;
    this.formService.getAssessFormPdf(assessCaseId, formName, submissionId, false)
      .subscribe((rsp: HttpResponse<Blob>) => {
        const filename = this.formService.getFileNameFromResponse(rsp);
        const pdfBlob = new Blob([rsp.body as BlobPart], {type: 'application/pdf'});
        const downloadURL = URL.createObjectURL(pdfBlob);
        const link = document.createElement('a');
        link.href = downloadURL;
        link.download = filename;
        link.click();
        event.target.disabled = false;
      });
  }

  caseCurrentStageActions(assessCase: IAssessCaseInfo): ICaseStageActionInfo[] {
    const currStage: ICaseStageInfo = assessCase?.Stages?.[assessCase.CurrentStage];

    if (currStage?.Actions?.length > 0) {
      return currStage.Actions;
    }

    return null;
  }

  caseLastAction(assessCase: IAssessCaseInfo): ICaseStageActionInfo {
    const tmp = this.caseCurrentStageActions(assessCase);

    if (tmp?.length > 0)
      return tmp[tmp.length - 1];

    return null;
  }

  caseLastActionDisplay(assessCase: IAssessCaseInfo): string {
    const tmp = this.caseLastAction(assessCase);

    if (tmp)
      return tmp.Action +
        ' (' +
        tmp.ActionName +
        ')';

    return null;
  }

  caseHasSubmittedForm(assessCase: IAssessCaseInfo, formName: string): boolean {
    if (!assessCase || !formName) return false;

    return assessCase.Attachments?.completedForms?.
      findIndex(frm => frm.formName == formName) > -1;
  }

  caseGetLastSubmittedFormSubId(assessCase: IAssessCaseInfo, formName: string): string {
    if (!assessCase || !formName || !assessCase.Attachments?.completedForms) return null;

    const ordered = assessCase.Attachments.completedForms
      .map(m => { return { form: m, formDate: new Date(m.date) }; } )
      .sort((a,b) =>
        a.formDate > b.formDate
          ? 1
          : a.formDate < b.formDate
            ? -1
            : 0
      )
      .map(m => m.form)
      .reverse();
    const idx = ordered.
      findIndex(frm => frm.formName == formName);

    return idx > -1
      ? ordered[idx].submissionId
      : null;
  }

  caseHasReviewedForm(assessCase: IAssessCaseInfo, formName: string): boolean {
    if (!assessCase || !formName) return false;

    return this.caseHasSubmittedForm(assessCase, formName)
      && assessCase.Attachments?.completedForms?.
        findIndex(frm => frm.formName == formName
          && frm.name.endsWith("Review Completed")) > -1;
  }

  caseHasReviewRequestForForm(assessCase: IAssessCaseInfo, formName: string): boolean {
    if (!assessCase || !formName) return false;

    if (!this.caseHasSubmittedForm(assessCase, formName)
      || this.caseHasReviewedForm(assessCase, formName)) return false;

    const lastSub = this.caseGetLastSubmittedFormSubId(assessCase, formName);

    if (!lastSub) return false;

    const currStage: ICaseStageInfo = assessCase.Stages?.[assessCase.CurrentStage];

    if (!currStage) return false;

    return currStage.Actions.
      findIndex(act => act.SubmissionId == lastSub
        && (act.ActionName.endsWith("Review Requested")
        || act.ActionName.endsWith("Review Added"))) > -1;
  }

  private _caseTeamCheck(currentCase: IAssessCaseInfo): boolean {
    if (currentCase?.CurrentStage?.length > 0 && currentCase?.CurrentStage?.toLowerCase() === 'evaluations')
      return true;

    return currentCase?.AssignedTeam?.Users?.length > 0;
  }

  private _ifAbleToAnythingGetUserId(currentCase: IAssessCaseInfo, formNumber: number,
                          currentUserId: string | number): number {
    if (!this._caseTeamCheck(currentCase))
      return -1;

    const userId = isNumeric(currentUserId)
      ? currentUserId as number
      : parseInt(currentUserId as string);

    if (isNaN(userId) || !(userId > 0))
      return -1;

    const stageForms = AssessForms.getFormsFor(currentCase.CurrentStage);

    if (formNumber > stageForms.length)
      return -1;

    return userId;
  }

  isAbleToCreate(currentCase: IAssessCaseInfo, formNumber: number,
                 currentUserId: string | number): boolean {
    //debugger;
    const userId = this._ifAbleToAnythingGetUserId(currentCase, formNumber, currentUserId);

    if (userId <= 0)
      return false;

    const stageForms = AssessForms.getFormsFor(currentCase.CurrentStage);
    const thisFormName = stageForms[formNumber - 1];
    const prereqs = AssessForms.namesIfHasPrerequisiteForms(currentCase.CurrentStage, thisFormName);
    let prResult = true;
    let prHit = false;

    prereqs.forEach(req => {
      if (prHit == true) return;

      const needsReview = AssessForms.isReviewRequired(req);
      if (needsReview) {
        if (!this.caseHasReviewedForm(currentCase, req)) {
          prResult = false;
          prHit = true;
        }
      } else {
        if (!this.caseHasSubmittedForm(currentCase, req)) {
          prResult = false;
          prHit = true;
        }
      }
    });

    if (prHit && !prResult)
      return false;

    if (AssessForms.isReviewable(thisFormName) && thisFormName != AssessForms.names.roarActivity) {
      if (this.caseHasReviewedForm(currentCase, thisFormName)
        || this.caseHasReviewRequestForForm(currentCase, thisFormName))
        return false;
    }

    return currentCase.AssignedTeam?.Users?.
      findIndex(w => w.id?.toString() == userId.toString()) > -1;
  }

  isAbleToCreateAny(currentCase: IAssessCaseInfo, currentUserId: string | number): boolean {
    const userId = this._ifAbleToAnythingGetUserId(currentCase, 1, currentUserId);

    if (userId <= 0)
      return false;

    const stageForms = AssessForms.getFormsFor(currentCase.CurrentStage);

    for (let i = 1; i <= stageForms.length; i++) {
      if (this.isAbleToCreate(currentCase, i, userId))
        return true;
    }

    return false;
  }

  /**
   * Checks whether the provided user is able to review the requested form for
   * the provided case, optionally not requiring a request for review action.
   * @param currentCase
   * @param formNumber
   * @param currentUserId
   * @param requestRequired
   */
  isAbleToReview(currentCase: IAssessCaseInfo, formNumber: number,
                 currentUserId: string | number, requestRequired?: boolean): boolean {
    const userId = this._ifAbleToAnythingGetUserId(currentCase, formNumber, currentUserId);

    if (userId <= 0)
      return false;

    const reqRequired = requestRequired != false;
    const stageForms = AssessForms.getFormsFor(currentCase.CurrentStage);
    const thisFormName = stageForms[formNumber - 1];

    if (!AssessForms.isReviewable(thisFormName)
      || this.caseHasReviewedForm(currentCase, thisFormName)
      || (reqRequired && !this.caseHasReviewRequestForForm(currentCase, thisFormName)))
      return false;

    return currentCase.AssignedTeam?.Users?.
    findIndex(w => w.id?.toString() == userId.toString()) > -1;
  }

  showAskReviewButton(assessCase: IAssessCaseInfo,
                      currentUserId: string | number): boolean {
    const userId = isNumeric(currentUserId)
      ? currentUserId as number
      : parseInt(currentUserId);

    if (!(userId > 0))
      return false;

    const reviewForms = AssessForms.getFormsFor(assessCase.CurrentStage)
      .filter(w => AssessForms.isReviewRequired(w));
    let shown = true;
    let finished = false;

    if (reviewForms.every(w => this.caseHasReviewedForm(assessCase, w))) {
      shown = false;
      finished = true;
    }

    if (!finished && reviewForms
      .findIndex(w => this.caseHasReviewRequestForForm(assessCase, w)) >= 0) {
      shown = false;
    }

    return shown;
  }

  enableAskReviewButton(assessCase: IAssessCaseInfo,
                        currentUserId: string | number): boolean {
    const userId = isNumeric(currentUserId)
      ? currentUserId as number
      : parseInt(currentUserId);

    if (!(userId > 0))
      return false;

    if (!this.showAskReviewButton(assessCase, currentUserId))
      return false;

    const reviewForms = AssessForms.getFormsFor(assessCase.CurrentStage)
      .filter(w => AssessForms.isReviewRequired(w));
    let enabled = false;
    let finished = false;

    reviewForms.forEach(frm => {
      if (finished) return;

      if (this.caseHasSubmittedForm(assessCase, frm)
        && !this.caseHasReviewedForm(assessCase, frm)
        && !this.caseHasReviewRequestForForm(assessCase, frm)) {
        enabled = true;
        finished = true;
      }
    });

    return assessCase.CurrentStage == AssessStages.evaluations
      ? enabled
      : enabled && assessCase.AssignedTeam?.Users
        ?.findIndex(w => w.id?.toString() == userId.toString()) > -1;
  }

  enableStartReviewButton(assessCase: IAssessCaseInfo,
                        currentUserId: string | number): boolean {
    const userId = isNumeric(currentUserId)
      ? currentUserId as number
      : parseInt(currentUserId);

    if (!(userId > 0))
      return false;

    if (this.showAskReviewButton(assessCase, currentUserId))
      return false;

    const reviewForms = AssessForms.getFormsFor(assessCase.CurrentStage)
      .filter(w => AssessForms.isReviewRequired(w));
    let enabled = false;
    let finished = false;

    reviewForms.forEach(frm => {
      if (finished) return;

      if (this.caseHasSubmittedForm(assessCase, frm)
        && !this.caseHasReviewedForm(assessCase, frm)
        && this.caseHasReviewRequestForForm(assessCase, frm)) {
        enabled = true;
        finished = true;
      }
    });

    return assessCase.CurrentStage == AssessStages.evaluations
      ? enabled
      : enabled && assessCase.AssignedTeam?.Users
      ?.findIndex(w => w.id?.toString() == userId.toString()) > -1;
  }

  private parseStudentInfo(identifier: string, rsp: IStudentContactInfoApiRsp): IStudentContactInfo {
    let rv: IStudentContactInfo = null;

    if (!(rsp?.data?.state_identifiers?.findIndex(x => x.state_identifier === identifier) >= 0)) {
      return null;
    }

    try {
      rv = {
        last_name: rsp.data.last_name,
        first_name: rsp.data.first_name,
        birth_date: rsp.data.birth_date,
        physical_description: rsp.data.physical_description,
        grade: rsp.data.grade,
        nside_student_id: rsp.data.nside_student_id,
        age: rsp.data.age,
        photo: rsp.data.photo,
        iep_contacts: rsp.data.iep_contacts_array,
        state_identifier: identifier,
        identified_gender: rsp.data.identified_gender,
        iep: rsp.data.iep,
        504: rsp.data['504'],
        ihp: rsp.data.ihp,
        assess_cases: rsp.data.assess_cases
      }
    } catch (e) {
      this.debug.error('Assess Service - parseStudentInfo - Error parsing student info:', e, 'response:', rsp);
      rv = null;
    }

    return rv;
  }

  private prepStudentInfoReq(studentInfo: IStudentContactInfo): IStudentContactInfoApiObj {
    let rv: IStudentContactInfoApiObj = null;

    try {
      rv = {
        last_name: studentInfo.last_name,
        first_name: studentInfo.first_name,
        birth_date: studentInfo.birth_date,
        physical_description: studentInfo.physical_description,
        grade: studentInfo.grade,
        nside_student_id: studentInfo.nside_student_id,
        age: studentInfo.age,
        photo: studentInfo.photo,
        iep_contacts_array: studentInfo.iep_contacts,
        identified_gender: studentInfo.identified_gender,
        iep: studentInfo.iep,
        504: studentInfo['504'],
        ihp: studentInfo.ihp
      }
    } catch (e) {
      this.debug.error('Assess Service - prepStudentInfoReq - Error parsing student info:', e, 'studentInfo:', studentInfo);
      rv = null;
    }

    return rv;
  }

  getStudentInfoByIdentifier(identifier: string): Observable<IStudentContactInfo> {
    const reqURL = `${environment.formApiBase}/${environment.formApiVersion}/Assess/${this.activeBuildingIdSubject.value}/student/${identifier}`;
    const userHeaders = new HttpHeaders();
    userHeaders.append(
      'Authorizations',
      `Bearer ${this.authorizationService.getToken()}`
    );

    try {
      return this.http.get<IStudentContactInfoApiRsp>(reqURL, { headers: userHeaders })
        .pipe(catchError((err: HttpErrorResponse) => {
          this.debug.error('Assess Service - getStudentInfoByIdentifier - Error getting student info:', err, 'identifier:', identifier);
          return of(null);
        }))
        .pipe(
          map((rsp: IStudentContactInfoApiRsp) => {
            return this.parseStudentInfo(identifier, rsp);
          }
        ));
    } catch (e) {
      this.debug.error('Assess Service - getStudentInfoByIdentifier - Error getting student info:', e, 'identifier:', identifier);
      return of(null)
    }
  }

  addOrUpdateStudentInfo(identifier: string, studentInfo: IStudentContactInfo): Observable<IStudentContactInfo> {
    const reqURL = `${environment.formApiBase}/${environment.formApiVersion}/Assess/${this.activeBuildingIdSubject.value}/student/${identifier}`;
    const userHeaders = new HttpHeaders();
    userHeaders.append(
      'Authorizations',
      `Bearer ${this.authorizationService.getToken()}`
    );

    const studentInfoObj = this.prepStudentInfoReq(studentInfo);

    try {
      return studentInfoObj == null ? null : this.http.put<IStudentContactInfoApiRsp>(reqURL, studentInfoObj, { headers: userHeaders })
        .pipe(catchError((err: HttpErrorResponse) => {
          this.debug.error('Assess Service - addOrUpdateStudentInfo - Error updating student info:', err, 'studentInfo:', studentInfo);
          return of(null);
        }))
        .pipe(
          map((rsp: IStudentContactInfoApiRsp) => {
              return this.parseStudentInfo(identifier, rsp);
            }
          ));
    } catch (e) {
      this.debug.error('Assess Service - addOrUpdateStudentInfo - Error updating student info:', e, 'studentInfo:', studentInfo);
      return of(null)
    }
  }
}
