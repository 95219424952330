import { StudentConcernPromptComponent } from './student-concern-prompt/student-concern-prompt.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { StudentConcernNotFoundComponent } from './student-concern-not-found/student-concern-not-found.component';
import { StudentConcernCreateComponent } from './student-concern-create/student-concern-create.component';
import {
  StudentConcernConfirmationComponent
} from './student-concern-confirmation/student-concern-confirmation.component';
import {
  IStudentOfConcernInfoResult,
  IStudentOfConcernPromptInfo,
  IStudentOfConcernPromptResult
} from './student-concern-dialogs-helpers';
import { IStudentContactInfo } from '@clients-nside-io/shared/models';
import { Observable } from 'rxjs';


export class StudentConcernDialogs {
  private promptDialogRef: MatDialogRef<StudentConcernPromptComponent, IStudentOfConcernPromptResult> = null;
  private createDialogRef: MatDialogRef<StudentConcernCreateComponent, IStudentOfConcernInfoResult> = null;
  private confirmationDialogRef: MatDialogRef<StudentConcernConfirmationComponent, IStudentOfConcernInfoResult> = null;
  private notFoundDialogRef: MatDialogRef<StudentConcernNotFoundComponent, IStudentOfConcernPromptResult> = null;
  public readonly defaultPromptInfo: IStudentOfConcernPromptInfo = { buildingID: '0', stateIdentifier: '' };
  public readonly defaultContactInfo: IStudentContactInfo = {
    first_name: '',
    last_name: '',
    birth_date: '',
    iep_contacts: [],
    grade: '',
    identified_gender: '',
    iep: 'No',
    504: 'No',
    ihp: 'No',
    physical_description: '',
    state_identifier: '',
    nside_student_id: null,
    age: null,
    photo: ''
  };

  constructor(public dialog: MatDialog) { }

  /**
   * Open the Student of Concern prompt dialog, returning the dialog's afterClosed observable.
   * @param width
   * @param height
   * @param data
   */
  public openPromptDialog(width?: string, height?: string, data?: IStudentOfConcernPromptInfo): Observable<IStudentOfConcernPromptResult> {
    const dialogData: IStudentOfConcernPromptResult = { action: null, data: data || this.defaultPromptInfo };
    const dialogWidth = width || '400px';
    const dialogHeight = height || 'auto';

    this.promptDialogRef = this.dialog.open(StudentConcernPromptComponent, {
      width: dialogWidth,
      height: dialogHeight,
      data: dialogData
    });

    return this.promptDialogRef.afterClosed();
  }

  /**
   * Attempts to close the Student of Concern prompt dialog.
   */
  public closePromptDialog(): void {
    this.promptDialogRef?.close();
  }

  /**
   * Open the Student of Concern create record dialog, returning the dialog's afterClosed observable.
   * @param width
   * @param height
   * @param data
   */
  public openCreateDialog(width?: string, height?: string, data?: IStudentContactInfo): Observable<IStudentOfConcernInfoResult> {
    const dialogData: IStudentOfConcernInfoResult = { action: null, data: data || this.defaultContactInfo };
    const dialogWidth = width || '600px';
    const dialogHeight = height || 'auto';

    this.createDialogRef = this.dialog.open(StudentConcernCreateComponent, {
      width: dialogWidth,
      height: dialogHeight,
      data: dialogData
    });

    return this.createDialogRef.afterClosed();
  }

  /**
   * Attempts to close the Student of Concern create record dialog.
   */
  public closeCreateDialog(): void {
    this.createDialogRef?.close();
  }

  /**
   * Open the Student of Concern confirmation dialog, returning the dialog's afterClosed observable.
   * @param width
   * @param height
   * @param data
   */
  public openConfirmationDialog(width?: string, height?: string, data?: IStudentContactInfo): Observable<IStudentOfConcernInfoResult> {
    const dialogData: IStudentOfConcernInfoResult = { action: null, data: data || this.defaultContactInfo };
    const dialogWidth = width || '600px';
    const dialogHeight = height || 'auto';

    this.confirmationDialogRef = this.dialog.open(StudentConcernConfirmationComponent, {
      width: dialogWidth,
      height: dialogHeight,
      data: dialogData
    });

    return this.confirmationDialogRef.afterClosed();
  }

  /**
   * Attempts to close the Student of Concern confirmation dialog.
   */
  public closeConfirmationDialog(): void {
    this.confirmationDialogRef?.close();
  }

  /**
   * Open the Student of Concern not found dialog, returning the dialog's afterClosed observable.
   * @param width
   * @param height
   * @param data
   */
  public openNotFoundDialog(width?: string, height?: string, data?: IStudentOfConcernPromptInfo): Observable<IStudentOfConcernPromptResult> {
    const dialogData: IStudentOfConcernPromptResult = { action: null, data: data || this.defaultPromptInfo };
    const dialogWidth = width || '400px';
    const dialogHeight = height || 'auto';

    this.notFoundDialogRef = this.dialog.open(StudentConcernNotFoundComponent, {
      width: dialogWidth,
      height: dialogHeight,
      data: dialogData
    });

    return this.notFoundDialogRef.afterClosed();
  }

  /**
   * Attempts to close the Student of Concern not found dialog.
   */
  public closeNotFoundDialog(): void {
    this.notFoundDialogRef?.close();
  }
}
